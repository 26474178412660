import React from 'react';
import styles from './footer.module.css';

const Footer = () => {return (
  <div className={styles.Footer} data-testid="Footer">
     <footer id="footer">
                    <div class="container text-center">
                    <span className="footer-text"><i className="fa-regular fa-copyright"></i> DXHUB. All Rights Reserved</span>
                    </div>
                </footer>
  </div>
)};

export default Footer;
