import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import useAppContext from '../../AppContext';
import styles from './create-job-category.module.css';
import { Spinner } from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateJobCategory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const appContext = useAppContext();
  const Axios = appContext.getAxios();
  const search = useLocation().search;
  const [categoryId, setCategoryId] = useState("");
  const [inputs, setInputs] = useState({});
  const [clickSubmit, setClickSubmit] = useState(false);
  const [response, setResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorCode, setErrorCode] = useState();
  const page = location?.state?.page

  useEffect(() => {
    window. scrollTo(0, 0);
    appContext.setError(null);
    appContext.setSuccess(null)
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    if (new URLSearchParams(search).get('id')) {
      getCategory(new URLSearchParams(search).get('id'));
    }
    enterKeyEvent();
    // eslint-disable-next-line 
  }, []);

  function submit(e) {
    e.preventDefault();
    setClickSubmit(true);
    if (inputs.category === "" || !inputs.category) {
      return;
    } else {
      if (categoryId) {
        editCategory()
      } else {
        addCategory();
      }
    }
  }
  function getCategory(id) {
    setOpen(true);
    Axios.get("category/listOne/"+id).then((response) => {;
      if (response.status === 200) {
        setOpen(false);
        console.log(response)
        setResponse(true);
         setCategoryId(response?.data?.CategoryId)
         setInputs(values => ({ ...values, "category": response?.data?.CategoryName}));
      }
    }).catch(error => {
      setOpen(false);
      console.log('There was an error!', error);
      setResponse(false);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "312516"){
        if(page !== undefined){
          navigate("/job-category/"+page);
        }else{
          navigate("/job-category/1");
        }
     }
    });
  }
  const handleChange = (event) => {
    const value = event.target?.value;
    const name = event.target?.name;
    setInputs(values => ({ ...values, [name]: value }));
    console.log(inputs)
  }
  function addCategory() {
    setOpen(true);
    setIsLoading(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    const body = { "category": inputs.category.trim() }
    Axios.post("category", body).then((response) => {
      ;
      setIsLoading(false);
     
      if (response.status === 200) {
        setResponse(true);
        setTimeout(() => {
          navigate("/job-category/1");
          setOpen(false);
       }, 200);
      }
    }).catch(error => {
      console.log('There was an error!', error);
      setIsLoading(false);
      setResponse(false);
      setOpen(false);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "32402"){
        appContext.setError(t("category.category_exist"))
      }else
      document.getElementById("successPopUpButton").click();
    });
  }
  function editCategory() {
    setOpen(true);
    setIsLoading(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    const body = { "category": inputs.category.trim() }
    Axios.put("category/" + categoryId, body).then((response) => {
      setIsLoading(false);
      if (response.status === 200) {
        setResponse(true);
        setTimeout(() => {
          navigate("/job-category/"+page);
          setOpen(false);
       }, 200);
      }
    }).catch(error => {
      console.log('There was an error!', error);
      setIsLoading(false);
      setResponse(false);
      setOpen(false);
      setErrorCode(error.response?.data?.errorCode);
      document.getElementById("successPopUpButton").click();
    });
  }
  function enterKeyEvent(){
    let el = document.getElementById("myInputID");
    el.addEventListener("keypress", function(event) {
    if (event.key === "Enter") {
      document.getElementById("saveBtn").click();
      event.preventDefault();
    }
   });
  }
  return (
    <div className={styles.CreateIndustry} data-testid="CreateIndustry">
      <div>
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-5">
          {categoryId ? <h5>{t('category.edit_category')}</h5> : <h5>{t('category.create_category')}</h5>}
        </div>
        <div class="card create-card p-4 vh-100">
          <div class="card-body">
            <form>
              <div class="row mb-3">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('category.category_name')}</label><span class="required">*</span>
                    <input type="text" class="form-control custom-input2" id="myInputID" aria-describedby="emailHelp" placeholder={t('category.category_name')}
                      maxLength={100} name="category" value={inputs.category} onChange={handleChange} />
                  </div>
                  {(clickSubmit && !inputs.category) ? <div className="error">{t('category.category_required')}</div> : null}
                </div>

              </div>
              <div class="col-md-12 text-end my-5">
                {!isLoading ? <div>
                  {page?<Link to={"/job-category/" + page}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link> :
                  <Link to={"/job-category/1"}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link>}
                  <button class="main-buttons sub-btn" id="saveBtn" onClick={(e) => submit(e)}>{categoryId?t('update') : t('save')}</button>
                </div> : null}
                {isLoading === true ? <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px" }}></Spinner> : null
                }
              </div>
            </form>
          </div>
        </div>

      </div>
      {/*SUCCESS POPUP */}
      <a href="" class="edit-icon" data-bs-toggle="modal" id="successPopUpButton" data-bs-target="#successPopup" hidden></a>

      <div class="modal fade" id="successPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-confirm ">
          <div class="modal-content">
            <div class="modal-header justify-content-center">
              <div class="icon-box">
                {response === true ? <i class="fa fa-check" aria-hidden="true"></i> : <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>}
              </div>
              <button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
            </div>
            <div class="modal-body text-center">
              {response === true ? <h4>{t('success')}</h4> : <h4>{t('error')}</h4>}
              {response === true && !categoryId && <p>{t('category.category_created')}</p>}
              {response === true && categoryId && <p>{t('category.category_updated')}</p>}
              {response === false && errorCode === "32402" && <p>{t('category.category_exist')}</p>}
              {response === false && errorCode === "32401" && <p>{t('category.category_not_found')}</p>}
              {response === true ? <Link to={"/job-category/" + page}> <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button> </Link>
                : <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button>}
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </div>
  )
};

export default CreateJobCategory;
