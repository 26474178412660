import React, { useEffect, useState } from "react";
import useAppContext from "../../AppContext";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";

function ChangePassword() {
  const { t } = useTranslation();
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  const navigate = useNavigate();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealconPwd, setIsRevealconPwd] = useState(false);
  const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
  
  const vaildPasswordRegex = RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+={}\[\]>?.<|:;'',`"/\\-])?[A-Za-z\d~!@#$%^&*()_+={}\[\]>?.<|:;'',`"/\\-]{8,20}$/
  );
  const [changePassword, setChangePassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [changePasswordEncrypted, setChangePasswordEncrypted] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const { oldPassword, newPassword, confirmNewPassword } = changePassword;
  const [passwordError, setPasswordError] = useState(null);
  const [newpasswordError, setNewpasswordError] = useState(null);
  const [confirmpasswordError, setConfirmpasswordError] = useState(null);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    appContext.setError(null);
    appContext.setSuccess(null)
  }, []);
  const onInputChange = (e) => {
    e.preventDefault();
    setChangePassword({
      ...changePassword,
      [e.target.name]: e.target.value.trimStart(),
    });
    if (e.target.name === "oldPassword") {
      setPasswordError(null);
      if (e.target.value === "") {
        setPasswordError(t("change_password_page.old_password_error"));
      }
    }
    if (e.target.name === "newPassword") {
      setNewpasswordError(null);
      if (e.target.value === "") {
        setNewpasswordError(t("change_password_page.new_password_error"));
      } else if (!vaildPasswordRegex.test(e.target.value)) {
        setNewpasswordError(t("change_password_page.new_password_valid"));
      } else if (
        changePassword.confirmNewPassword !== "" &&
        e.target.value !== "" &&
        changePassword.confirmNewPassword !== e.target.value
      ) {
        setConfirmpasswordError(t("change_password_page.match_Error"));
      } else if (
        changePassword.confirmNewPassword !== "" &&
        e.target.value !== "" &&
        changePassword.confirmNewPassword === e.target.value
      ) {
        setConfirmpasswordError(null);
      }
    } else if (e.target.name === "confirmNewPassword") {
      setConfirmpasswordError(null);
      if (e.target.value === "") {
        setConfirmpasswordError(
          t("change_password_page.confirm_new_password_error")
        );
      } else if (
        e.target.value !== "" &&
        changePassword.newPassword !== "" &&
        e.target.value !== changePassword.newPassword
      ) {
        setConfirmpasswordError(t("change_password_page.match_Error"));
      } else if (!vaildPasswordRegex.test(e.target.value)) {
        setConfirmpasswordError(t("change_password_page.new_password_valid"));
        return;
      } 
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    appContext.setError(null);
    appContext.setSuccess(null)
    if (changePassword.oldPassword === "") {
      setPasswordError(t("change_password_page.old_password_error"));
    }
    if (changePassword.newPassword === "") {
      setNewpasswordError(t("change_password_page.new_password_error"));
    }
    if (
      changePassword.newPassword !== "" &&
      !vaildPasswordRegex.test(changePassword.newPassword)
    ) {
      setNewpasswordError(t("change_password_page.new_password_valid"));
    }
    if (changePassword.confirmNewPassword === "") {
      setConfirmpasswordError(
        t("change_password_page.confirm_new_password_error")
      );
    }
    if (
      changePassword.confirmNewPassword !== "" &&
      !vaildPasswordRegex.test(changePassword.confirmNewPassword)
    ) {
      setConfirmpasswordError(t("change_password_page.new_password_valid"));
    }
    if (
      changePassword.confirmNewPassword !== "" &&
      changePassword.newPassword !== "" &&
      changePassword.confirmNewPassword !== changePassword.newPassword
    ) {
      setConfirmpasswordError(t("change_password_page.match_Error"));
    } else {
      if (
        changePassword.newPassword !== "" &&
        changePassword.confirmNewPassword !== "" &&
        changePassword.oldPassword !== "" &&
        vaildPasswordRegex.test(changePassword.newPassword) &&
        vaildPasswordRegex.test(changePassword.confirmNewPassword)
      ) {
        
        changePasswordEncrypted.oldPassword = encryptData(changePassword.oldPassword)
        changePasswordEncrypted.newPassword =   encryptData(changePassword.newPassword)
        changePasswordEncrypted.confirmNewPassword = encryptData(changePassword.confirmNewPassword)
        setOpen(true);
        axios.put("changePassword", changePasswordEncrypted).then(
          (response) => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            console.log(response);
            appContext.setSuccess(t("change_password_page.password_success"));
            if (response.status === 200) {
              setTimeout(() => {
                localStorage.clear();
                window.location.replace("/login");
                setOpen(false);
             }, 2000);
            }
           // document.getElementById("successPopUpButton").click();
          },
          (error) => {
            console.log(error);

            if (error?.response?.data?.errorCode === "31301") {
              setPasswordError(t("change_password_page.old_invalid"));
            } else if (error?.response?.data?.errorCode === "31305") {
              setNewpasswordError(t("change_password_page.cant_be_same"));
            } else {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              appContext.setError(t("change_password_page.password_change_failed"))
            }
            setTimeout(() => {
              setOpen(false);
              console.log(error.response.data.message);
            }, 1000);
          }
        );
      }
    }
  };
  function handleOnClickPopUp() {
    window.location.reload();

    setTimeout(() => {
      navigate("/login");
    }, 400);
  }
  function onCancel(){
    navigate("/user/1");
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSubmit(event)
    }
  };
  function encryptData(userBody){
    const secretPass = "XkhZG4fW2t2W";
    
    // const data = CryptoJS.AES.encrypt(
    //   JSON.stringify(userBody),
    //   secretPass
    // ).toString();
    // return data;
    return CryptoJS.AES.encrypt(userBody, secretPass).toString();
  }
  return (
    <>
    
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-5">
          <h5>{t("change_password_page.heading")}</h5>
        </div>
        <div className="card create-card p-4">
          <div className="card-body">
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="oldPassword"
                      className="form-label input-label"
                    >
                      {t("change_password_page.current_password")}
                    </label>
                    <div className="dob-inp">
                    <input
                       type={isRevealPwd ? "text" : "password"}
                      id="name"
                      aria-describedby="emailHelp"
                      name="oldPassword"
                      value={oldPassword}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={handleKeyDown}
                      className={`form-control custom-input2 custom-input-changeJa ${
                        passwordError ? "is-invalid password-errorshow" : ""
                      }`}
                    />
                    {passwordError ? (
                      <div className="error">{passwordError}</div>
                    ) : (
                      ""
                    )}
                       <span
                        className="calender-icon"
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      >
                        {" "}
                        <i
                          className={
                            isRevealPwd
                              ? "fa-solid fa-eye"
                              : "fa-solid fa-eye-slash"
                          }
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="password"
                      className="form-label input-label"
                    >
                      {t("change_password_page.new_password")}
                    </label>
                    <div className="dob-inp">
                    <input
                        type={isRevealNewPwd ? "text" : "password"}
                      id="name"
                      aria-describedby="emailHelp"
                      name="newPassword"
                      value={newPassword}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={handleKeyDown}
                      className={`form-control custom-input2 custom-input-changeJa ${
                        newpasswordError ? "is-invalid password-errorshow" : ""
                      }`}
                    />
                    {newpasswordError ? (
                      <div className="error">{newpasswordError}</div>
                    ) : (
                      ""
                    )}
                     <span
                        className="calender-icon"
                        onClick={() =>
                          setIsRevealNewPwd((prevState) => !prevState)
                        }
                      >
                        {" "}
                        <i
                          className={
                            isRevealNewPwd
                              ? "fa-solid fa-eye"
                              : "fa-solid fa-eye-slash"
                          }
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="password"
                      className="form-label input-label"
                    >
                      {t("change_password_page.confirm_new_password")}
                    </label>
                    <div className="dob-inp">
                    <input
                    type={isRevealconPwd ? "text" : "password"}
                      id="name"
                      aria-describedby="emailHelp"
                      name="confirmNewPassword"
                      value={confirmNewPassword}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={handleKeyDown}
                      className={`form-control custom-input2 custom-input-changeJa ${
                        confirmpasswordError ? "is-invalid password-errorshow" : ""
                      }`}
                    />
                    {confirmpasswordError ? (
                      <div className="error">{confirmpasswordError}</div>
                    ) : (
                      ""
                    )}
                       <span
                        className="calender-icon"
                        onClick={() =>
                          setIsRevealconPwd((prevState) => !prevState)
                        }
                      >
                        {" "}
                        <i
                          className={
                            isRevealconPwd
                              ? "fa-solid fa-eye"
                              : "fa-solid fa-eye-slash"
                          }
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end my-5">
                <button className="cancel-btn" data-bs-dismiss="modal" onClick={onCancel}>
                  {t("change_password_page.cancel")}
                </button>
                <button className="main-buttons sub-btn">
                  {" "}
                  {t("change_password_page.save")}
                </button>
              </div>
            </form>
          </div>
        </div>
    
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
      <a
        href=""
        className="edit-icon"
        data-bs-toggle="modal"
        id="successPopUpButton"
        data-bs-target="#successPopup"
        hidden
      ></a>
      <div
        className="modal fade"
        id="successPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-confirm ">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <div className="icon-box">
                <i className="fa fa-check" aria-hidden="true"></i>
              </div>
            </div>
            <div className="modal-body text-center">
              <p>{t("change_password_page.password_success")}</p>
              <button
                className="btn btn-success"
                data-dismiss="modal"
                onClick={handleOnClickPopUp}
              >
                <span>{t("ok")}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
