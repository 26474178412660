import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAppContext from "../../AppContext";
import i18n from "../../i18n";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
function HotJob() {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  const [hotJobs, setHotJobs] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState(null);
  const limit = 10;
  const [currentPage] = useState(1);
  const [deleteRow, setDeleteRow] = useState("");
  const [jobError, setJobError] = useState(null);
  const [modalClose, setModalClose] = useState(false);
  const [selectOptions, setSelectOptions] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    getAllJobs();
    getHotJobs();
   // eslint-disable-next-line 
  }, []);
  function getHotJobs(){
    appContext.setError(null);
    appContext.setSuccess(null)
    axios
      .get("hotJob")
      .then((response) => {
      
        console.log(response);
        setHotJobs(response?.data);
        setIsLoaded(true);
        setOpen(false);
     
      })
      .catch((error) => {
        setIsLoaded(true);
        setOpen(false);
        console.log("There was an error!", error);
      });
  }
  function getAllJobs(){
    let options;
    axios
      .get("job/list/published")
      .then((response) => {
      
        console.log(response?.data);
        setJobs(response?.data);
        console.log(jobs);
        options = response?.data?.map(item => {
          return {
            label: String(item.jobTitle),
            value: item.jobId
          }
        })
        setSelectOptions(options);
        console.log(options);
        setOpen(false);
     
      })
      .catch((error) => {
        setOpen(false);
        console.log("There was an error!", error);
      });
  }
  function handleChange(event) {
    console.log(event?.value);
    setJobId(event);
  }
  function onDelete(row) {
    console.log(row);
    setDeleteRow(row);
  }
  function deleteCategory() {
    setOpen(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    axios
      .delete("/hotJob/" + deleteRow.hotJobId)
      .then((response) => {
        setOpen(false);
        getHotJobs();
        window. scrollTo(0, 0);
        appContext.setSuccess(t("hotJob.hotjob_deleted"))
      })
      .catch((error) => {
        setOpen(false);  
        window. scrollTo(0, 0);     
        appContext.setError(t("hotJob.hot_deletion_failed"))      
      });
  }
  function saveHotJob(){
    setJobError(null);
    console.log(jobId);
    appContext.setError(null);
    appContext.setSuccess(null)
    if (jobId === null) {
      setJobError(t("hotJob.valid_job"));
      setModalClose(false);
    } else {
      let data = { jobId: jobId?.value };
      setOpen(true);
      window. scrollTo(0, 0);  
      axios
        .post("hotJob", data)
        .then((response) => {
          getHotJobs();
          setJobId(null);
          console.log(response);
          setModalClose(true);
          document.getElementById("deletePopup").click();
          setOpen(false);
          appContext.setSuccess(t("hotJob.hotjob_added"))
        })
        .catch((error) => {
          setOpen(false);
          setModalClose(true);
          document.getElementById("deletePopup").click();
          if(error?.response?.data?.errorCode === "314401"  || error?.response?.data?.errorCode === "312317") {
            appContext.setError(t("hotJob.job_notFound"))
          } else if(error?.response?.data?.errorCode === "314402") {
            appContext.setError(t("hotJob.already_exist"))
          }  else if(error?.response?.data?.errorCode === "314403") {
            appContext.setError(t("hotJob.maxCount_exceed"))
          } 
          else {
            console.log(error);
           
            appContext.setError(t("hotJob.job_notFound"))
          }
          console.log(modalClose);
        });
    }
  }
  function addJob(){
    setJobId(null)
    setJobError(null)
    appContext.setError(null);
    appContext.setSuccess(null)
  }
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-4">
        <h5>{t("hotJob.hotjobs")}</h5>
        <div className="col-md-3 text-end">
          <button
            className="create-btn mt-3 mb-3"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal" onClick={addJob}
          >
            <i className="fa-solid fa-plus"></i>{t("hotJob.heading")}
          </button>
        </div>
      </div>
      {isLoaded ?  
      <div>
       {hotJobs.length > 0 ? 
      <div className="card table-card">
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table custom-table1">
              <thead className="table-header">
                <tr>
                  <th scope="col" style={{"width":"5%"}}>{t("hotJob.slno")}</th>
                  <th scope="col" style={{"width":"20%"}}>{t("hotJob.hotjobs")}</th>
                  <th scope="col" style={{"width":"5%"}}>{t("hotJob.action")}</th>
                </tr>
              </thead>
              <tbody>
              {hotJobs.length > 0 ? (
                  <>
                    {hotJobs?.map((row, index) => (
                <tr>
                  <td >{index + 1 + limit * (currentPage - 1)}</td>
                  <td>{row?.job?.jobName}</td>
                  <td >
                    <div className="row">
                      <div className="col-md-12  ">
                        <button
                          className="delete-btn me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal2"
                          onClick={(e) => onDelete(row)}
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>

))}{" "}
</>
) : (
<tr>
  <td className="no-data" colspan="3">
    {t("hotJob.no_data_found")}
  </td>
</tr>
)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
 : <div><h6 style={{ "textAlign": "center","fontWeight":"bold","lineHeight":"400px" }}> {t("hotJob.no_data_found")}</h6></div>}
 </div>: <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px","marginTop":"150px" }}></Spinner> }
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
        <button class="edit-icon" data-bs-toggle="modal" id="deletePopup" data-bs-target="#exampleModal" hidden></button>
       <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button type="button" className="btn-close close-pop" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-5 py-3 text-start">
                        <h5>{t("hotJob.hotjobs")}</h5>

                        <div className="row mb-2 mt-5 px-3">
                            <div className="mb-3">
                                <label for="name" className="form-label input-label">{t("hotJob.select_hotjob")}</label>
                            
                <Select aria-label="Default select example" placeholder= {t("hotJob.select_hotjob")}  style={{"height":"60px","paddingLeft":"10px"}}
                    name="jobId" value={jobId}   onChange={handleChange}  options={selectOptions} 
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#CBCBCB',
                        boxShadow: 'none',
                        '&:hover': {
                            border: '1px solid #86b7fe',
                        },
                        height : "60px",
                        borderRadius:"6px",
                        }),
                        
                                            
                    }} 
                    noOptionsMessage={() => t('no_results')}  >
                  
                  </Select>
                  {jobError ? (
                    <div className="error">{jobError}</div>
                  ) : (
                    ""
                  )}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-0">
                        <div className="col-md-12 text-center mb-5">
                            <button className="cancel-btn" data-bs-dismiss="modal">{t("hotJob.cancel")}</button>
                            <button className="main-buttons sub-btn"
                                      onClick={saveHotJob}>{t("add")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    
        <div className="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">

                    <div className="modal-body px-5 py-3">
                        <div className="row mb-2 mt-5 px-3">
                            <div className="col-md-12 text-center">
                                <i className="fa-regular fa-circle-xmark delete-pop"></i>
                            </div>

                            <div className="text-center mt-4 mb-4">
                                <h5>{t("hotJob.delete_header")}</h5>
                            </div>

                            <div className="text-center">
                                <p>{t("hotJob.delete_description")}</p>
                            </div>


                        </div>
                        <div className="col-md-12 text-center mb-3">
                            <button className="cancel-btn" data-bs-dismiss="modal">{t("hotJob.cancel")}</button>
                            <button className="main-buttons sub-btn" data-bs-dismiss="modal" onClick={deleteCategory}>{t("hotJob.delete")}</button>
                        </div>
                    </div>
                    {/* <div className="modal-footer border-0">
                       
                    </div> */}
                </div>
            </div>
        </div>
    </>
  );
}

export default HotJob;
