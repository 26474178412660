import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAppContext from "../../AppContext";
import i18n from "../../i18n";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
function PickupCategory() {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  const [pickUpCategory, setPickUpCategory] = useState([]);
  const [category, setPCategory] = useState([]);
  const limit = 10;
  const [currentPage] = useState(1);
  const [deleteRow, setDeleteRow] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [categoryError, setCategoryError] = useState(null);
  const [modalClose, setModalClose] = useState(false);
  const [selectOptions, setSelectOptions] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
  
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    getAllSubCategories();
    getPickupCategories();
     // eslint-disable-next-line 
  }, []);
  function getPickupCategories() {   
    appContext.setError(null);
    appContext.setSuccess(null)
    axios
      .get("pickup")
      .then((response) => {
        console.log(response);
        setPickUpCategory(response?.data);
        setIsLoaded(true);
        setOpen(false);
      })
      .catch((error) => {
        setIsLoaded(true);
        setOpen(false);
        console.log("There was an error!", error);
      });
  }
  function getAllSubCategories() {
    let options;
    axios
      .get("subCategory")
      .then((response) => {
        console.log(response);
        setPCategory(response?.data?.subCategoryList);
        console.log(category);
        options = response?.data?.subCategoryList?.map(item => {
          return {
            label: String(item.subCategoryName),
            value: item.subCategoryId
          }
        })
        setSelectOptions(options);
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        console.log("There was an error!", error);
      });
  }
  function onDelete(row) {
    console.log(row);
    setDeleteRow(row);
  }
  function deleteCategory() {
    appContext.setError(null);
    appContext.setSuccess(null)
    setOpen(true);
    axios
      .delete("/pickup/" + deleteRow.pickupCategoryId)
      .then((response) => {
        setOpen(false);
        getPickupCategories();
        appContext.setSuccess(t("pikcup.category_deleted"))
      })
      .catch((error) => {
        setOpen(false);
        if(error?.response?.data?.errorCode === "314901") {
          appContext.setError(t("pikcup.category_deletion_failed"))
        } else {
          console.log(error)
          appContext.setError(t("pikcup.category_deletion_failed"))
        }
      
      });
  }
  function handleChange(event) {
    console.log(event?.value);
    setCategoryId(event);
  }

  function saveCategory() {
    setCategoryError(null);
    appContext.setError(null);
    appContext.setSuccess(null)
    console.log(categoryId);
    if (categoryId === null) {
      setCategoryError(t("pikcup.valid_Category"));
      setModalClose(false);
    } else {
      let data = { categoryId: categoryId?.value };
      setOpen(true);

      axios
        .post("pickup", data)
        .then((response) => {
          getPickupCategories();
          setCategoryId(null);
          console.log(response);
          setModalClose(true);
          document.getElementById("deletePopup").click();
          setOpen(false);
          appContext.setSuccess(t("pikcup.category_added"))
        })
        .catch((error) => {
          setOpen(false);
          setModalClose(true);
          document.getElementById("deletePopup").click();
          if(error?.response?.data?.errorCode === "310800" || error?.response?.data?.errorCode === "33301" || error?.response?.data?.errorCode === "33201") {
            appContext.setError(t("pikcup.category_notFound"))
          } else if(error?.response?.data?.errorCode === "310801") {
            appContext.setError(t("pikcup.maxCount_exceed"))
          } else if(error?.response?.data?.errorCode === "314802") {
            appContext.setError(t("pikcup.already_exist"))
          } 
          else {
            appContext.setError(t("pikcup.category_notFound"))
          }
          console.log(modalClose);
        });
    }
  }
  function addPickup() {
    setCategoryId(null);
    setCategoryError(null)
    appContext.setError(null);
    appContext.setSuccess(null)
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-4">
        <h5> {t("pikcup.heading")}</h5>
        <div className="col-md-3 text-end">
          <button
            className="create-btn mt-3 mb-3"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={addPickup}
          >
            <i className="fa-solid fa-plus"></i>
            {t("pikcup.add")}
          </button>
        </div>
      </div>
      {isLoaded ?  
      <div>
       {pickUpCategory.length > 0 ? 
      <div className="card table-card">
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table custom-table1">
              <thead className="table-header">
                <tr>
                  <th scope="col" style={{"width":"5%"}}>{t("pikcup.slno")}</th>
                  <th scope="col" style={{"width":"25%"}}>{t("pikcup.category")}</th>
                  <th scope="col" style={{"width":"5%"}}>{t("pikcup.action")}</th>
                </tr>
              </thead>
              <tbody>
                {pickUpCategory.length > 0 ? (
                  <>
                    {pickUpCategory?.map((row, index) => (
                      <tr>
                        <td >{index + 1 + limit * (currentPage - 1)}</td>
                        <td className="site_text_order">
                      
                          {row?.subCategory.name}</td>
                        <td>

                          <div>
                            <div className="col-md-12  ">
                              <button
                                className="delete-btn me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal2"
                                onClick={(e) => onDelete(row)}
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}{" "}
                  </>
                ) : (
                  <tr>
                    <td className="no-data" colspan="3">
                      {t("pikcup.no_data_found")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      : <div><h6 style={{ "textAlign": "center","fontWeight":"bold","lineHeight":"400px" }}> {t("pikcup.no_data_found")}</h6></div>}
        </div>: <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px","marginTop":"150px" }}></Spinner> }
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
      <button class="edit-icon" data-bs-toggle="modal" id="deletePopup" data-bs-target="#exampleModal" hidden></button>
      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close close-pop"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body px-5 py-3 text-start">
              <h5>{t("pikcup.addpickUp")}</h5>

              <div className="row mb-2 mt-5 px-3">
                <div className="mb-3">
                  <label for="name" className="form-label input-label">
                    {t("pikcup.select")}
                  </label>
                <Select  aria-label="Default select example" placeholder=  {t("pikcup.select_category")}  style={{"height":"60px","paddingLeft":"10px"}}
                    name="categoryId" value={categoryId}   onChange={handleChange}  options={selectOptions} 
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#CBCBCB',
                        boxShadow: 'none',
                        '&:hover': {
                            border: '1px solid #86b7fe',
                        },
                        height : "60px",
                        borderRadius:"6px",
                        }),
                        
                                            
                    }} 
                    noOptionsMessage={() => t('no_results')}  >
                  
                  </Select>
                        {/* <Dropdown className="form-select custom-input2" filterPlaceholder=""  value={categoryId} options={category}  onChange={(e) => setCategoryId(e.value)} filterInputAutoFocus="true" optionValue="subCategoryId" optionLabel="subCategoryName" filter   filterBy="subCategoryName" placeholder= {t("pikcup.select_category")}
                       /> */}
                  {categoryError ? (
                    <div className="error">{categoryError}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div className="col-md-12 text-center">
                
                <button className="cancel-btn" data-bs-dismiss="modal">
                  {t("pikcup.cancel")}
                </button>
                <button
                  className="main-buttons sub-btn  mt-0"
                  onClick={saveCategory}
                >
                  {t("add")}
                </button>
              </div>
            
            </div>
          </div>
        </div>
      </div>
  
      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body px-5 py-3">
              <div className="row mb-2 mt-5 px-3">
                <div className="col-md-12 text-center">
                  <i className="fa-regular fa-circle-xmark delete-pop"></i>
                </div>

                <div className="text-center mt-4 mb-4">
                  <h5>{t("pikcup.deleteHeading")}</h5>
                </div>

                <div className="text-center">
                  <p>{t("pikcup.delete_description")}</p>
                </div>
              </div>
              <div className="col-md-12 text-center mb-3">
                <button className="cancel-btn" data-bs-dismiss="modal">
                  {t("pikcup.cancel")}
                </button>
                <button
                  className="main-buttons sub-btn"
                  data-bs-dismiss="modal"
                  onClick={deleteCategory}
                >
                  {t("pikcup.delete")}
                </button>
              </div>
            </div>
            {/* <div className="modal-footer border-0">
             
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default PickupCategory;
