import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import useAppContext from '../../AppContext';
import styles from './job.module.css';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import Pagination from '../../pagination.js';
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Job = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const search = useLocation().search;
  const appContext = useAppContext();
  const Axios = appContext.getAxios();
  const limit = 10;
  const [count, setCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(parseInt(params.page));
  const [lastPage, setLastPage] = useState();
  const [jobList, setJobList] = useState([]);
  const [jobId, setJobId] =  useState();
  const [isLoadedAllJobs, setIsLoadedAllJobs] = useState(false);
  const [publish, setPublish] = useState();
  const [deleted, setDeleted] =  useState();
  const [response, setResponse] = useState(false);
  const [freeWord, setFreeWord] =  useState(new URLSearchParams(search)?.get('search') );
  const [errorCode, setErrorCode] =  useState();
  
  useEffect(() => {
    window. scrollTo(0, 0);
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    getJob(currentPage);
    // eslint-disable-next-line 
    window.addEventListener('popstate', onBackButtonEvent);
}, []);
const onBackButtonEvent = (e) => {
  e.preventDefault();
  window.location.reload()
}
function tableScroll(){
  const slider = document.querySelector('.table-responsive1');
  console.log(slider)
  let isDown = false;
  let startX;
  let scrollLeft;
  
  slider?.addEventListener('mousedown', (e) => {
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider?.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider?.addEventListener('mouseup', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider?.addEventListener('mousemove', (e) => {
    if(!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 3; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
    console.log(walk);
  });
}
function getJob(page) {
  appContext.setError(null);
  appContext.setSuccess(null)
  setIsLoadedAllJobs(false);
  if(freeWord === "" || !freeWord){
   Axios.get("job/list?limit=" + limit + "&page=" + page).then((response) => {
    setJobList(response?.data?.data);
    setCount(response?.data?.totalCount)
    setLastPage(response?.data?.totalPages);
    setIsLoadedAllJobs(true);
    setTimeout(() => {
      tableScroll();
   }, 500);
    console.log(response.data.data);
    const totalPageCount = Math.ceil(response?.data?.totalCount / limit);
    if(page > totalPageCount){
      setCurrentPage(currentPage-1);
      if(totalPageCount !== 0){
        navigatePage(currentPage-1);
      }
    }
    
  }).catch(error => {
    setIsLoadedAllJobs(true);
    console.log('There was an error!', error);
  });
   }else{
    Axios.get("job/list?limit=" + limit + "&page=" + page + "&search="+encodeURIComponent(freeWord.trim()) ).then((response) => {
    
      setJobList(response?.data?.data);
      setCount(response?.data?.totalCount)
      setLastPage(response?.data?.totalPages);
      setIsLoadedAllJobs(true);
      setTimeout(() => {
        tableScroll();
     }, 500);     
      console.log(response.data.data);
      const totalPageCount = Math.ceil(response?.data?.totalCount / limit);
      if(page > totalPageCount){
        setCurrentPage(currentPage-1);
        if(totalPageCount !== 0){
          navigatePage(currentPage-1);
        }
      }
    }).catch(error => {
      setIsLoadedAllJobs(true);
      console.log('There was an error!', error);
    });
}
}
function publishJob(id,publishStatus){
  setDeleted(0);
  setPublish(0);
  appContext.setError(null);
  appContext.setSuccess(null)
  let status;
  if(publishStatus === 1){
    setPublish(2)
    status = 2;
  }else if(publishStatus === 0 || publishStatus === 2){
    setPublish(1);
    status = 1;
  }
  Axios.patch('job/changeStatus/'+id,{"status":status}).then(
    (response) => {
      setResponse(true)
      console.log(response);
      getJob(currentPage);
      window. scrollTo(0, 0);
      if(publishStatus === 1){
        appContext.setSuccess(t("job_unpublished"))
       
      } else  if(publishStatus ===2 || publishStatus === 0){
        appContext.setSuccess(t("job_published"))
      }
     
    },
    (error) => {
      console.log(error)
      setResponse(false);
      document.getElementById("successPopUpButton").click();
    }
  );
}
function deleteJob(id){
  setPublish(0);
  setDeleted(0);
  appContext.setError(null);
  appContext.setSuccess(null)
  Axios.delete('job/'+id).then(
    (response) => {
      setResponse(true)
      console.log(response);
      setDeleted(1);
      getJob(currentPage);
      appContext.setSuccess(t("job_deleted"))
      window. scrollTo(0, 0);
    },
    (error) => {
      console.log(error)
      setResponse(false);
      window. scrollTo(0, 0);
      getJob(currentPage);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "34203"){
        appContext.setError(t("job_not_found"))
      }else{
        appContext.setError(t("error"))
      }
     
    }
  );
}
function pageChange(page) {
  if(page === currentPage){
    return;
  }
  setCurrentPage(page);
  if (page >= 1 && page <= lastPage) {
    navigatePage(page);
  }
}
function navigatePage(page) {
  if(freeWord === "" || freeWord === null){
    navigate("/job/"+page);
  }else{
    navigate("/job/"+page+"?search="+freeWord);
  }
 getJob(page);
 window. scrollTo(0, 0);
}
function handleClosePopup(){
  if(jobList.length === 0 && currentPage > 1){
    navigate("/job/"+(currentPage-1));
    getJob(currentPage-1);
    setCurrentPage(currentPage-1);
  }else{
    getJob(currentPage);
  }
}
function clickDelete(id){
  setJobId(id)
  document.getElementById("deletePopUpButton").click();
}
function handleDelete(){
deleteJob(jobId)
}
const handleOnChangeFreeText = (event) => {
  setFreeWord(event.target.value)
  console.log(freeWord)
}
const handleKeyDown = (e) =>{
  if (e.key === 'Enter') {
    freeWordSearch();
  }
}
function freeWordSearch(){
  setCurrentPage(1);
  getJob(1);
  if(freeWord === "" || freeWord === null){
    navigate("/job/1");
  
  }else{
    navigate("/job/1?search="+freeWord);
  }

}
  return (
    <div className={styles.Job} data-testid="Job">
      <body>
          <div className="mt-1" >
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-4">
              <h5>{t('job_management')}</h5>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-4">
                    <Link to="/create-job" state={{page: currentPage,search:freeWord}}><button className="create-btn mt-3 mb-3"><i className="fa-solid fa-plus"></i>{t('create_job')}</button></Link>
                  </div>
                  <div className="col-md-8">
                    <div className="input-group mt-3 mb-3">
                      <input type="text" className="form-control custom-search" placeholder={t('search_by_job')} aria-label="Search" aria-describedby="basic-addon2" 
                       onKeyDown={handleKeyDown} onChange={e => handleOnChangeFreeText(e)} value={freeWord} id="myInputID" />
                      <button type="button" className="input-group-text search-btn" id="basic-addon2" onClick={freeWordSearch}><i className="fa-solid fa-magnifying-glass"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
             
            { isLoadedAllJobs &&<div>
            { jobList?.length > 0 && <div className="card table-card">
              <div className="card-body p-0">
                <div className="table-responsive1">
                  <table className="table custom-table3" style={{"minWidth":"768px"}}>
                    <thead className="table-header">
                      <tr>
                        <th scope="col" style={{"width":"5%"}}>{t('sl_no')}</th>
                        <th scope="col" style={{"width":"5%"}}>{t('job_title')}</th>
                        <th scope="col" style={{"width":"5%"}}>{t('job_No')}</th>
                        <th scope="col" style={{"width":"5%"}}>{t('company_name')}</th>
                        <th scope="col" style={{"width":"5%"}}>{t('employment_type')}</th>
                        <th scope="col" style={{"width":"5%"}}>{t('working_hours')}</th>
                        <th scope="col" style={{"width":"5%"}}>{t('holidays')}</th>
                        <th scope="col" style={{"width":"5%"}}>{t('benefits')}</th>
                        <th scope="col" style={{"width":"7%"}}>{t('tags')}</th>
                        <th scope="col" style={{"width":"8%"}}>{t('conditions')}</th>
                        <th scope="col" style={{"width":"8%"}}>{t('job_categories')}</th>
                        <th scope="col" style={{"width":"8%"}}>{t('skills')}</th>
                        <th scope="col" style={{"width":"10%"}}>{t('comment_from')}</th>
                        <th scope="col" style={{"width":"10%"}}>{t('job_description')}</th>
                        <th scope="col" style={{"width":"5%"}}>{t('publish_job')}</th>
                        <th scope="col" style={{"width":"5%"}}>{t('action')}</th>
                      </tr>
                      
                    </thead>
                   <tbody>
                    {jobList.map(( job, index ) => {
                     return (
                      <tr>
                        <td>{(index+1)+(limit*(currentPage-1))}</td>
                        <td className="site_text_order site_text_order_align"><span className="table-text">{job.title}</span></td>
                        <td className="site_text_order site_text_order_align" title={job.jobNo}><span className="table-text">{job.jobNo}</span></td>
                        <td className="site_text_order site_text_order_align"><span className="table-text">{job.companyName}</span></td>
                        <td className="site_text_order site_text_order_align">{job.employeeTypeId}</td>
                        <td className="site_text_order site_text_order_align"><span className="table-text">{job.workingHours}</span></td>
                        <td className="site_text_order site_text_order_align"><span className="table-text">{job.holiday}</span></td>
                        <td className="site_text_order site_text_order_align"><span className="table-text">{job.benefits}</span></td>
                        <td className="site_text_order site_text_order_align">
                        <span className="table-text">
                        {job.tags.map(( tag,i ) => {
                         return (  <span> {tag?.tagName} 
                          {job?.tags.length-1 === i ? '': " , "}</span>)})}
                          </span>
                        </td>
                        <td className="site_text_order site_text_order_align"><span className="table-text">{job.conditions}</span></td>
                        <td className="site_text_order site_text_order_align">
                        <span className="table-text">
                        {job.jobSubCategorys.map(( categories,i ) => {
                         return (<span> {categories?.jobSubCategoryName} 
                          {job?.jobSubCategorys.length-1 === i ? '': " , "}</span>)})}
                          </span>
                        </td>
                        <td className="site_text_order site_text_order_align">
                          <span className="table-text">
                        {job.skills.map(( skill,i ) => {
                         return (<span> {skill?.skill_title} 
                          {job?.skills.length-1 === i ? '': " , "}</span>)})}
                          </span>
                        </td>                    
                        <td className="site_text_order site_text_order_align"><span className="table-text">{job.comments}</span></td>
                        <td className="site_text_order site_text_order_align"><span className="table-text">{job.description}</span></td>
                        <td>
                          {job.status === 1 ? <button className="Published-btn px-3" onClick={() => publishJob(job.jobId,job.status)} >{t('published')}</button>: null}
                          {job.status === 0 || job.status === 2 ? <button className="Publish-btn px-3" onClick={() => publishJob(job.jobId,job.status)}>{t('unpublished')}</button>: null}
                        </td>
                        <td><div className="row">
                          <div className="col-md-12">
                          <Link to = {"/create-job?id="+job.jobId} state={{page: currentPage,search:freeWord}}><button className="edit-btn me-2"><i className="fa-solid fa-pen"></i></button></Link>  
                            <button className="delete-btn me-2" onClick={() => clickDelete(job.jobId)}><i className="fa-solid fa-trash-can"></i></button>
                          </div>
                        </div></td>
                      </tr>)})}

                     
                    </tbody>
                  </table> 

                </div>
              </div>
            </div>}{jobList?.length === 0 && <div><h6 style={{ "textAlign": "center","fontWeight":"bold","lineHeight":"400px" }}>{t('job_not_found')}</h6></div>}
            </div>}{ !isLoadedAllJobs && <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px","marginTop":"150px" }}></Spinner> }
            


           {isLoadedAllJobs && <div className="col-md-12 mt-5">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                     <Pagination
                                  className="pagination-bar"
                                  currentPage={currentPage}
                                  totalCount={count}
                                  pageSize={limit}
                                  onPageChange={page => pageChange(page)}
                                />
                </ul>
              </nav>
            </div> }

          </div>


        <script src="js/bootstrap.bundle.js"></script>
      </body>
       {/*SUCCESS POPUP */}
       <a href="" class="edit-icon" data-bs-toggle="modal" id="successPopUpButton" data-bs-target="#successPopup" hidden></a>

<div class="modal fade" id="successPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-confirm ">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <div class="icon-box">
          {response === true && <i class="fa fa-check" aria-hidden="true"></i>}{response === false &&<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>}
        </div>
       <button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn"
          data-bs-dismiss="modal" aria-label="Close" onClick={handleClosePopup}><i class="fa-solid fa-xmark"></i></button>
      </div>
      <div class="modal-body text-center">
        {response === true ? <h4>{t('success')}</h4> : <h4>{t('error')}</h4>}
        {(response === true && publish === 1 && deleted === 0) && <p>{t('job_published')}</p> }
        {(response === true && publish === 2 && deleted === 0) && <p>{t('job_unpublished')}</p>}
        {(response === true && deleted === 1) &&  <p>{t('job_deleted')}</p> }
        {response === false && errorCode === "34203" && <p>{t('job_not_found')}</p> }
        <button class="btn btn-success btn-lg" data-bs-dismiss="modal" onClick={handleClosePopup}><span>{t('ok')}</span></button>
      </div>
    </div>
  </div>
</div>
  {/*DELETE POPUP */}
  <a href="" class="edit-icon" data-bs-toggle="modal" id="deletePopUpButton" data-bs-target="#deletePopup" hidden></a>

{/* <div class="modal fade" id="deletePopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-confirm ">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <div class="icon-box">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </div>
       <button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn"
          data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
      </div>
      <div class="modal-body text-center">
         <h4>{t('delete')}</h4>
         <p>{t('delete_confirm')}</p> 
        <button class="btn btn-success btn-sm" data-bs-dismiss="modal" style={{"background":"#A5A6A8","marginRight":"20px","width":"100px"}}>{t('cancel')}</button>
        <button class="btn btn-success btn-sm" data-bs-dismiss="modal" style={{"width":"100px"}}
         onClick={handleDelete} >{t('ok')}</button>
      </div>
    </div>
  </div>
</div> */}

<div class="modal fade" id="deletePopup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">

                    <div class="modal-body px-5 py-3">
                        <div class="row mb-2 mt-5 px-3">
                            <div class="col-md-12 text-center">
                                <i class="fa-regular fa-circle-xmark delete-pop"></i>
                            </div>

                            <div class="text-center mt-4 mb-4">
                                <h5>{t('are_u_sure')}</h5>
                            </div>

                            <div class="text-center">
                                <p>{t('delete_msg')}</p>
                            </div>


                        </div>
                        <div class="col-md-12 text-center mb-3">
                            <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button>
                            <button class="main-buttons sub-btn"  data-bs-dismiss="modal" onClick={handleDelete}>{t('delete')}</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </div>
  )
};


export default Job;
