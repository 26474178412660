import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import useAppContext from '../../AppContext';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import styles from './create-job.module.css';
import { Spinner } from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 

const CreateJob = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const appContext = useAppContext();
  const Axios = appContext.getAxios();
  const search = useLocation().search;
  const [job, setJob] = useState();
  const [category, setCategory] = useState([]);
  const [skill, setSkill] = useState([]);
  const [area, setArea] = useState([]);
  const [salary, setSalary] = useState([]);
  const [company, setCompany] = useState([]);
  const [employment, setEmployment] = useState([]);
  const [tag, setTag] = useState([]);
  const [jobId, setJobId] = useState("");
  const [clickSubmit, setClickSubmit] = useState(false);
  const [inputs, setInputs] = useState({});
  const [response, setResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] =  useState();

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState([]);
  const [tempCategory, setTempCategory] = useState([]);
  const [tempCategoryName, setTempCategoryName] = useState([]);

  const [selectedSkill, setSelectedSkill] = useState([]);
  const [selectedSkillName, setSelectedSkillName] = useState([]);
  const [tempSkill, setTempSkill] = useState([]);
  const [tempSkillName, setTempSkillName] = useState([]);

  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedAreaName, setSelectedAreaName] = useState([]);
  const [tempArea, setTempArea] = useState([]);
  const [tempAreaName, setTempAreaName] = useState([]);

  const [selectedTag, setSelectedTag] = useState([]);
  const [selectedTagName, setSelectedTagName] = useState([]);
  const [tempTag, setTempTag] = useState([]);
  const [tempTagName, setTempTagName] = useState([]);

  const [textCount,setTextCount] = useState(0);
  const [open, setOpen] = useState(false);
  const page = location?.state?.page;
  const freeWord = location?.state?.search;
  const [jobDetailList, setJobDetailList] = useState([]);
  const [jobUpdateList, setJobUpdateList] = useState({});
  const [catchphrase ,setCatchphrase] = useState(null)
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [catchError, setCatchError] = useState(null);
  const [erroroccupy, setErroroccupy] = useState(false)
  const [minSalaryList, setMinSalaryList] = useState([]);
  const [maxSalaryList, setMaxSalaryList] = useState([]);
  const [maxSalaryError, setMaxSalaryError] = useState(null);
  const [minSalaryError, setMinSalaryError] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    appContext.setError(null);
    appContext.setSuccess(null)
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    setJobId(new URLSearchParams(search).get('id') ? parseInt(new URLSearchParams(search).get('id')) : "");
    getCategory();
    getArea();
    getSalary();
    getSkill();
    getEmployment();
    getCompany();
    getTag();
    getMInMAxsalaray()
    if (new URLSearchParams(search).get('id')) {
      getJobByID( parseInt(new URLSearchParams(search).get('id')) );
    }
    console.log(job)
    // eslint-disable-next-line 
  }, []);
  function setJobDetails(job){
    setInputs(values => ({ ...values, "title": job?.jobTitle }));
    setInputs(values => ({ ...values, "jobNo": job?.jobNo }));
    setInputs(values => ({ ...values, "description": job?.description }));
    setInputs(values => ({ ...values, "Conditions": job?.conditions }));
    setInputs(values => ({ ...values, "comment": job?.comments }));
    setInputs(values => ({ ...values, "companyId": job?.companyId }));
    setInputs(values => ({ ...values, "workingHours": job?.workingHours }));
    setInputs(values => ({ ...values, "benefits": job?.benefits }));
    setInputs(values => ({ ...values, "holidays": job?.holiday }));
    setInputs(values => ({ ...values, "minSalaryId": job?.minSalaryId }));
    setInputs(values => ({ ...values, "maxSalaryId": job?.maxSalaryId }));
    checkCategoryDetails(job);
    checkTagDetails(job);
    checkSkillDetails(job);
    checkAreaDetails(job);
    checkEmploymentDetails(job);
   // checkSalaryDetails(job);
    getCompanyById(job?.companyId)
    setTextCount(job?.description.length);
  }
  function getArea() {
    Axios.get("highLevelLocation/prefecture").then((response) => {
      setArea(response.data.areaList);
      console.log(response.data.areaList);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getCategory() {
    Axios.get("category/subCategory").then((response) => {
      setCategory(response.data.categoryList);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getSalary() {
    Axios.get("salaryRange").then((response) => {
      setSalary(response.data?.salaryRangeList);
      console.log(salary);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getSkill() {
    Axios.get("skill").then((response) => {
      setSkill(response.data.skillList);
      console.log(skill);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getEmployment() {
    Axios.get("employmentType").then((response) => {
      setEmployment(response?.data?.employmentTypeList);
      console.log(employment);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getCompany() {
    Axios.get("company/name").then((response) => {
      setCompany(response?.data);
      console.log(company);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getTag() {
    Axios.get("tag").then((response) => {
      setTag(response?.data?.tagList);
      console.log(tag);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getJobByID(id) {
    setOpen(true);
    setCatchError(null)
    Axios.get("job/" + id).then((response) => {
      setOpen(false);
      setJob(response?.data);
      setJobDetails(response?.data)
      setData(response?.data)
     
      console.log(response.data);
    }).catch(error => {
      setOpen(false);
      console.log('There was an error!', error);
    });
  }
  function createJob() {
    setOpen(true);
    setIsLoading(true);
    let newArr =Object.keys(inputs)
    .filter(e => (inputs[e] !== "" && inputs[e] !== null))
    .reduce( (o, e) => {
         o[e]  = inputs[e]
         return o;
    }, {});
    console.log(newArr,"newaray")
    Axios.post('job', newArr).then(
      (response) => {
        console.log(response);
        setIsLoading(false);
        if (response.status === 200) {
          setTimeout(() => {
            setIsRevealPwd(false)    
            navigate("/job/1");
            setOpen(false);
         }, 200);
          setResponse(true);
        }
      },
      (error) => {
        setResponse(false);
        setIsLoading(false);
        setOpen(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setErrorCode(error.response?.data?.errorCode);
        if (error?.response?.data?.errorCode === "331103") {
          appContext.setError(t("job_no._already_exists"));
        }
        document.getElementById("successPopUpButton").click();
      }
    );
  }
  function editJob() {
    setOpen(true);
    setIsLoading(true)
    setJobUpdateList({})
    for (const key in jobDetailList) {
      console.log(jobDetailList)
      for (const keyCom in inputs) {
        
       
        if(key !== 'minSalaryId' && key !== 'maxSalaryId' && key !== 'companyId' && key !== 'jobNo' && key !== 'employmentType' && key !== 'jobCategoryId' && key !== 'locationId' && key !== 'skillIds' && key !== 'tagIds'){
          if(key === keyCom && jobDetailList[key] !== inputs[keyCom]){
            console.log(`${key}: ${inputs[key]}`);
            jobUpdateList[keyCom] = inputs[keyCom];
          }
        }else {
          // console.log(key);
          // console.log(jobDetailList[key]);
          // console.log(keyCom);
          // console.log(inputs[keyCom]);
          if(key === keyCom){
            if(key == 'companyId' && jobDetailList[key] !== inputs[keyCom]){
              jobUpdateList[keyCom] = inputs[keyCom];
            }
            if(key == 'jobNo' && jobDetailList[key] !== inputs[keyCom]){
              jobUpdateList[keyCom] = inputs[keyCom];
            }
            if(key == 'minSalaryId' && jobDetailList[key] !== inputs[keyCom]){
              jobUpdateList[keyCom] = inputs[keyCom];
            }
            if(key == 'maxSalaryId' && jobDetailList[key] !== inputs[keyCom]){
              jobUpdateList[keyCom] = inputs[keyCom];
            }
            if(key == 'employmentType' && jobDetailList[key] !== inputs[keyCom]){
              jobUpdateList[keyCom] = inputs[keyCom];
            }
          
            if(key == 'locationId' && jobDetailList[key] !== inputs[keyCom]){
              jobUpdateList[keyCom] = inputs[keyCom];
            }
            if(key == 'skillIds' && jobDetailList[key] !== inputs[keyCom]){
              jobUpdateList[keyCom] = inputs[keyCom];
            }
            if(key == 'jobCategoryId' && jobDetailList[key] !== inputs[keyCom]){
              jobUpdateList[keyCom] = inputs[keyCom];
            }
            if(key == 'tagIds' && jobDetailList[key] !== inputs[keyCom]){
              jobUpdateList[keyCom] = inputs[keyCom];
            }
          }
         
        }
      
        // console.log(jobUpdateList)
      }
  }

  for (const key in jobUpdateList) {
    if( jobUpdateList[key] === "" || jobUpdateList[key] === '' || jobUpdateList[key]?.length === 0 || jobUpdateList[key] === undefined){
      jobUpdateList[key] = null;
    }
   }
  // console.log(jobDetailList)
  // console.log("inputs",inputs)
  // console.log("jobUpdateList",jobUpdateList);
    Axios.put('job/' + jobId, jobUpdateList).then(
      (response) => {
        // console.log(response);
        if (response.status === 200) {
          setTimeout(() => {
            setIsRevealPwd(false)    
            if(freeWord){
              navigate("/job/"+page+"?search="+freeWord);
            }else{
              navigate("/job/"+page);
            }          
            setOpen(false);
         }, 200);
          setResponse(true);
        }
        setIsLoading(false);
      },
      (error) => {
        console.log(error)
        setResponse(false);
        setIsLoading(false);
        setOpen(false);
        setErrorCode(error.response?.data?.errorCode);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if (error?.response?.data?.errorCode === "331103") {
          appContext.setError(t("job_no._already_exists"));
        }
        document.getElementById("successPopUpButton").click();
      }
    );
  }
  function submitJob(e) {
    e.preventDefault();
    setClickSubmit(true);
    setCatchError(null)
    setMaxSalaryError(null)
    setMinSalaryError(null)
    if(inputs.title === "" || !inputs.title ||inputs.jobNo === "" || !inputs.jobNo || inputs.companyId === "" || !inputs.companyId){
      document.querySelector(".jobTitle").scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    // if(inputs.employmentType === "" || !inputs.employmentType || inputs.workingHours === "" || !inputs.workingHours){
    //   document.querySelector(".empTitle").scrollIntoView({ behavior: 'smooth', block: 'center' });
    // }
    // if(inputs.holidays === "" || !inputs.holidays || inputs.benefits === "" || !inputs.benefits){
    //   document.querySelector(".holidayTitle").scrollIntoView({ behavior: 'smooth', block: 'center' });
    // }
    // if(inputs.tagIds?.length === 0 || !inputs.tagIds || inputs.Conditions === "" || !inputs.Conditions
    //    || inputs.jobCategoryId?.length === 0|| !inputs.jobCategoryId || inputs.skillIds?.length === 0 || !inputs.skillIds
    //    || inputs.locationId?.length === 0 || !inputs.locationId ||inputs.SalaryId === "" || !inputs.SalaryId ){
    //   document.querySelector(".skillTitle").scrollIntoView({ behavior: 'smooth', block: 'center' });
    // }
    // if (inputs.title === ""|| !inputs.title || inputs.description === "" || !inputs.description || inputs.comment === "" || !inputs.comment ||
    //   inputs.benefits === "" || inputs.Conditions === "" || !inputs.locationId || !inputs.Conditions || !inputs.benefits || !inputs.holidays ||
    //   inputs.locationId?.length === 0 || inputs.companyId === "" || inputs.employmentType === "" || inputs.holidays === "" || !inputs.Conditions ||
    //   inputs.jobCategoryId?.length === 0|| !inputs.jobCategoryId || inputs.skillIds?.length === 0 || inputs.tagIds?.length === 0 || 
    //   inputs.workingHours === "" || !inputs.skillIds || !inputs.tagIds ||inputs.SalaryId === "" || !inputs.SalaryId || 
    //   !inputs.employmentType || !inputs.companyId || !inputs.workingHours || inputs.workingHours === "" ) {
    //   return;
    // }
    // console.log(inputs);
    //  if(inputs.jobCategoryId?.length === 0|| !inputs.jobCategoryId || inputs.skillIds?.length === 0 || !inputs.skillIds){
    //   document.querySelector(".skillTitle").scrollIntoView({ behavior: 'smooth', block: 'center' });
    // }
     if(inputs.minSalaryId !== "" && inputs.minSalaryId && (inputs.maxSalaryId === "" || !inputs.maxSalaryId) ){
      setMaxSalaryError(t('salary.salary_max_required'))

    //  document.querySelector(".skillTitle").scrollIntoView({ behavior: 'smooth', block: 'center' });
     }
     if(inputs.maxSalaryId !== "" && inputs.maxSalaryId && (inputs.minSalaryId === "" || !inputs.minSalaryId) ){
       setMinSalaryError(t('salary.salary_min_required'))

      // document.querySelector(".skillTitle").scrollIntoView({ behavior: 'smooth', block: 'center' });
     }
    if(inputs.minSalaryId !== "" && inputs.minSalaryId && inputs.maxSalaryId !== "" && inputs.maxSalaryId ){
       let tempMin = minSalaryList.find(element => element.salaryId === inputs.minSalaryId)
       let tempMax = maxSalaryList.find(element => element.salaryId === inputs.maxSalaryId)
       if(tempMin && tempMax && tempMax.salary !== 1 && tempMax.salary !== 0){

         if(tempMax.salary == tempMin.salary){
          setMinSalaryError(t('salary.same_salary'))
          // document.querySelector(".skillTitle").scrollIntoView({ behavior: 'smooth', block: 'center' });
          return;
         }
         if(tempMin.salary > tempMax.salary){
          setMinSalaryError(t('salary_error'))
          // document.querySelector(".skillTitle").scrollIntoView({ behavior: 'smooth', block: 'center' });
          return;
         }
       }

   
    }
    // console.log(inputs)
    console.log(minSalaryError)
    if (inputs.title === ""|| !inputs.title || inputs.description === "" || !inputs.description || inputs.companyId === ""  ||
       inputs.jobCategoryId?.length === 0|| !inputs.jobCategoryId || 
      //  inputs.skillIds?.length === 0 || !inputs.skillIds ||
       !inputs.companyId || (inputs.maxSalaryId !== "" && inputs.maxSalaryId && (inputs.minSalaryId === "" || !inputs.minSalaryId))
       || (inputs.minSalaryId !== "" && inputs.minSalaryId && (inputs.maxSalaryId === "" || !inputs.maxSalaryId)) ) {
      return;
    } else if(isRevealPwd){
      if(catchphrase !== undefined && catchphrase !== null && catchphrase !== ""){
      document.getElementById("confirmationPopUpButton").click();
      }else {
        if (jobId) {
          editJob();
        } else {
          createJob();
        }
      }
    }
     else {
      if (jobId) {
        editJob();
      } else {
        createJob();
      }
    }

  }
  const handleChange = (event) => {
    const name = event.target?.name;
    const value = event.target?.value;
    if(name === "description"){
      setTextCount(value.length);
    }
    if ((name === "companyId" || name === "employmentType" || name === "minSalaryId" || name === "maxSalaryId") && value !== "") {
      setInputs(values => ({ ...values, [name]: parseInt(value) }));
    } else {
      setInputs(values => ({ ...values, [name]: value.trimStart() }));
    }
    if(name === "companyId"){
      getCompanyById(value)
    }
    console.log(inputs)
  }

  const handleOnChangeCategory = (event,subCategoryD) => {
    console.log(event.target.id)
    if (event.target.checked) {
      setTempCategory(selectedCategory => [parseInt(event.target.value), ...selectedCategory]);
      setTempCategoryName(selectedCategoryName => [subCategoryD.subCategoryTitle, ...selectedCategoryName]);
      console.log(selectedCategory);
    } else if (!event.target.checked) {
      setTempCategory(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      setTempCategoryName(current =>
        current.filter(item => {
          return item !== (subCategoryD.subCategoryTitle);
        }),);
      console.log(selectedCategory)
    }
  };
  function submitCategory() {
    setSelectedCategory(tempCategory);
    setSelectedCategoryName(tempCategoryName)
    setInputs(values => ({ ...values, "jobCategoryId": String(tempCategory) }))
    console.log(inputs)
  }
  function clearCategory() {
    setTempCategory([]);
    setTempCategoryName([]);
  }
  function closeCategoryModal() {
    setTempCategory(selectedCategory);
    setTempCategoryName(selectedCategoryName)
  }

  function checkCategoryDetails(job){
    let ary1=[];
    let ary2=[];
    for (let i = 0; i < job?.jobSubCategorys?.length; i++){
      ary1[i] = job.jobSubCategorys[i]?.jobSubCategoryId;
      ary2[i] = job.jobSubCategorys[i]?.name;
     }
    Axios.get("subCategory").then((response) => {
      const responseAry1 = response.data.subCategoryList.map((x) => parseInt(x.subCategoryId)); 
      const responseAry2 = response.data.subCategoryList.map((x) => x.subCategoryName); 
      const mapAry1 = ary1; 
      const mapAry2 = ary2; 
       const results1 = mapAry1.filter(x => !responseAry1.includes(x));
       const results2 = mapAry2.filter(x => !responseAry2.includes(x));
       console.log(results2 ," ", responseAry2, " ",mapAry2);
      const toRemove1 = new Set(results1);
      const toRemove2 = new Set(results2);
      const difference1 = ary1.filter( x => !toRemove1.has(x) );
      const difference2 = ary2.filter( x => !toRemove2.has(x) );
  
       console.log(difference1,difference2);

       setInputs(values => ({ ...values, "jobCategoryId": String(difference1) }));
       setTempCategory(difference1);
       setSelectedCategory(difference1);
       setSelectedCategoryName(difference2);
       setTempCategoryName(difference2);
         }).catch(error => {
    });
  }
  const handleOnChangeSkill = (event,skillD) => {
    console.log(event.target.id)
    if (event.target.checked) {
      setTempSkill(selectedSkill => [parseInt(event.target.value), ...selectedSkill]);
      setTempSkillName(selectedSkillName => [skillD.skillName, ...selectedSkillName]);
      console.log(selectedSkillName);
    } else if (!event.target.checked) {
      setTempSkill(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      setTempSkillName(current =>
        current.filter(item => {
          return item !== (skillD.skillName);
        }),);
      console.log(selectedSkill)
    }
  }
  function submitSkill() {
    setSelectedSkill(tempSkill);
    setSelectedSkillName(tempSkillName)
    setInputs(values => ({ ...values, "skillIds": String(tempSkill) }))
    console.log(inputs)
  }
  function clearSkill() {
    setTempSkill([]);
    setTempSkillName([]);
  }
  function closeSkillModal() {
    setTempSkill(selectedSkill);
    setTempSkillName(selectedSkillName)
  }


  function checkSkillDetails(job){
    let ary1=[];
    let ary2=[];
    for (let i = 0; i < job?.skills?.length; i++){
      ary1[i] = job.skills[i]?.skillId;
      ary2[i] = job.skills[i]?.skillTitle;
     }
     Axios.get("skill").then((response) => {
      const responseAry1 = response.data.skillList.map((x) => parseInt(x.skillId)); 
      const responseAry2 = response.data.skillList.map((x) => x.skillName); 
      const mapAry1 = ary1; 
      const mapAry2 = ary2; 
       const results1 = mapAry1.filter(x => !responseAry1.includes(x));
       const results2 = mapAry2.filter(x => !responseAry2.includes(x));
       console.log(results1 ," ", responseAry1, " ",mapAry1);
      const toRemove1 = new Set(results1);
      const toRemove2 = new Set(results2);
      const difference1 = ary1.filter( x => !toRemove1.has(x) );
      const difference2 = ary2.filter( x => !toRemove2.has(x) );

     console.log(difference1,difference2);
     setInputs(values => ({ ...values, "skillIds": String(difference1) }));
     setTempSkill(difference1);
     setSelectedSkill(difference1);
     setSelectedSkillName(difference2);
     setTempSkillName(difference2);
         }).catch(error => {
    });
  }

  const handleOnChangeTag = (event,tagD) => {
    console.log(event.target.id)
    if (event.target.checked) {
      setTempTag(selectedTag => [parseInt(event.target.value), ...selectedTag]);
      setTempTagName(selectedTagName => [tagD.tagName, ...selectedTagName]);
      console.log(selectedTagName);
    } else if (!event.target.checked) {
      setTempTag(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      setTempTagName(current =>
        current.filter(item => {
          return item !== (tagD.tagName);
        }),);
      console.log(selectedTag)
    }
  }
  function submitTag() {
    setSelectedTag(tempTag);
    setSelectedTagName(tempTagName)
    setInputs(values => ({ ...values, "tagIds": String(tempTag) }))
    console.log(inputs)
  }
  function clearTag() {
    setTempTag([]);
    setTempTagName([]);
  }
  function closeTagModal() {
    setTempTag(selectedTag);
    setTempTagName(selectedTagName)
  }
  function checkTagDetails(job){
    let ary1=[];
    let ary2=[];
    for (let i = 0; i < job?.tags?.length; i++){
      ary1[i] = job.tags[i]?.tag_id;
      ary2[i] = job.tags[i]?.tagName;
     }
     Axios.get("tag").then((response) => {
      const responseAry1 = response.data.tagList.map((x) => parseInt(x.tagId)); 
      const responseAry2 = response.data.tagList.map((x) => x.tagName); 
      const mapAry1 = ary1; 
      const mapAry2 = ary2; 
       const results1 = mapAry1.filter(x => !responseAry1.includes(x));
       const results2 = mapAry2.filter(x => !responseAry2.includes(x));
       console.log(results1 ," ", responseAry1, " ",mapAry1);
      const toRemove1 = new Set(results1);
      const toRemove2 = new Set(results2);
      const difference1 = ary1.filter( x => !toRemove1.has(x) );
      const difference2 = ary2.filter( x => !toRemove2.has(x) );
  
    console.log(difference1,difference2);
     setInputs(values => ({ ...values, "tagIds": String(difference1) }));
     setTempTag(difference1);
     setSelectedTag(difference1);
     setSelectedTagName(difference2);
     setTempTagName(difference2);
         }).catch(error => {
    });
    
  }
// const handleOnChangeArea = (event,areaD) => {
  //   console.log(event.target.id)
  //   let areaName = [areaD.prefectureTitle]
  //   let areaId = [parseInt(event.target.value)]
  //   if (event.target.checked) {
  //     setTempArea(areaId);
  //     setTempAreaName(areaName);
  //     console.log(selectedAreaName);
  //   }
  // }
  const handleOnChangeArea = (event, areaD) => {
    const selectedId = parseInt(event.target.value);
  
    setTempArea((prevTempArea) => {
      if (event.target.checked) {
        // If the checkbox is checked, add the selectedId to the array
        return [...prevTempArea, selectedId];
      } else {
        // If the checkbox is unchecked, remove the selectedId from the array
        return prevTempArea.filter((id) => id !== selectedId);
      }
    });
  
    setTempAreaName((prevTempAreaName) => {
      if (event.target.checked) {
        // If the checkbox is checked, add the areaD.prefectureTitle to the array
        return [...prevTempAreaName, areaD.prefectureTitle];
      } else {
        // If the checkbox is unchecked, remove the areaD.prefectureTitle from the array
        return prevTempAreaName.filter((name) => name !== areaD.prefectureTitle);
      }
    });
  };
  function submitArea() {
    setSelectedArea(tempArea);
    setSelectedAreaName(tempAreaName)
    setInputs(values => ({ ...values, "locationId": String(tempArea) }))
    console.log(inputs)
  }
  function closeAreaModal() {
    setTempArea(selectedArea);
    setTempAreaName(selectedAreaName)
  
  }
 
  function checkAreaDetails(job){
    // let ary1=job?.prefectureId;
    // let ary2=job?.prefectureName;
    // Axios.get("prefecture").then((response) => {
    //   const responseAry1 = response.data.prefectureList.map((x) => x.prefectureId); 
    //   const responseAry2 = response.data.prefectureList.map((x) => x.prefectureName); 
    //   const mapAry1 = [ary1]; 
    //   const mapAry2 = [ary2]; 
    //    const results1 = mapAry1.filter(x => !responseAry1.includes(x));
    //    const results2 = mapAry2.filter(x => !responseAry2.includes(x));
    //   //  console.log(results1 ," ", responseAry1, " ",mapAry1);
    //   const toRemove1 = new Set(results1);
    //   const toRemove2 = new Set(results2);
    //   const difference1 = [ary1].filter( x => !toRemove1.has(x) );
    //   const difference2 = [ary2].filter( x => !toRemove2.has(x) );

    //   // console.log(difference1,difference2);
    //      setInputs(values => ({ ...values, "locationId": difference1 }));
    //      setTempArea(difference1);
    //      setSelectedArea(difference1);
    //      setSelectedAreaName(difference2);
    //      setTempAreaName(difference2);
    //      }).catch(error => {
    // });
    let ary1=[];
    let ary2=[];
    for (let i = 0; i < job?.prefectures?.length; i++){
      ary1[i] = job.prefectures[i]?.prefectureId;
      ary2[i] = job.prefectures[i]?.prefectureName;
     }
     Axios.get("prefecture").then((response) => {
      const responseAry1 = response.data.prefectureList.map((x) => parseInt(x.prefectureId)); 
      const responseAry2 = response.data.prefectureList.map((x) => x.prefectureName); 
      const mapAry1 = ary1; 
      const mapAry2 = ary2; 
       const results1 = mapAry1.filter(x => !responseAry1.includes(x));
       const results2 = mapAry2.filter(x => !responseAry2.includes(x));
      //  console.log(results1 ," ", responseAry1, " ",mapAry1);
      const toRemove1 = new Set(results1);
      const toRemove2 = new Set(results2);
      const difference1 = ary1.filter( x => !toRemove1.has(x) );
      const difference2 = ary2.filter( x => !toRemove2.has(x) );

     console.log("difference1,difference2",difference1,difference2);
      setInputs(values => ({ ...values, "locationId": String(difference1) }));
      setTempArea(difference1);
      setSelectedArea(difference1);
      setSelectedAreaName(difference2);
      setTempAreaName(difference2);
         }).catch(error => {
    });
  }
  function checkEmploymentDetails(job){
    let ary1=job?.employeeTypeId;
    Axios.get("employmentType").then((response) => {
      const responseAry1 = response.data.employmentTypeList.map((x) => x.employmentTypeId); 
      const mapAry1 = [ary1]; 
      const results1 = mapAry1.filter(x => !responseAry1.includes(x));
      console.log(results1 ," ", responseAry1, " ",mapAry1);
      const toRemove1 = new Set(results1);
      const difference1 = [ary1].filter( x => !toRemove1.has(x) );

      console.log(difference1);
      if(difference1.length > 0){
        setInputs(values => ({ ...values, "employmentType": String(difference1) }));
      }else{
        setInputs(values => ({ ...values, "employmentType": null }));
      }
         }).catch(error => {
    });
  }
  function checkSalaryDetails(job){
    // let ary1=job?.salaryId;
    // Axios.get("salaryRange").then((response) => {
    //   const responseAry1 = response.data.salaryRangeList.map((x) => x.salaryRangesId); 
    //   const mapAry1 = [ary1]; 
    //    const results1 = mapAry1.filter(x => !responseAry1.includes(x));
    //    console.log(results1 ," ", responseAry1, " ",mapAry1);
    //   const toRemove1 = new Set(results1);
    //   const difference1 = [ary1].filter( x => !toRemove1.has(x) );

    //   console.log(difference1);
    //   if(difference1.length > 0){
    //     setInputs(values => ({ ...values, "minSalaryId": String(difference1) }));
    //   }else{
    //     setInputs(values => ({ ...values, "minSalaryId": null }));
    //   }
    //      }).catch(error => {
    // });
    setInputs(values => ({ ...values, "minSalaryId": job?.minSalaryId }));
    setInputs(values => ({ ...values, "minSalaryId": job?.maxSalaryId }));
  }
  function numFormatter(num) {
    if(num !== undefined){
    if(num > 999999){
      return (num / 10000).toLocaleString("en-US") + '万';
    }else if(num < 1000000 && num > 999){
      return (num/1000).toLocaleString("en-US") + '千';
    }else if(num < 1000 && num > 99){
      return (num/100).toLocaleString("en-US") + '百';
    }else if(num < 100){
      return (num).toLocaleString("en-US");
    }
  }
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      submitJob(event);
    }
  };
  function setData(response){
    let data = {};
    data.minSalaryId = response.minSalaryId;
    data.maxSalaryId = response.maxSalaryId;
    data.comment = response.comments;
    data.companyId = response.companyId;
    data.benefits = response.benefits;
    data.Conditions = response.conditions;
    data.description = response.description;
    data.employmentType = response.employeeTypeId;
    data.holidays = response.holiday;
    data.jobCategoryId = response.jobSubCategorys;
    data.title = response.jobTitle;
    data.jobNo = response.jobNo;
    data.locationId = response.prefectures;
    data.skillIds = response.skills;
    data.tagIds = response.tags;
    data.workingHours = response.workingHours;
    setJobDetailList(data);
  }
  function clearAreaModal(){
    setTempArea([])
    setTempAreaName([])
  }
  const handleChangeCatch = (event) => {
   
      setCatchphrase(event.target?.value.trimStart());
      setIsRevealPwd(true)    
  }
  const handleKeyDownCatch = (event) => {
    if (event.key === 'Enter' && isRevealPwd) {
      submitCatch(event);
    } else if (event.key === 'Enter' && !isRevealPwd) {
      return;
    }
  }
  function submitCatch(e) {
    if(!erroroccupy) {
    e.preventDefault()
    }
    setCatchError(null)
    appContext.setError(null);
    appContext.setSuccess(null)
    if(inputs.companyId !== "" && inputs.companyId !== null && inputs.companyId !== undefined){
      if(catchphrase !== undefined && catchphrase !== null && catchphrase !== ""){

     
      setOpen(true);
      let data ={"catchPhrase": catchphrase}
      Axios.put('company/'+ inputs.companyId,data).then(
        (response) => {
          console.log(response);
          if (response.status === 200) {
            appContext.setSuccess(t('catch_Cahnged'))
            setErroroccupy(false)
            setIsRevealPwd(false)    
            if(erroroccupy){
              if (jobId) {
                editJob();
              } else {
                createJob();
              }
            
            }
            setTimeout(() => {
          
              setOpen(false);
           }, 200);
          
          }
          setIsLoading(false);
        },
        (error) => {
          setErroroccupy(false)
          console.log(error)
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          if (error?.response?.data?.errorCode === "310645") {
          
            setCatchError(t("catch_canbe_null"))
          } else if (error?.response?.data?.errorCode === "310503") {
             
            appContext.setError(t("company_page.smiley_not_allowed"));
          } else {
            appContext.setError(t("error"));
          }
          setIsLoading(false);
          setOpen(false);
        //  setErrorCode(error.response?.data?.errorCode);
        }
      );
      }else {
        setCatchError(t("catch_canbe_null"))
      }
    }else {
      appContext.setError(t("select_Company_for_catch"))
      //  error msg
    }
  }
  function editCatch(){
    setIsRevealPwd(true)    
  }
  function  getCompanyById(id){
    setCatchError(null)
    appContext.setError(null);
    appContext.setSuccess(null)
    setOpen(true);
    Axios
    .get('company/' + id)
    .then((response) => {
      setOpen(false);
      console.log(response?.data?.catchPhrase);
      setIsRevealPwd(false)    
      setCatchphrase(response?.data?.catchPhrase)
    })
    .catch((error) => {
      setOpen(false);
      console.log("There was an error!", error);
    });
  }
  function cancelCatch(e){
    e.preventDefault();
    appContext.setError(null);
    appContext.setSuccess(null)
    if (jobId) {
      editJob();
    } else {
      createJob();
    }
  }
  function saveCatchJob(e){
    e.preventDefault();
    appContext.setError(null);
    appContext.setSuccess(null)
    setErroroccupy(true)
  
  }
  useEffect( () => {
    if(erroroccupy) {
      submitCatch()
    }
 
  } , [erroroccupy])
  function getMInMAxsalaray(){
    Axios.get("salary").then((response) => {
      setMinSalaryList(response?.data?.minSalary);
      setMaxSalaryList(response?.data?.maxSalary)
      console.log(tag);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  return (
    <div className={styles.CreateJob} data-testid="CreateJob">
       <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
       <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
      <div>
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-5">
          {jobId && <h5>{t('edit_job')}</h5>} {!jobId && <h5>{t('create_job')}</h5>}
        </div>
        <div class="card create-card p-4">
          <div class="card-body">
            <form>
              <div class="row mb-3">
              <div class="col-md-2">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('job_No')}</label><span class="required">*</span>
                    <input type="text" class="form-control custom-input2 jobTitle" id="myInputID" name="jobNo" aria-describedby="emailHelp" placeholder={t('job_No')}
                      maxLength={100} onChange={handleChange} value = {inputs.jobNo} onKeyDown={e => { handleKeyDown(e) }} />
                    {(clickSubmit && !inputs.jobNo) && <div className="error">{t('job_title_required')}</div> }
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('job_title')}</label><span class="required">*</span>
                    <input type="text" class="form-control custom-input2 jobTitle" id="myInputID" name="title" aria-describedby="emailHelp" placeholder={t('job_title')}
                      maxLength={100} onChange={handleChange} value = {inputs.title} onKeyDown={e => { handleKeyDown(e) }} />
                    {(clickSubmit && !inputs.title) && <div className="error">{t('job_title_required')}</div> }
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('company')} </label><span class="required">*</span>
                    <select class="form-select custom-input2" aria-label="Default select example" name="companyId"
                      maxLength={50} onChange={handleChange} value={inputs.companyId}>
                      <option disabled selected>{t('select_company')}</option>
                      {company?.map((comp) => {
                        return (
                          <option value={comp?.companyId}>
                            {comp?.companyName.length < 40 ? comp?.companyName : comp?.companyName.substring(0,35)+"..."}</option>
                          )
                      })}
                    </select>
                    {(clickSubmit && !inputs.companyId) &&  <div className="error">{t('company_required')}</div> }
                  </div>

                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-12">
                  <div className="row">
                    <div className="col-md-10">
                    <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('catchphrase')}</label>
                    <div className="dob-inp">
                    <input type="text" class="form-control custom-input2 catchpharse-input" disabled={!isRevealPwd} id="myInputID" name="catchphrase" aria-describedby="emailHelp" placeholder={t('catchphrase')}
                        maxLength="500" onChange={e=> handleChangeCatch(e)} value = {catchphrase} onKeyDown={e => { handleKeyDownCatch(e) }} />
                        {catchError ? (
                      <div className="error">{catchError}</div>
                    ) : (
                      ""
                    )}
                       <span
                        className="calender-icon"
                        onClick={(e) => editCatch(e)}
                      >
                        {" "}
                        <i
                          className={
                            !isRevealPwd
                              ? "fas fa-edit"
                              : ""
                          }
                        ></i>
                      </span>
                  </div>
                  </div>
                    </div>
                    <div className="col-md-2">
                    <button className={
                                     isRevealPwd ? "inpt-save-btn" : "inpt-save-btn block-btn"
                                             } onClick={e => submitCatch(e)} disabled={!isRevealPwd}>{t("save")}</button>
                    </div>
                  </div>
                            
              </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('employment_type')}</label>
                    <select class="form-select custom-input2 empTitle" aria-label="Default select example" name="employmentType" 
                    value={inputs.employmentType} onChange={handleChange}>
                      <option selected value="">{t('select_employment_type')}</option>
                      {employment?.map((emp) => {
                        return (
                          <option value={emp?.employmentTypeId}>{emp?.employmentTypeName.length < 40 ? emp?.employmentTypeName : emp?.employmentTypeName.substring(0,35)+"..."}</option>)
                      })}
                    </select>
                    {/* {(clickSubmit && !inputs.employmentType) && <div className="error">{t('employment_required')}</div> } */}
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('working_hours')}</label>
                    <input type="text" class="form-control custom-input2" id="myInputID" aria-describedby="emailHelp" name="workingHours"
                     placeholder={t('working_hours')} maxLength={30} onChange={handleChange} value={inputs.workingHours} onKeyDown={e => { handleKeyDown(e) }}/>
                    {/* {(clickSubmit && !inputs.workingHours) && <div className="error">{t('working_hours_required')}</div>} */}
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('holidays')}</label>
                    <input type="text" class="form-control custom-input2 holidayTitle" id="myInputID" aria-describedby="emailHelp" name="holidays"
                     placeholder={t('holidays')} maxLength={1000} value={inputs.holidays} onChange={handleChange} onKeyDown={e => { handleKeyDown(e) }}/>
                    {/* {(clickSubmit && !inputs.holidays && inputs.holidays !== 0) && <div className="error">{t('holidays_required')}</div>} */}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('benefits')}</label>
                    <input type="text" class="form-control custom-input2" id="myInputID" aria-describedby="emailHelp" name="benefits"
                    placeholder={t('benefits')}  maxLength={1000} onChange={handleChange} value={inputs.benefits} onKeyDown={e => { handleKeyDown(e) }} />
                    {/* {(clickSubmit && !inputs.benefits) && <div className="error">{t('benefits_required')}</div> } */}
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('tags')}</label>
                    <input type="text" id="inputSkills" class="form-control custom-input2" aria-describedby="emailHelp" readOnly name="tagIds" placeholder= {t('tagz.select_tag')}
                      style={{ "background": "#8b8b8b14", "overflow": "auto","cursor":"pointer" }} value={selectedTagName} data-bs-toggle="modal" 
                      data-bs-target="#tagsearch" onKeyDown={e => { handleKeyDown(e) }} />
                    {/* {(clickSubmit && (!inputs.tagIds || inputs.tagIds?.length === 0)) && <div className="error">{t('tag_required')}</div> } */}
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('condition')}</label>
                    {/* <input type="text" class="form-control custom-input2" id="myInputID" aria-describedby="emailHelp" name="Conditions"
                    placeholder={t('condition')}  maxLength={1000} onChange={handleChange} value={inputs.Conditions} onKeyDown={e => { handleKeyDown(e) }}/> */}
                     <textarea class="form-control custom-input2" id="myInputID" rows="3" name="Conditions"
                    placeholder={t('condition')}  maxLength={1000} onChange={handleChange} value={inputs.Conditions} ></textarea>
                    {/* {(clickSubmit && !inputs.Conditions) && <div className="error">{t('condition_required')}</div> } */}
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('job_categories')}</label><span class="required">*</span>
                    <input id="inputJobCategories" type="text" readOnly class="form-control custom-input2" aria-label="Default select example" name="jobCategoryId" placeholder= {t('select_category')}
                      style={{ "background": "#8b8b8b14", "overflow": "auto","cursor":"pointer" }} value={selectedCategoryName} data-bs-toggle="modal" 
                      data-bs-target="#occupationsearch" onKeyDown={e => { handleKeyDown(e) }}>

                    </input>
                    {(clickSubmit && (!inputs.jobCategoryId || inputs.jobCategoryId?.length === 0)) && <div className="error">{t('job_categories_required')}</div> }
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('skills')}</label>
                    {/* <span class="required">*</span> */}
                    <input type="text" id="inputSkills" class="form-control custom-input2 skillTitle" aria-describedby="emailHelp" readOnly name="skillIds" placeholder= {t('skillz.select_skill')}
                      style={{ "background": "#8b8b8b14", "overflow": "auto","cursor":"pointer" }} value={selectedSkillName} data-bs-toggle="modal"
                       data-bs-target="#skillssearch" onKeyDown={e => { handleKeyDown(e) }} />
                    {/* {(clickSubmit && (!inputs.skillIds || inputs.skillIds?.length === 0)) && <div className="error">{t('skills_required')}</div> } */}
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('location')}</label>
                    <input type="text" id="inputArea" class="form-control custom-input2" aria-describedby="emailHelp" name="locationId" readOnly placeholder= {t('company_page.selected_location')}
                      style={{ "background": "#8b8b8b14","cursor":"pointer" }} value={selectedAreaName} data-bs-toggle="modal" 
                      data-bs-target="#joblocation" onKeyDown={e => { handleKeyDown(e) }} />
                    {/* {(clickSubmit && (!inputs.locationId || inputs.locationId?.length === 0)) && <div className="error">{t('area_required')}</div> } */}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('salary_range')} </label>
                    <div className="row">
                    <div class="col-md-6">
                    <select class="form-select custom-input2 inpt-salary-gap" aria-label="Default select example" name="minSalaryId"
                      onChange={handleChange} value={inputs.minSalaryId}>
                      <option selected value="">{t('select_salary_range')}</option>
                      {minSalaryList?.map((salaryRange) => {
                        return (
                          <option value={salaryRange?.salaryId}>{salaryRange?.salaryAsString}</option>)
                      })}
                    </select>
                    <div className="error">{minSalaryError}</div> 
                      </div>
                      <div class="col-md-6">
                    <select class="form-select custom-input2" aria-label="Default select example" name="maxSalaryId"
                      onChange={handleChange} value={inputs.maxSalaryId}>
                      <option selected value="">{t('select_salary_range')}</option>
                      {maxSalaryList?.map((salaryRange) => {
                        return (
                          <option value={salaryRange?.salaryId}>{salaryRange?.salaryAsString}</option>)
                      })}
                    </select>
                    <div className="error">{maxSalaryError}</div> 
                    {/* {(clickSubmit && !inputs.maxSalaryId) && <div className="error">{t('salary_required')}</div> } */}
                      </div>
                    </div>
                   
                  </div>

                </div>

              </div>
              <div class="row mb-3">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('comment_from')}</label>
                    <textarea class="form-control custom-input2" id="myInputID" rows="3" name="comment" placeholder={t('comment_from')}
                      maxLength={500} onChange={handleChange} value={inputs.comment}></textarea>
                    {/* {(clickSubmit && !inputs.comment) && <div className="error">{t('comment_required')}</div> } */}
                  </div>


                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('job_description')}</label><span class="required">*</span>
                    <textarea class="form-control custom-textarea" id="myInputID" rows="6" name="description" placeholder={t('job_description')}
                      maxLength={2000} onChange={handleChange} value={inputs.description}></textarea>
                    {(clickSubmit && !inputs.description) && <div className="error">{t('description_required')}</div> }
                    <div className="textCount">{textCount}/2000</div>
                  </div>
                </div>
              </div>


              <div class="col-md-12 text-end my-5">
                {!isLoading  &&<div>
                {freeWord && <Link to={"/job/" + page +"?search="+freeWord}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link>}
                { !freeWord && <Link to={"/job/1"}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link> }
                  <button class="main-buttons sub-btn" onClick={(e) => submitJob(e)}>{jobId? t('update') : t('save')}</button>
                </div>}
                {isLoading === true && <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px" }}></Spinner>}
              </div>



            </form>
          </div>
        </div>
      </div>


      {/* MODAL CATEGORY */}

      <div class="modal fade" id="occupationsearch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  modal-lg">
          <div class="modal-content">
            <div class="modal-header border-none">
              <div class="col-md-12 text-center mt-60">
                <div class="main-heading hot-jobhead"><h4 style={{ "fontWeight": "bolder" }}>{t('job_category')}</h4>
                </div>
              </div>
              <button type="button" class="Close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={closeCategoryModal}><i class="fa-solid fa-xmark"></i></button>
            </div>

            <div className="modal-body p-0">
              <div className="search-area">
                {category.map((categoryDetails) => {
                  return (
                    <div>
                      <div className="row">
                      {categoryDetails.subCategoryList.length > 0 &&  <div className="popup-head mt-3 mb-3 d-flex">
                          <h6>{categoryDetails?.categoryTitle}</h6>
                        </div>}
                      </div>
                      <div style={{"textAlign":"left"}}>
                        {categoryDetails?.subCategoryList.map((subCategoryDetails) => {
                          return (
                            <div className="form-check form-check-inline mb-2">
                              <input className="form-check-input form-chck" type="checkbox" onChange={e => handleOnChangeCategory(e,subCategoryDetails)}
                                checked={tempCategory?.includes(subCategoryDetails?.subId)} id={subCategoryDetails?.subId} value={subCategoryDetails?.subId} />
                              <label className="form-check-label ps-2" for="inlineCheckbox1"
                               data-toggle="tooltip" data-placement="bottom" title={subCategoryDetails?.subCategoryTitle}>{subCategoryDetails?.subCategoryTitle}</label>
                            </div>)
                        })}
                      </div>
                    </div>)
                })}

              </div>
            </div>
            <div class="modal-footer border-none ">
              <div class="col-md-12 text-center">
                <button class="cancel-btn" onClick={clearCategory}>{t('clear')}</button>
                <button className="main-buttons sub-btn" data-bs-dismiss="modal" onClick={submitCategory} >{t('submit')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* MODAL SKILL */}

      <div class="modal fade" id="skillssearch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  modal-lg">
          <div class="modal-content">
            <div class="modal-header border-none">
              <div class="col-md-12 text-center mt-60">
                <div class="main-heading hot-jobhead"><h4 style={{ "fontWeight": "bolder" }}>{t('skills')}</h4>
                </div>
              </div>
              <button type="button" class="Close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={closeSkillModal}><i class="fa-solid fa-xmark"></i></button>
            </div>
            <div class="modal-body p-0">
              <div class="search-area">
                <div>
                  <div class="search-area2" style={{"textAlign":"left"}}>
                    <div>
                      <div>
                        {skill.map((skillDetails) => {
                          return (
                            <div class="form-check form-check-inline mb-2">
                             {/* <input class="form-check-input form-chck" type="checkbox" checked={tempSkill?.includes(skillDetails?.skillId)}
                                id={skillDetails?.skillName} value={skillDetails?.skillId} onChange={e => handleOnChangeSkill(e)} /> */}
                                   <input class="form-check-input form-chck" type="checkbox" checked={tempSkill?.includes(skillDetails.skillId)}
                                  id={skillDetails.skillId} value={skillDetails.skillId} onChange={e => handleOnChangeSkill(e,skillDetails)} />
                              <label class="form-check-label ps-2" data-toggle="tooltip" data-placement="bottom" title={skillDetails?.skillName}
                                for="inlineCheckbox1">{skillDetails?.skillName}</label>
                            </div>)
                        })}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer border-none ">
              <div class="col-md-12 text-center">
                <button class="cancel-btn" onClick={clearSkill}>{t('clear')}</button>
                <button class="main-buttons sub-btn" data-bs-dismiss="modal" onClick={submitSkill}>{t('submit')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* MODAL TAG */}

     <div class="modal fade" id="tagsearch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  modal-lg">
          <div class="modal-content">
            <div class="modal-header border-none">
              <div class="col-md-12 text-center mt-60">
                <div class="main-heading hot-jobhead"><h4 style={{ "fontWeight": "bolder" }}>{t('tags')}</h4>
                </div>
              </div>
              <button type="button" class="Close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={closeTagModal}><i class="fa-solid fa-xmark"></i></button>
            </div>
            <div class="modal-body p-0">
              <div class="search-area">
                <div>
                  <div class="search-area2" style={{"textAlign":"left"}}>
                    <div>
                      <div>
                        {tag.map((tagDetails) => {
                          return (
                            <div class="form-check form-check-inline mb-2">
                              <input class="form-check-input form-chck" type="checkbox" checked={tempTag?.includes(tagDetails?.tagId)}
                                id={tagDetails?.tagId} value={tagDetails?.tagId} onChange={e => handleOnChangeTag(e,tagDetails)} />
                              <label class="form-check-label ps-2"for="inlineCheckbox1"
                               data-toggle="tooltip" data-placement="bottom" title={tagDetails?.tagName}>{tagDetails?.tagName}</label>
                            </div>)
                        })}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer border-none ">
              <div class="col-md-12 text-center">
                <button class="cancel-btn" onClick={clearTag}>{t('clear')}</button>
                <button class="main-buttons sub-btn" data-bs-dismiss="modal" onClick={submitTag}>{t('submit')}</button>
              </div>
            </div>
          </div>
        </div>
      </div> 

      {/* AREA MODAL */}

      <div className="modal fade" id="joblocation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-none">
              <div className="col-md-12 text-center mt-60">
                <div className="main-heading hot-jobhead">
                  <h4 style={{ "fontWeight": "bolder" }}>{t("location")}</h4>
                </div>
              </div>
              <button type="button" className="Close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={closeAreaModal}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="search-area">
                {area?.map((row) => (
                  <div>
                    <div className="row">
                    {row?.prefectures?.length > 0 ?  <div className="popup-head mt-3 mb-3 d-flex">
                        <h6>{row?.areaTitle}</h6>
                      </div>:null}
                    </div>
                    <div style={{ "textAlign": "left" }}>
                      {row?.prefectures?.map((sub) => (
                        <>
                          <div className="form-check form-check-inline mb-2">
                            <input className="form-check-input form-chck" type="checkbox" name="radioLOc" id={sub?.prefectureId}
                              value={sub?.prefectureId} onChange={(e) => handleOnChangeArea(e,sub)} checked={tempArea?.includes(sub?.prefectureId)} />
                            <label className="form-check-label ps-2" for="inlineCheckbox1"
                            data-toggle="tooltip" data-placement="bottom" title={sub?.prefectureTitle}>{sub?.prefectureTitle}</label>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-footer border-none ">
              <div className="col-md-12 text-center">
                <button className="cancel-btn" onClick={clearAreaModal}> {t("clear")}</button>
                <button className="main-buttons sub-btn" data-bs-dismiss="modal" onClick={submitArea}> {t("add")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*SUCCESS POPUP */}
      <button class="edit-icon" data-bs-toggle="modal" id="successPopUpButton" data-bs-target="#successPopup" hidden></button>

      <div class="modal fade" id="successPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-confirm ">
          <div class="modal-content">
            <div class="modal-header justify-content-center">
              <div class="icon-box">
                {response === true && <i class="fa fa-check" aria-hidden="true"></i>}{response === false && <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>}
              </div>
              {response === true &&<Link to={"/job/" + page}> <button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn"
                data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button></Link>} 
                {response === false &&<button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn"
                data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>}
            </div>
            <div class="modal-body text-center">
              {response === true ? <h4>{t('success')}</h4> : <h4>{t('error')}</h4>}
              {(response === true && !jobId) && <p>{t('job_created')}</p>}
              {(response === true && jobId) && <p>{t('job_updated')}</p>}
              {(response === false && errorCode === "312601") && <p>{t('job_not_found')}</p>}
              {(response === false && errorCode === "312509") && <p>{t('invalid_employment_type')}</p>}
              {(response === false && errorCode === "312517") && <p>{t('skill_not_found')}</p>}
              {(response === false && errorCode === "312520") && <p>{t('tag_not_found')}</p>}
              {(response === false && errorCode === "312510") && <p>{t('invalid_holiday')}</p>}
              {(response === false && errorCode === "312516") && <p>{t('category_not_found')}</p>}
              {(response === false && errorCode === "312503") && <p>{t('invalid_company')}</p>}
              {(response === false && errorCode === "312504") && <p>{t('invalid_location')}</p>}
              {(response === false && errorCode === "312506") && <p>{t('invalid_tag')}</p>}
              {(response === false && errorCode === "312512") && <p>{t('invalid_comment')}</p>}
              {(response === false && errorCode === "312513") && <p>{t('invalid_condition')}</p>}
              {(response === false && errorCode === "312505") && <p>{t('invalid_benefit')}</p>}
              {(response === false && errorCode === "312502") && <p>{t('invalid_description')}</p>}
              {(response === false && errorCode === "312511") && <p>{t('invalid_salary_range')}</p>}
              {(response === false && errorCode === "312521") && <p>{t('invalid_working_hours')}</p>}
             {response === true &&  <Link to={"/job/" + page +"?search="+freeWord}> <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button> </Link>}
            {response === false && <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button>}
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
        {/*confirmation POPUP */}
        <a href="" class="edit-icon" data-bs-toggle="modal" id="confirmationPopUpButton" data-bs-target="#confirmationPopup" hidden></a>

        <div class="modal fade" id="confirmationPopup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content">

                  <div class="modal-body px-5 py-3">
                      <div class="row mb-2 mt-2 px-3">
                          <div class="col-md-12 text-center">
                              <i class="fa fa-exclamation-triangle warn-pop"></i>
                          </div>

                          <div class="text-center mt-4 mb-4">
                              <h5>{t('warning')}</h5>
                          </div>

                          <div class="text-center">
                              <p>{t('save_valid_catch')}</p>
                          </div>


                      </div>
                      <div class="col-md-12 text-center mb-2">
                          <button class="cancel-btn" data-bs-dismiss="modal" onClick={(e) =>cancelCatch(e)}>{t('cancel')}</button>
                          <button class="main-buttons sub-btn"  data-bs-dismiss="modal" onClick={saveCatchJob}>{t('save')}</button>
                      </div>
                  </div>
                  
              </div>
          </div>
      </div>
    </div>
  )
};


export default CreateJob;
