import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "userCounter",
  initialState: {
    page: 1
  },
  reducers: {
    setPageUserCount: (state, action) => {
      state.page = action.payload;
    }
  }
});

export const { reducer, actions } = slice;

export const selectCount = (state) => state.userCounter.value;
