import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import useAppContext from '../../AppContext';
import { useLocation, Link, useNavigate} from 'react-router-dom'
import { Spinner } from "react-bootstrap";
import styles from './create-salary-range.module.css';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateSalaryRange = () =>{
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const appContext = useAppContext();
  const Axios = appContext.getAxios();
  const search = useLocation().search;
  const [salaryError, setSalaryError] = useState(false);
  const [sameSalaryError, setSameSalaryError] = useState(false);
  const [salaryRangeId, setSalaryRangeId] = useState("");
  const [inputs, setInputs] = useState({});
  const [clickSubmit, setClickSubmit] = useState(false);
  const [response, setResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDecimalmin, setIsDecimalMin] =  useState(false);
  const [isDecimalmax, setIsDecimalMax] =  useState(false);
  const [open, setOpen] = useState(false);
  const [errorCode, setErrorCode] =  useState();
  const maxValue = 999999999;
  const minValue = 0;
  const page = location?.state?.page;
  const alphabets = [
    'a','b','c','d','e','f','g','h','i','j','k','l', 'm','n','o','p','q','r','s','t','u','v','w','x','y','z',
    'A','B','C','D','E', 'F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
    '+','-','*','/','!','@','#','$','%','^',"&",'(',')','-','_','"','{','}','`','~','/',',','<','>','?',';',':'
    ];
  useEffect(() => {
    window. scrollTo(0, 0);
    appContext.setError(null);
    appContext.setSuccess(null)
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    if (new URLSearchParams(search).get('id')) {
      getSalary(parseInt(new URLSearchParams(search).get('id')) )
    }
    enterKeyEvent();
    // eslint-disable-next-line 
  }, []);

  function submit(e){
    e.preventDefault();
    setClickSubmit(true);
    if (inputs.salaryFrom === "" || !inputs.salaryFrom || inputs.salaryTo === "" || !inputs.salaryTo || salaryError || inputs.salaryFrom === "0"
    || isDecimalmin || isDecimalmax || sameSalaryError) {
    return;
  } else {
    if(new URLSearchParams(search).get('id')){
      editSalary()
    }else{
      addSalary();
    }
  }
  }
  const handleOnChangeMaxSalary = (event) => {
    if(event.target.value.length > 9){
      return
    }
    salaryCheck(inputs.salaryFrom,event.target.value);
    if (!event.target.value) {
      setInputs(values => ({ ...values, [event.target.name]: "" }));
    } else {
      validateDecimalMax(event.target.value);
      if (event.target.value < minValue) {
        event.target.value = minValue;

      } else if (event.target.value > maxValue) {
        event.target.value = event.target.value.slice(0, 9);

      }
      setInputs(values => ({ ...values, [event.target.name]: event.target.value }));
      console.log(inputs)
    }
  }
  const handleOnChangeMinSalary = (event) => {
    if(event.target.value.length > 9){
      return
    }
    salaryCheck(event.target.value,inputs.salaryTo);
    if (!event.target.value) {
      setInputs(values => ({ ...values, [event.target.name]: "" }));
    } else {
      validateDecimalMin(event.target.value);
      if (event.target.value < minValue) {
        event.target.value = minValue;

      } else if (event.target.value > maxValue) {
        event.target.value = event.target.value.slice(0, 9);

      }
      setInputs(values => ({ ...values, [event.target.name]: event.target.value }));
      console.log(inputs)
    }
  };
  function salaryCheck(min,max){
    if(parseInt(min) > parseInt(max)){
      console.log(min," ",max)
      console.log("true")
      setSalaryError(true);
      setSameSalaryError(false);   
    }else if(parseInt(min) < parseInt(max)){
      console.log(min," ",max)
      console.log("false")
      setSalaryError(false);
      setSameSalaryError(false);        
    }else if(parseInt(min) === parseInt(max)){
      console.log(min," ",max)
      console.log("false")
      setSalaryError(false);
      setSameSalaryError(true);   
    }   
  }
  function validateDecimalMax(num) {
    let value = num;
    let regex = /^[-+]?[0-9]+\.[0-9]+$/;
    let isValidated = regex.test(value);
    if (isValidated) {
      setIsDecimalMax(true);
    } else {
      setIsDecimalMax(false);
    }
 }
 function validateDecimalMin(num) {
  let value = num;
  let regex = /^[-+]?[0-9]+\.[0-9]+$/;
  let isValidated = regex.test(value);
  if (isValidated) {
    setIsDecimalMin(true);
  } else {
    setIsDecimalMin(false);
  }
}
function getSalary(id) {
  setOpen(true);
  Axios.get("salaryRange/"+id).then((response) => {;
    if (response.status === 200) {
      setOpen(false);
      console.log(response)
      setResponse(true);
       setSalaryRangeId(response?.data?.salaryRangesId);
       setInputs(values => ({ ...values, "salaryFrom": response?.data?.salaryFrom }));
       setInputs(values => ({ ...values, "salaryTo": response?.data?.salaryTo }));
    }
  }).catch(error => {
    setOpen(false);
    console.log('There was an error!', error);
    setResponse(false);
    setErrorCode(error.response?.data?.errorCode);
    if(error.response?.data?.errorCode === "38201"){
      if(page !== undefined){
        navigate("/salary-range/"+page);
      }else{
        navigate("/salary-range/1");
      }
   }
  });
}
  function addSalary() {
    setOpen(true);
    setIsLoading(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    const body = {"salaryFrom":inputs.salaryFrom.trim(),
                  "salaryTo":inputs.salaryTo.trim()}
    Axios.post("salaryRange",body).then((response) => {;
      setIsLoading(false);
      if (response.status === 200) {
        setTimeout(() => {
          navigate("/salary-range/1");
          setOpen(false);
       }, 200);
        setResponse(true);
      }
    }).catch(error => {
      console.log('There was an error!', error);
      setIsLoading(false);
      setOpen(false);
      setResponse(false);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "38403"){
        appContext.setError(t("salary.salary_exist"))
      }else
      document.getElementById("successPopUpButton").click();
    });
  }
  function editSalary() {
    setOpen(true);
    setIsLoading(true);
    const body = {"salaryFrom":inputs.salaryFrom,
                  "salaryTo":inputs.salaryTo}
    Axios.put("salaryRange/"+salaryRangeId,body).then((response) => {;
      setIsLoading(false);
      if (response.status === 200) {
        setTimeout(() => {
          navigate("/salary-range/"+page);
          setOpen(false);
       }, 200);
        setResponse(true);
      }
    }).catch(error => {
      console.log('There was an error!', error);
      setIsLoading(false);
      setOpen(false);
      setResponse(false);
      setErrorCode(error.response?.data?.errorCode);
      document.getElementById("successPopUpButton").click();
    });
  }
  function enterKeyEvent(){
    let el = document.getElementById("myInputID");
    let el2 = document.getElementById("myInputID2");
    el.addEventListener("keypress", function(event) {
    if (event.key === "Enter") {
      document.getElementById("saveBtn").click();
      event.preventDefault();
    }
   });
   el2.addEventListener("keypress", function(event) {
    if (event.key === "Enter") {
      document.getElementById("saveBtn").click();
      event.preventDefault();
    }
   });
  }
  return(
  <div className={styles.CreateSalaryRange} data-testid="CreateSalaryRange">
     <div>
           <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-5">
           {new URLSearchParams(search).get('id') ? <h5>{t('salary.edit_salary')}</h5> : <h5>{t('salary.create_salary')}</h5>}
        </div>
        <div class="card create-card vh-100 p-4">
            <div class="card-body">
                <form>
                    <div class="row mb-3">
                      <div class="col-md-6">
                        <div class="mb-3">
                        <label for="name" class="form-label input-label">{t('lower_range')}</label><span class="required">*</span>
                          <input type="number" class="form-control custom-input" onChange={e => handleOnChangeMinSalary(e)} onKeyDown={(e) =>alphabets.includes(e.key) && e.preventDefault()} 
                            name="salaryFrom" maxLength="9" id="myInputID" aria-describedby="emailHelp" value={inputs.salaryFrom} placeholder="1 - 999999999" />
                        </div>
                        {(clickSubmit && !inputs.salaryFrom) ? <div className="error">{t('salary.salary_min_required')}</div> : null}
                        {(clickSubmit && salaryError && inputs.salaryFrom && inputs.salaryTo && !isDecimalmin ) ? <div className="error">{t('salary_error')}</div> : null}
                        {(clickSubmit && inputs.salaryFrom === "0" ) ? <div className="error">{t('salary.zero')}</div> : null}
                        {(clickSubmit && isDecimalmin) ? <div className="error">{t('salary.decimal')}</div> : null}
                        {(clickSubmit && sameSalaryError && inputs.salaryFrom && inputs.salaryTo && !isDecimalmin && inputs.salaryFrom != "0") ? <div className="error">{t('salary.same_salary')}</div> : null}
                      </div>

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label for="name" class="form-label input-label">{t('upper_range')}</label><span class="required">*</span>
                          <input type="number" class="form-control custom-input" onChange={e => handleOnChangeMaxSalary(e)} placeholder="1 - 999999999" onKeyDown={(e) =>alphabets.includes(e.key) && e.preventDefault()}
                           name="salaryTo" maxLength="9" id="myInputID2" aria-describedby="emailHelp" value={inputs.salaryTo} />
                        </div>
                        {(clickSubmit && !inputs.salaryTo) ? <div className="error">{t('salary.salary_max_required')}</div> : null}
                        {(clickSubmit && isDecimalmax) ? <div className="error">{t('salary.decimal')}</div> : null}
                      </div>
                    </div>             
                    <div class="col-md-12 text-end my-5">
                     {!isLoading ? <div>
                { page? <Link to={"/salary-range/"+page}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link> :
                  <Link to={"/salary-range/1"}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link> }
                  <button class="main-buttons sub-btn" id="saveBtn" onClick={(e) => submit(e)}>{salaryRangeId?t('update') : t('save')}</button>
                </div> : null}
                {isLoading === true ? <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px" }}></Spinner> : null
                }
                     </div>
          
                  </form>
            </div>
          </div>

        </div>


      {/*SUCCESS POPUP */}
      <a href="" class="edit-icon" data-bs-toggle="modal" id="successPopUpButton" data-bs-target="#successPopup" hidden></a>

<div class="modal fade" id="successPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-confirm ">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <div class="icon-box">
          {response === true ? <i class="fa fa-check" aria-hidden="true"></i> : <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>}
        </div>
       {response === false && errorCode === "38201" ? <Link to="/salary-range/1"> <button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn"
          data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button></Link>:
          <button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn"
          data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>}
      </div>
      <div class="modal-body text-center">
        {response === true ? <h4>{t('success')}</h4> : <h4>{t('error')}</h4>}
        {(response === true && !salaryRangeId) ? <p>{t('salary.salary_created')}</p> : null}
        {(response === true && salaryRangeId) ? <p>{t('salary.salary_updated')}</p> : null}
        {response === false && errorCode === "38403" ? <p>{t('salary.salary_exist')}</p> : null}     
        {response === false && errorCode === "38201" ? <p>{t('salary.salary_not_found')}</p> : null}   
        {response === false && errorCode === "38404" ? <p>{t('salary_error')}</p> : null}     
        {response === false && errorCode === 400 ? <p>{t('salary.salary_error2')}</p> : null}                      
        {response === true?  <Link to={"/salary-range/"+page}> <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button></Link>
          : <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button>}
      </div>
    </div>
  </div>
</div>
<Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
  </div>
)};
export default CreateSalaryRange;
