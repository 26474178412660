import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

import './vendor/fontawesome/css/all.css';
import { refreshAccessToken, AppContextProvider } from "./AppContext";
import { Provider } from "react-redux";
import store , { persistor } from "./redux/store";
import "../node_modules/primereact/resources/primereact.min.css";                  //core css
import "../node_modules/primeicons/primeicons.css"; 
import "../node_modules/primereact/resources/themes/lara-light-indigo/theme.css"
import {PersistGate} from 'redux-persist/integration/react'
<script src="js/bootstrap.bundle.js"></script>
const root = ReactDOM.createRoot(document.getElementById('root'));
refreshAccessToken().then((value) =>
root.render(
<AppContextProvider init={value}>
<Provider store={store}>
<PersistGate loading={null} persistor={persistor}>
    <App />
    </PersistGate>
</Provider>
</AppContextProvider>,
)
);
