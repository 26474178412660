import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAppContext from "../../AppContext";
import i18n from "../../i18n";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
function PopularCompany(){
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [popularCompany, setPopularCompany] = useState([]);
  const [company, setCompany] = useState([]);
  const [deleteRow, setDeleteRow] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const [companyError, setCompanyError] = useState(null);
  const [selectOptions, setSelectOptions] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    getAllCompany();
    getPopularCompany();
   
  }, []);
  function getPopularCompany() {
   // setOpen(true);
   appContext.setError(null);
   appContext.setSuccess(null)
    axios
      .get("popularCompany")
      .then((response) => {
        console.log(response);
        setPopularCompany(response?.data);
        setIsLoaded(true);
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        setIsLoaded(true);
        console.log("There was an error!", error);
      });
  }
  function getAllCompany() {
    let options;
    //setOpen(true);
    axios
      .get("company/name")
      .then((response) => {
        console.log(response);
        setCompany(response?.data);

        options = response?.data?.map(item => {
          return {
            label: String(item.companyName),
            value: item.companyId
          }
        })
        setSelectOptions(options);
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        console.log("There was an error!", error);
      });
  }
  function onDelete(row) {
    console.log(row);
    setDeleteRow(row);
  }
  function deleteCategory() {
    setOpen(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    axios
      .delete("/popularCompany/" + deleteRow.popularCompanyId)
      .then((response) => {
        setOpen(false);
        getPopularCompany();
        appContext.setSuccess(t("popularCompany.company_deleted"))
      })
      .catch((error) => {
        setOpen(false);
        if(error?.response?.data?.errorCode === "314701") {
          appContext.setError(t("popularCompany.company_deletion_failed"))
        } else {
          appContext.setError(t("popularCompany.company_deletion_failed"))
        }
     
      });
  }
  function addCompany() {
    setCompanyId(null);
    setCompanyError(null);
    appContext.setError(null);
    appContext.setSuccess(null)
  }
  function handleChange(event) {
    console.log(event?.value);
   
    setCompanyId(event);
  }

  function saveCompany() {
    setCompanyError(null);
    console.log(companyId);
    appContext.setError(null);
    appContext.setSuccess(null)
    if (companyId === null) {
      setCompanyError(t("popularCompany.valid_Company"));
    } else {
      let data = { companyId: companyId?.value };
      setOpen(true);

      axios
        .post("popularCompany", data)
        .then((response) => {
          getPopularCompany();
          setCompanyId(null);
          console.log(response);
          document.getElementById("deletePopup").click();
          setOpen(false);
          appContext.setSuccess(t("popularCompany.comapny_sucess"))
        })
        .catch((error) => {
          setOpen(false);
          document.getElementById("deletePopup").click();
          if(error?.response?.data?.errorCode === "310201") {
            appContext.setError(t("popularCompany.comapny_Failed"))
          } else if(error?.response?.data?.errorCode === "310803") {
            appContext.setError(t("popularCompany.maxCount_exceed"))
          } else if(error?.response?.data?.errorCode === "314602") {
            appContext.setError(t("popularCompany.already_exist"))
          } else{
            appContext.setError(t("popularCompany.comapny_Failed"))
          }
        });
    }
  }
  return(
    <>
 
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-4">
                        <h5>{t("popularCompany.heading")}</h5>
                        <div class="col-md-3 text-end">
                            <button class="create-btn mt-3 mb-3" data-bs-toggle="modal"
                                data-bs-target="#exampleModal"   onClick={addCompany}><i class="fa-solid fa-plus"></i>{t("popularCompany.addComapny")}</button>
                        </div>


                    </div>
                    {isLoaded ?  
      <div>
       {popularCompany.length > 0 ? 
                    <div class="card table-card">
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table custom-table1">
                                    <thead class="table-header">
                                        <tr>
                                            <th scope="col" style={{"width":"5%"}} >{t("popularCompany.slno")}</th>
                                            <th scope="col" style={{"width":"25%"}}>{t("popularCompany.name")}</th>
                                            <th scope="col" style={{"width":"5%"}} >{t("popularCompany.action")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {popularCompany.length > 0 ? (
                  <>
                    {popularCompany?.map((row, index) => (
                                        <tr>
                                            <td>{index + 1 + limit * (currentPage - 1)}</td>
                                            <td>{row?.Company?.companyName}</td>
                                            <td>
                                                <div>
                                                    <div class="col-md-12  ">
                                                        <button class="delete-btn me-2" data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal2"   onClick={(e) => onDelete(row)}><i
                                                                class="fa-solid fa-trash-can"></i></button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
  ))}{" "}
  </>
) : (
  <tr>
    <td className="no-data" colspan="3">
      {t("popularCompany.no_data_found")}
    </td>
  </tr>
)}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
 : <div><h6 style={{ "textAlign": "center","fontWeight":"bold","lineHeight":"400px" }}>  {t("popularCompany.no_data_found")}</h6></div>}
 </div>: <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px","marginTop":"150px" }}></Spinner> }
                    <a href="" class="edit-icon" data-bs-toggle="modal" id="deletePopup" data-bs-target="#exampleModal" hidden></a>

                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header border-0">
                        <button type="button" class="btn-close close-pop" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body px-5 py-3 text-start">
                        <h5> {t("popularCompany.addComapny")}</h5>

                        <div class="row mb-2 mt-5 px-3 ">
                            <div class="mb-3">
                                <label for="name" class="form-label input-label">{t("popularCompany.select_company")}</label>
                              
                                <Select  aria-label="Default select example" placeholder={t("popularCompany.select_company")}  style={{"height":"60px","paddingLeft":"10px"}}
                    name="companyId" value={companyId}   onChange={handleChange}  options={selectOptions} 
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#CBCBCB',
                        boxShadow: 'none',
                        '&:hover': {
                            border: '1px solid #86b7fe',
                        },
                        height : "60px",
                        borderRadius:"6px",
                        }),
                        
                                            
                    }} 
                    noOptionsMessage={() => t('no_results')}  >
                  
                  </Select>
                  {companyError ? (
                    <div className="error">{companyError}</div>
                  ) : (
                    ""
                  )}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-0">
                        <div class="col-md-12 text-center mb-5">
                            <button class="cancel-btn" data-bs-dismiss="modal">{t("popularCompany.cancel")}</button>
                            <button class="main-buttons sub-btn"  onClick={saveCompany}>{t("add")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">

                    <div class="modal-body px-5 py-3">
                        <div class="row mb-2 mt-5 px-3">
                            <div class="col-md-12 text-center">
                                <i class="fa-regular fa-circle-xmark delete-pop"></i>
                            </div>

                            <div class="text-center mt-4 mb-4">
                                <h5>{t("popularCompany.deleteHeader")}</h5>
                            </div>

                            <div class="text-center">
                                <p>{t("popularCompany.delete_description")}</p>
                            </div>


                        </div>
                        <div class="col-md-12 text-center mb-3">
                            <button class="cancel-btn" data-bs-dismiss="modal">{t("popularCompany.cancel")}</button>
                            <button class="main-buttons sub-btn"  data-bs-dismiss="modal"
                  onClick={deleteCategory}>{t("popularCompany.delete")}</button>
                        </div>
                    </div>
                    {/* <div class="modal-footer border-0">
                        
                    </div> */}
                </div>
            </div>
        </div>


      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />

    </>
  )
}

export default PopularCompany;
