import React, { useEffect, useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import useAppContext from "../../AppContext";
import i18n from "../../i18n";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import imageAXIOS from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emojiRegex from "emoji-regex";
import { useDispatch } from "react-redux";
import { actions } from "../../redux/couterReducer";

function CreateCompany() {
  const validUrl = RegExp(/((https?:\/\/)|(\/)|(..\/))(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i);
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  const dispatch = useDispatch();
  const [industry, setIndustry] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [comapny, setCompany] = useState({
    companyName: "",
    description: "",
    industryId: "",
    capital: "",
    prefectureId: "",
    companyURL: "",
    companyLogo: "",
    businessContent: "",
    headquartersLocation: "",
    workingEnvironment: "",
    catchPhrase:""
  });
  const {
    companyName,
    description,
    industryId,
    capital,
    prefectureId,
    companyURL,
    companyLogo,
    workingEnvironment,
    headquartersLocation,
    businessContent,
    catchPhrase
  } = comapny;
  const [nameError, setNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [capitalError, setCapitalError] = useState(null);
  const [industryError, setIndustryError] = useState(null);
  const [prefectureError, setPrefectureError] = useState(null);
  const [urlError, setUrlError] = useState(null);
  const [logoError, setLogoError] = useState(null);
  const [workingError, setWorkingError] = useState(null);
  const [headquartersError, setHeadquartersError] = useState(null);
  const [contentError, setContentError] = useState(null);
  const [areaList, setAreaList] = useState([]);
  const [tempIndustry, setTempIndustry] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [dummyLocation, setDummyLocation] = useState();
  const [locationName, setLocationName] = useState();
  const [checkedLocation, setCheckedLocation] = useState("");
  const [industryName, setIndustryName] = useState(null);
  const [image, setImage] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [companyLogoShow, setCompanyLogoShow] = useState(null);
  const [prefectureList, setPrefectureList] = useState([]);
  const [companyDetailList, setCompanyDetailList] = useState([]);
  const [companyUpdateList, setCompanyUpdateList] = useState({});
  const [catchPhraseError, setCatchPhraseError] = useState(null);

  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedAreaName, setSelectedAreaName] = useState([]);
  const [tempArea, setTempArea] = useState([]);
  const [tempAreaName, setTempAreaName] = useState([]);

  const regex = emojiRegex();
  const companyNameRef = useRef();
  const descriptionRef = useRef();
  const industryIdRef = useRef();
  const capitalRef = useRef();
  const prefectureIdRef = useRef();
  const companyURLRef = useRef();
  const catchphraseRef = useRef();
  const companyLogoRef = useRef();
  const businessContentRef = useRef();
  const headquartersLocationRef = useRef();
  const workingEnvironmentRef = useRef();
  const location = useLocation();
  const page = location?.state?.page;
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    appContext.setError(null);
    appContext.setSuccess(null)
    getIndustry();
    getPrefecture();
    getLocation();
    if (params.id !== undefined) {
      setIsEdit(true);
      getCompanyById();
    }
  }, []);
  useMemo(() => getPageCountCompany(selectedIndustry), [selectedIndustry]);
  async function getIndustry() {
    await axios
      .get("industry")
      .then((response) => {
        console.log(response);
        setIndustry(response?.data?.industryList);
        console.log(industry);
      })
      .catch((error) => {
        console.log("There was an error!", error);
      });
  }
  async function getLocation() {
    await axios
      .get("highLevelLocation/prefecture")
      .then((response) => {
        console.log(response);
        setAreaList(response?.data?.areaList);
      })
      .catch((error) => {
        console.log("There was an error!", error);
      });
  }
  async function getPrefecture() {
    await axios
      .get("prefecture")
      .then((response) => {
        console.log(response);
        setPrefectureList(response?.data?.prefectureList);
      })
      .catch((error) => {
        console.log("There was an error!", error);
      });
  }
  const onInputChange = (e) => {
    e.preventDefault();
    setCompany({ ...comapny, [e.target.name]: e.target.value.trimStart()});
    if (e.target.name === "companyName") {
      setNameError(null);
      if (e.target.value === "") {
        setNameError(t("company_page.validate_company"));
      }
      if (e.target.value !== "" && regex.test(e.target.value)) {
        setNameError(t("company_page.validate_VAILD_company"));
      }
    }
    if (e.target.name === "description") {
      setDescriptionError(null);
      if (e.target.value === "") {
        setDescriptionError(t("company_page.validate_description"));
      }
    } else if (e.target.name === "capital") {
      setCapitalError(null);
      // if (e.target.value === "") {
      //   setCapitalError(t("company_page.validate_capital"));
      // }
      if (e.target.value !== "" &&  !(/^\d+$/.test(e.target.value))) {
        setCapitalError(t("company_page.validate_VAILD_capital"));
      }
    } 
    else if (e.target.name === "companyURL") {
      setUrlError(null);
      if (e.target.value !== "" && e.target.value !== null && !validUrl.test(e.target.value)) {
        setUrlError(t("company_page.validate_validurl"));
      }
    } 
    if (e.target.name === "catchPhrase") {
      setCatchPhraseError(null);
      if (e.target.value === "") {
        setCatchPhraseError(t("company_page.validate_catchphrase"));
      }
    }
    // else if (e.target.name === "workingEnvironment") {
    //   setWorkingError(null);
    //   if (e.target.value === "") {
    //     setWorkingError(t("company_page.validate_environment"));
    //   }
    // } else if (e.target.name === "headquartersLocation") {
    //   setHeadquartersError(null);
    //   if (e.target.value === "") {
    //     setHeadquartersError(t("company_page.validate_location"));
    //   }
    // } else if (e.target.name === "businessContent") {
    //   setContentError(null);
    //   if (e.target.value === "") {
    //     setContentError(t("company_page.validate_content"));
    //   }
    // }
  };
  function onSubmit(e) {
    e.preventDefault();
    appContext.setError(null);
    appContext.setSuccess(null)
    console.log(selectedIndustry);
    setCompanyUpdateList({})
    comapny.industryId = null;
    comapny.prefectureId = "";
  
    for (const iterator of industry) {
      if (iterator.industryId === selectedIndustry) {
        comapny.industryId = selectedIndustry;
      }
    }
    if (comapny.industryId === null) {
      setIndustryName("");
    }
    for (const iterator of prefectureList) {
      if (selectedArea.includes(iterator.prefectureId)) {
        comapny.prefectureId = String(selectedArea);
      }
    }
    if (comapny.prefectureId === "") {
      setLocationName('');
    }
    if (comapny.description === "") {
      descriptionRef.current.focus();
      setDescriptionError(t("company_page.validate_description"));
    }
    if (comapny.catchPhrase === "") {
  
      catchphraseRef.current.focus();
        setCatchPhraseError(t("company_page.validate_catchphrase"));

    }
    // if (comapny.workingEnvironment === "") {
    //   workingEnvironmentRef.current.focus();
    //   setWorkingError(t("company_page.validate_environment"));
    // }
    // if (comapny.businessContent === "") {
    //   businessContentRef.current.focus();
    //   setContentError(t("company_page.validate_content"));
    // }
    // if (comapny.headquartersLocation === "") {
    //   headquartersLocationRef.current.focus();
    //   setHeadquartersError(t("company_page.validate_location"));
    // }
    // if (comapny.prefectureId === "") {
    //   prefectureIdRef.current.focus();
    //  setPrefectureError(t("company_page.validate_prefecture"));
    // }
  
    if (comapny.companyURL !== "" && comapny.companyURL !== null && !validUrl.test(comapny.companyURL)) {
      companyURLRef.current.focus();
      setUrlError(t("company_page.validate_validurl"));
    }
    // if (comapny.companyURL === "") {
    //   companyURLRef.current.focus();
    //   setUrlError(t("company_page.validate_url"));
    // }
    console.log(comapny.capital)
    if (comapny.capital !== "" && comapny.capital !== null && !( /^\d+$/.test(comapny.capital))) {
      capitalRef.current.focus();
      setCapitalError(t("company_page.validate_VAILD_capital"));
    }
    // if (comapny.capital === "") {
    //   capitalRef.current.focus();
    //   setCapitalError(t("company_page.validate_capital"));
    // }
    // if (comapny.industryId === null) {
    //   industryIdRef.current.focus();
    //   setIndustryError(t("company_page.validate_industry"));
    // }
    // if (comapny.companyLogo === "") {
    //   companyLogoRef.current.focus();
    //   setLogoError(t("company_page.validate_logo"));
    // }
    if (comapny.companyName !== "" && regex.test(comapny.companyName)) {
      companyNameRef.current.focus();
      setNameError(t("company_page.validate_VAILD_company"));
    }
    if (comapny.companyName === "") {
    // document.querySelector("name").scrollIntoView({ behavior: 'smooth', block: 'center' });
    companyNameRef.current.focus();
      setNameError(t("company_page.validate_company"));

    }
    if(comapny.companyURL !== "" && comapny.companyURL !== null && !validUrl.test(comapny.companyURL)){
      return;
    }
    console.log(JSON.stringify(comapny));
    if(comapny.capital !== ""  && comapny.capital !== null &&  !(/^\d+$/.test(comapny.capital))){
      return;     
    }
    if (
      comapny.companyName !== "" &&
      comapny.description !== "" && comapny.catchPhrase !== "" &&
      !regex.test(comapny.companyName)
     
    )
   
    // if (
    //   comapny.companyName !== "" &&
    //   comapny.description !== "" &&
    //   comapny.companyURL !== "" &&
    //   comapny.capital !== "" &&
    //   comapny.workingEnvironment !== "" &&
    //   comapny.headquartersLocation !== "" &&
    //   !regex.test(comapny.companyName) &&
    //   validUrl.test(comapny.companyURL) &&
    //   comapny.businessContent !== "" &&
    //   comapny.companyLogo !== "" &&
    //   comapny.industryId !== null &&
    //   comapny.prefectureId !== ""
    // ) 
     {
   
      // console.log(newArr)
      setOpen(true);
      if (params.id !== undefined) {
        for (const key in companyDetailList) {
         
          for (const keyCom in comapny) {
        
            // console.log(key);
            // console.log(companyDetailList[key]);
            // console.log(keyCom);
            // console.log(comapny[keyCom]);
          if(keyCom !== 'industryId' && keyCom !== 'prefectureId' ){
            if(key === keyCom && companyDetailList[key] !== comapny[keyCom]){
              console.log(`${key}: ${comapny[key]}`);

              companyUpdateList[keyCom] = comapny[keyCom];
            }
          }else {
            if(key == 'industry' && companyDetailList[key]?.industryId !== comapny[keyCom]){
              companyUpdateList[keyCom] = comapny[keyCom];
            }
            if(key == 'prefecture' && companyDetailList[key]?.prefectureId !== comapny[keyCom]){
              companyUpdateList[keyCom] = comapny[keyCom];
            }
          
          }
         
           
          }
          
        
      }
    
      for (const key in companyUpdateList) {
        if( companyUpdateList[key] === "" || companyUpdateList[key] === ''){
          companyUpdateList[key] = null;
        }
       }
      console.log(companyUpdateList)
        axios
          .put("company/" + params.id, companyUpdateList)
          .then((response) => {
            console.log(response);
            setTimeout(() => {
             
              navigate("/company/"+page);
              setOpen(false);
           }, 200);
           
          })
          .catch((error) => {
            setOpen(false);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            if (error?.response?.data?.errorCode === "310609") {
            
              appContext.setError(t("company_page.invalid_industry_id_error"));
            } else if (error?.response?.data?.errorCode === "310616") {
              appContext.setError(t("company_page.invalid_prefeature_id_error"));
            }  else if (error?.response?.data?.errorCode === "2011") {
             
              appContext.setError(t("company_page.company_unique"));
            } else if (error?.response?.data?.errorCode === "310503") {
             
              appContext.setError(t("company_page.smiley_not_allowed"));
            } else if(error?.response?.data?.errorCode === "310643"){
              
              appContext.setError(t("company_page.only_characters_allowed"));
            }
            else {
              appContext.setError(t("company_page.company_updation_failed"));
            }
            console.log("There was an error!", error);
          });
      } else {
        let newArr =Object.keys(comapny)
        .filter(e => (comapny[e] !== "" && comapny[e] !== null))
        .reduce( (o, e) => {
             o[e]  = comapny[e]
             return o;
        }, {});
        axios
          .post("company", newArr)
          .then((response) => {
            console.log(response);
            dispatch(actions.setSearchCompanyfield(""));
            dispatch(actions.setPageCompanyCount(1));
            setOpen(false);
          
            setTimeout(() => {
             
              navigate("/company/1");
              setOpen(false);
           }, 200);
           
          })
          .catch((error) => {
            setOpen(false);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            if (error?.response?.data?.errorCode === "310609") {
              appContext.setError(t("company_page.invalid_industry_id_error"));
            } else if (error?.response?.data?.errorCode === "310616") {
              appContext.setError(t("company_page.invalid_prefeature_id_error"));
            }  else if (error?.response?.data?.errorCode === "2011") {
              appContext.setError(t("company_page.company_unique"));
            }  else if (error?.response?.data?.errorCode === "310503") {
             
              appContext.setError(t("company_page.smiley_not_allowed"));
            } 
            else {
              appContext.setError(t("company_page.company_creation_failed"));
            }
            console.log("There was an error!", error);
          });
      }
    }
  }
  function onCancel() {
    navigate("/company/" + page);
  }
  function onFileSelect(e) {
    appContext.setError(null);
    appContext.setSuccess(null)
    if (e.target.files.length > 0) {
      let image = e.target.files[0];
      if (image.size <= "10485760") {
        console.log(e.target.files[0]);
      
        const formData = new FormData();
        formData.append("uploadfile", image);
        setOpen(true);
        imageAXIOS
          .post(process.env.REACT_APP_BASE_URL + "file/imageUpload", formData, {
            headers: {
              Authorization:
                "JobSiteAdmin " + localStorage.getItem("accessToken"),
            },
          })
          .then((response) => {
           
             console.log(response);
     
           
            let filePath = response?.data?.filepath.split("/");
            console.log(filePath[filePath.length - 1])
            setCompany({
              ...comapny,
              ["companyLogo"]: filePath[filePath.length - 1],
            });
            setImage(filePath[filePath.length - 1]);
            setCompanyLogoShow(response?.data?.location)
            setLogoError(null);
            setOpen(false);
          })
          .catch((error) => {
            setOpen(false);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            if(error?.response?.data?.errorCode === "310502"){
             
              appContext.setError(t("company_page.invalid_file"));
            }else {
              appContext.setError(t("company_page.file_upload_failed"));
            }
           
          });
        e.target.value = "";
      } else {
        setLogoError(t("company_page.file_max_size"));
      }
    } else {
    }
  }
  function handleOnChangeIndustry(event) {
    if (event.target.checked) {
      console.log("checked");
      setTempIndustry(parseInt(event.target.value));
      console.log(tempIndustry);
    } else if (!event.target.checked) {
      console.log("unchecked");
      setTempIndustry("");
      // setTempIndustry((current) =>
      //   current.filter((item) => {
      //     return item !== parseInt(event.target.value);
      //   })
      // );
      console.log(tempIndustry);
    }
  }
  function clearIndustry() {
    setTempIndustry("");
    setSelectedIndustry(null);
    setIndustryName('');
  }
  function IndustrySubmit() {
    console.log(tempIndustry);
    if (tempIndustry !== "") {
      setSelectedIndustry(tempIndustry);
    } else {
      setIndustryName('');
      setSelectedIndustry(null);
    }
  }
  function handleCloseIndustryModal() {
    console.log(selectedIndustry);
    setTempIndustry(selectedIndustry);
  }
  // function onLocation(event, prefature) {
  //   if (event.target.checked) {
  //     setDummyLocation(prefature);
  //     setCheckedLocation(prefature.prefectureId);
  //     //console.log(dummyLocation)
  //     console.log(checkedLocation);
  //     setPrefectureError(null);
  //   } else {
  //   }
  // }
  const onLocation = (event, areaD) => {
    const selectedId = parseInt(event.target.value);
    setTempArea((prevTempArea) => {
      if (event.target.checked) {
        // If the checkbox is checked, add the selectedId to the array
        return [...prevTempArea, selectedId];
      } else {
        // If the checkbox is unchecked, remove the selectedId from the array
        return prevTempArea.filter((id) => id !== selectedId);
      }
    });

    setTempAreaName((prevTempAreaName) => {
      if (event.target.checked) {
        // If the checkbox is checked, add the areaD.prefectureTitle to the array
        return [...prevTempAreaName, areaD.prefectureTitle];
      } else {
        // If the checkbox is unchecked, remove the areaD.prefectureTitle from the array
        return prevTempAreaName.filter((name) => name !== areaD.prefectureTitle);
      }
    });
  };
  function clearLocation() {
    // setCheckedLocation("");
    // setDummyLocation(null);
    // comapny.prefectureId = "";
    // setLocationName('');
    setTempArea([]);
    setTempAreaName([]);
  }
  function cancelLocation() {
    // setCheckedLocation(comapny.prefectureId);
    // setDummyLocation("");
    setTempArea(selectedArea);
    setTempAreaName(selectedAreaName);
  }
  function submitLocation() {
    // if (dummyLocation !== "") {
    //   comapny.prefectureId = dummyLocation.prefectureId;
    //   setLocationName(dummyLocation.prefectureTitle);
    //   setPrefectureError("");
    // }
    setSelectedArea(tempArea);
    setSelectedAreaName(tempAreaName)
    // setInputs(values => ({ ...values, "locationId": String(tempArea) }))
  }
  function showIndustry() {
    let val = "";
    console.log(industry);

    for (const iterator of industry) {
      if (iterator.industryId === tempIndustry) {
        val = val + iterator.industryName;
      }
    }
    if (val !== "") {
      setIndustryName(val);
    }
  }

    //Method to mark checked prefectures
  function checkPrefectureDetails(company) {
      let ary1 = [];
      let ary2 = [];
      for (let i = 0; i < company?.prefectures?.length; i++) {
        ary1[i] = company.prefectures[i]?.prefectureId;
        ary2[i] = company.prefectures[i]?.prefectureName;
      }
      axios.get("prefecture").then((response) => {
        const responseAry1 = response.data.prefectureList.map((x) => parseInt(x.prefectureId));
        const responseAry2 = response.data.prefectureList.map((x) => x.prefectureName);
        const mapAry1 = ary1;
        const mapAry2 = ary2;
        const results1 = mapAry1.filter(x => !responseAry1.includes(x));
        const results2 = mapAry2.filter(x => !responseAry2.includes(x));
        const toRemove1 = new Set(results1);
        const toRemove2 = new Set(results2);
        const difference1 = ary1.filter(x => !toRemove1.has(x));
        const difference2 = ary2.filter(x => !toRemove2.has(x));
        setTempArea(difference1);
        setSelectedArea(difference1);
        setSelectedAreaName(difference2);
        setTempAreaName(difference2);
      }).catch(error => {
        console.log("Error occured in checkPrefectureDetails axios get method", error)
      });
    }

  function getCompanyById() {
    setOpen(true);
    axios
      .get("company/" + params.id)
      .then((response) => {
        console.log(response);
        setCompanyDetailList(response?.data)
        setAllDetails(response?.data);
        setCompanyLogoShow(response?.data?.companyLogo)
        let filePath = response?.data?.companyLogo?.split("/");
        if(filePath !== undefined && filePath !== null){
          setImage(filePath[filePath.length - 1]);
        }
       

        setSelectedIndustry(parseInt(response?.data?.industry?.industryId));
        console.log(tempIndustry);
       // IndustrySubmit();
        setIndustryName(response?.data?.industry?.industryName);
        // comapny.prefectureId = response?.data?.prefecture?.prefectureId;
        // setLocationName(response?.data?.prefecture?.prefectureName);
        // setCheckedLocation(response?.data?.prefecture?.prefectureId);
        checkPrefectureDetails(response.data);
        setPrefectureError("");
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        console.log("There was an error!", error);
      });
  }
  function getPageCountCompany() {
    showIndustry();
    setIndustryError(null);
    setTempIndustry(selectedIndustry);
  }
  function deleteFile() {
    setCompany({
      ...comapny,
      ["companyLogo"]: "",
    });
    setImage("");
    setLogoError(null);
    setCompanyLogoShow(null)
  }
  function setAllDetails(data) {
    comapny.businessContent = data.businessContent;
    comapny.capital = data.capital;
    comapny.companyLogo = data.companyLogo;
    comapny.companyName = data.companyName;
    comapny.companyURL = data.companyURL;
    comapny.description = data.description;
    comapny.headquartersLocation = data.headquartersLocation;
    comapny.industryId = data.industry?.industryId;
    // comapny.prefectureId = data.prefecture?.prefectureId;
    comapny.workingEnvironment = data.workingEnvironment;
    comapny.catchPhrase = data.catchPhrase;
  }
  const  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmit(event);
    }
  }
  return (
    <>
      <div>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-5">
          <h5>
            {isEdit ? (
              <>{t("company_page.Update_company")}</>
            ) : (
              <>{t("company_page.create_company")}</>
            )}
          </h5>
        </div>
        <div className="card create-card p-4">
          <div className="card-body">
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="name" className="form-label input-label">
                      {t("company_page.name")}
                    </label><span class="required">*</span>
                    <input
                      type="text"
                      className="form-control custom-input2"
                      id="myInputID"
                      aria-describedby="emailHelp"
                      name="companyName"
                      placeholder= {t("company_page.name")}
                      value={companyName}
                      ref={companyNameRef}
                      maxLength="100"
                      onKeyDown={onKeyDown}
                      onChange={(e) => onInputChange(e)}
                    />
                    {nameError ? <div className="error">{nameError}</div> : ""}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3 margin-bot">
                    <div>
                      <label for="name" className="form-label input-label">
                        {t("company_page.logo")}
                      </label>
                    </div>
                    <div className="row">
                    {image ? (
                         <div className="col-md-7 mb-3">
                        
                         <div className="position-relative">
                       
                        {image ? (
                         <>
                         <img className="company-logo1" src={companyLogoShow} alt="" />
                           <span className="deleteIcon position-relative" onClick={deleteFile}>
                             <i className="fa fa-times" aria-hidden="true"></i>
                           </span>
                           </>
                         ) : (
                           ""
                         )}
                         </div>
                       
                        </div>
                        ) : (
                         <> 
                          <div className="col-md-5">
                           <div className="upload-btn-wrapper">
                             <button className="upload-btn">
                               <i className="fas fa-upload pe-2"></i>
                               {t("company_page.upload_file")}
                             </button>
                             <input
                               ref={companyLogoRef}
                               type="file"
                               onKeyDown={onKeyDown}
                               accept=".jpg, .jpeg, .png"
                               name="myfile"
                               onChange={(e) => onFileSelect(e)}
                             />
                           </div>
                         </div>
                        
                       
                          <span style={{"fontSize":"10px","overflow":"hidden","text-overflow":"ellipsis","paddingTop":"5px",
                          "display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}}>{t("company_page.file_format_size_validation")}</span>
                      
                   </>
                        )}
                     
                      
                    </div>
                  </div>
                  {logoError ? (
                    <div className="error error-padding">{logoError}</div>
                  ) : (
                    ""
                  )}
                </div>
                   {/* <div className="col-md-6">
                  <div className="mb-3">
                  <div>
                      <label for="name" className="form-label input-label">
                        {t("company_page.logo")}
                      </label>
                    </div>
                     <div className="row">
                        <div className="col-md-6">
                        <div className="upload-btn-wrapper">
                             <button className="upload-btn">
                               <i className="fas fa-upload pe-2"></i>
                               {t("company_page.upload_file")}
                             </button>
                             <input
                               ref={companyLogoRef}
                               type="file"
                               accept=".jpg, .jpeg, .png"
                               name="myfile"
                               onChange={(e) => onFileSelect(e)}
                             />
                           </div>
                       </div>
                        <div className="col-md-6">
                        <div className="position-relative">
                       
                       {image ? (
                        <>
                        <img className="company-logo1" src={companyLogoShow} alt="" />
                          <span className="deleteIcon position-relative" onClick={deleteFile}>
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </span>
                          </>
                        ) : (
                          ""
                        )}
                        </div>
                    </div>
                     </div>
                  
                  </div>
                </div> */}
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="name" className="form-label input-label">
                      {t("company_page.industry")}
                    </label>
                    <input
                      type="text"
                      readOnly
                      className="form-control custom-input2 align_input_text"
                      id="myInputID"
                      value={industryName}
                      ref={industryIdRef}
                      onKeyDown={onKeyDown}
                      aria-describedby="emailHelp"
                      placeholder={t("company_page.industry")}
                      data-bs-toggle="modal"
                      data-bs-target="#industrysearch"
                      style={{ "background": "#8b8b8b14", "overflow": "auto","cursor":"pointer" }} 
                    />
                    {industryError ? (
                      <div className="error">{industryError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="name" className="form-label input-label">
                      {t("company_page.capital")}
                    </label>
                    <input
                      type="text"
                      className="form-control custom-input2"
                      id="myInputID"
                      aria-describedby="emailHelp"
                      name="capital"
                      maxLength="100"
                      onKeyDown={onKeyDown}
                      placeholder={t("experience_valid")}
                      ref={capitalRef}
                      value={capital}
                      onChange={(e) => onInputChange(e)}
                      onKeyPress={(event) => {
                        if (!/[0-9]$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {capitalError ? (
                      <div className="error">{capitalError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="name" className="form-label input-label">
                      {t("company_page.url")}
                    </label>
                    <input
                      type="text"
                      className="form-control custom-input2"
                      id="myInputID"
                      maxLength="100"
                      onKeyDown={onKeyDown}
                      aria-describedby="emailHelp"
                      name="companyURL"
                      placeholder="http://example.com"
                      value={companyURL}
                      ref={companyURLRef}
                      onChange={(e) => onInputChange(e)}
                    />
                    {urlError ? <div className="error">{urlError}</div> : ""}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="name" className="form-label input-label">
                      {t("company_page.location")}
                    </label>
                    <input
                      type="text"
                      readOnly
                      id="myInputID"
                      value={selectedAreaName}
                      // ref={prefectureIdRef}
                      onKeyDown={onKeyDown}
                      placeholder= {t("company_page.location")}
                      aria-describedby="emailHelp"
                      data-bs-toggle="modal"
                      data-bs-target="#joblocation"
                      className="form-control custom-input2 align_input_text"
                      style={{ "background": "#8b8b8b14", "overflow": "auto","cursor":"pointer" }} 
                    />
                    {prefectureError ? (
                      <div className="error">{prefectureError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="name" className="form-label input-label">
                      {t("company_page.head_quarters_location")}
                    </label>
                    <input
                      type="text"
                      className="form-control custom-input2"
                      id="hqId"
                      maxLength="100"
                      aria-describedby="emailHelp"
                      onKeyDown={onKeyDown}
                      ref={headquartersLocationRef}
                      name="headquartersLocation"
                      value={headquartersLocation}
                      onChange={(e) => onInputChange(e)}
                    />
                    {headquartersError ? (
                      <div className="error">{headquartersError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="name" className="form-label input-label">
                      {t("company_page.business_content")}
                    </label>
                    {/* <input
                      type="text"
                      className="form-control custom-input2"
                      id="contentId"
                      maxLength="1500"
                      aria-describedby="emailHelp"
                      name="businessContent"
                      ref={businessContentRef}
                      value={businessContent}
                      onChange={(e) => onInputChange(e)}
                    /> */}
                      <textarea
                      rows="3"
                      className="form-control custom-input2"
                      id="contentId"
                      maxLength="1500"
                      aria-describedby="emailHelp"
                      name="businessContent"
                      ref={businessContentRef}
                      value={businessContent}
                      onChange={(e) => onInputChange(e)}
                    ></textarea>
                    {contentError ? (
                      <div className="error">{contentError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label for="name" className="form-label input-label">
                      {t("company_page.catchPhrase")}
                    </label><span class="required">*</span>
                    <input
                      type="text"
                      className="form-control custom-input2"
                      id="hqId"
                      maxLength="500"
                      aria-describedby="emailHelp"
                      onKeyDown={onKeyDown}
                      ref={catchphraseRef}
                      name="catchPhrase"
                      value={catchPhrase}
                      onChange={(e) => onInputChange(e)}
                    />
                    {catchPhraseError ? (
                      <div className="error">{catchPhraseError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label for="name" className="form-label input-label">
                      {t("company_page.working_enviroment")}
                    </label>
                    <textarea
                      className="form-control custom-input2"
                      id="workingId"
                      rows="3"
                      name="workingEnvironment"
                      ref={workingEnvironmentRef}
                      maxLength="500"
                      value={workingEnvironment}
                      onChange={(e) => onInputChange(e)}
                    ></textarea>
                    {workingError ? (
                      <div className="error">{workingError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label for="name" className="form-label input-label">
                      {t("company_page.commpany_description")}
                    </label><span class="required">*</span>
                    <textarea
                      className="form-control custom-textarea"
                      id="descriptionId"
                      rows="6"
                      name="description"
                      maxLength="1500"
                      value={description}
                      ref={descriptionRef}
                      onChange={(e) => onInputChange(e)}
                    ></textarea>
                    {descriptionError ? (
                      <div className="error">{descriptionError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end  text-col12 my-5">
                <button
                  className="cancel-btn"
                  data-bs-dismiss="modal"
                  onClick={onCancel}
                >
                  {t("company_page.cancel")}
                </button>
                <button type="submit" className="main-buttons sub-btn">
                  {isEdit ? (
                    <>{t("company_page.update")}</>
                  ) : (
                    <>{t("company_page.save")}</>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>

      {/* industry popup */}
      <div
        className="modal fade"
        id="industrysearch"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-none">
              <div className="col-md-12 text-center mt-60">
                <div className="main-heading hot-jobhead">
                  <h4> {t("company_page.select_industry")}</h4>
                </div>
              </div>
              <button
                type="button"
                className="Close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseIndustryModal}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="search-area">
                <div>
                  <div>
                    <div className="row popup-text-align">
                      <div>
                        {industry?.map((row, index) => (
                          <div className="form-check form-check-inline mb-2 custom-mb-2">
                            <input
                              className="form-check-input form-chck"
                              type="checkbox"
                              onChange={(e) => handleOnChangeIndustry(e)}
                              id={row?.industryId}
                              value={row?.industryId}
                              checked={tempIndustry === row?.industryId}
                            />

                            <label
                              className="form-check-label ps-2"
                              for="inlineCheckbox1"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title={row?.industryName}
                            >
                              {row.industryName}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-none ">
                <div className="col-md-12 text-center">
                  <button
                    className="cancel-btn"
                    data-bs-dismiss="modal"
                    onClick={clearIndustry}
                  >
                    {t("company_page.clear")}
                  </button>
                  <button
                    className="main-buttons sub-btn"
                    data-bs-dismiss="modal"
                    onClick={IndustrySubmit}
                  >
                    {t("company_page.add")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* location popup */}

      <div
        className="modal fade"
        id="joblocation"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-none">
              <div className="col-md-12 text-center mt-60">
                <div className="main-heading hot-jobhead">
                  <h4>{t("company_page.selected_location")}</h4>
                </div>
              </div>
              <button
                type="button"
                className="Close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={cancelLocation}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="search-area">
                {areaList?.map((row, index) => (
                  <div>
                    <div className="row">
                      {row.prefectures.length > 0 ? (
                        <div className="popup-head mt-3 mb-3 d-flex">
                          <h6>{row?.areaTitle}</h6>
                        </div>
                      ) : null}
                    </div>
                    <div className="popup-text-align">
                      {row?.prefectures?.map((sub, index) => (
                        <>
                          <div className="form-check form-check-inline mb-2">
                            <input
                              className="form-check-input form-chck"
                              type="checkbox"
                              name="checkboxLoc"
                              id={sub?.prefectureId}
                              value={sub?.prefectureId}
                              onChange={(e) => onLocation(e, sub)}
                              // checked={checkedLocation == sub.prefectureId}
                              checked={tempArea?.includes(sub?.prefectureId)}
                            />

                            <label
                              className="form-check-label ps-2"
                              for="inlineCheckbox1"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title={sub?.prefectureTitle}
                            >
                              {sub?.prefectureTitle}
                            </label>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-footer border-none ">
              <div className="col-md-12 text-center">
                <button
                  className="cancel-btn"
                  data-bs-dismiss="modal"
                  onClick={clearLocation}
                >
                  {t("clear")}
                </button>
                <button
                  className="main-buttons sub-btn"
                  data-bs-dismiss="modal"
                  onClick={submitLocation}
                >
                  {t("add")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default CreateCompany;
