import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import useAppContext from '../../AppContext';
import { Link, useParams, useNavigate} from 'react-router-dom';
import Pagination from '../../pagination.js';
import { Spinner } from "react-bootstrap";
import styles from './industry.module.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Industry = () =>{ 
  const { t } = useTranslation();
  const appContext = useAppContext();
  const params = useParams();
  const navigate = useNavigate();
  const Axios = appContext.getAxios();
  const [industryList, setIndustryList] = useState([]);
  const [industryId, setIndustryId] = useState();
  const limit = 10;
  const [count, setCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(parseInt(params.page));
  const [lastPage, setLastPage] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [response, setResponse] = useState(false);
  const [errorCode, setErrorCode] =  useState();
useEffect(() => {
  window. scrollTo(0, 0);
  i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
  getIndustry(currentPage);
  window.addEventListener('popstate', onBackButtonEvent);
  // eslint-disable-next-line 
}, []);
const onBackButtonEvent = (e) => {
  e.preventDefault();
  window.location.reload()
}
function getIndustry(page) {
  appContext.setError(null);
  appContext.setSuccess(null)
  Axios.get("industry?page="+page+"&size="+limit).then((response) => {
    setIndustryList(response?.data?.industryList);
    if(response?.data?.totalCount){
    setCount(response?.data?.totalCount);
    }
   setLastPage(response?.data?.totalPages);
    setIsLoaded(true);
    console.log(industryList);
    const totalPageCount = Math.ceil(response?.data?.totalCount / limit);
      if(page > totalPageCount){
        setCurrentPage(currentPage-1);
        if(totalPageCount !== 0){
          navigatePage(currentPage-1);
        }else{
          setCurrentPage(1);
        }
      }
  }).catch(error => {
    setIsLoaded(true);
    console.log('There was an error!', error);
  });
}
function deleteIndustry(id){
  appContext.setError(null);
  appContext.setSuccess(null)
  Axios.delete('industry/'+id).then(
    (response) => {
      setResponse(true)
      console.log(response);
      getIndustry(currentPage);
      window. scrollTo(0, 0);
      appContext.setSuccess(t("industries.industry_deleted"))
    },
    (error) => {
      console.log(error)
      window. scrollTo(0, 0);
      getIndustry(currentPage);
      setResponse(false);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "34207"){
        setIndustryId(id)
        document.getElementById("deletePopUpButton").click();
      }else if(error.response?.data?.errorCode === "34203"){
        appContext.setError(t("industries.industry_not_found"))
      }else{
        appContext.setError(t("error"))
      }
     
    }
  );
}
function deleteIndustryForce(id){
  appContext.setError(null);
  appContext.setSuccess(null)
  Axios.delete('industry/'+id+"?forceDelete=1").then(
    (response) => {
      setResponse(true)
      console.log(response);
      getIndustry(currentPage);
      window. scrollTo(0, 0);
      appContext.setSuccess(t("industries.industry_deleted"))
    },
    (error) => {
      console.log(error)
      setResponse(false);
      window. scrollTo(0, 0);
      setErrorCode(error.response?.data?.errorCode);
      appContext.setError(t("error"))
    }
  );
}
function pageChange(page) {
  if(page === currentPage){
    return;
  }
  setCurrentPage(page);
  if (page >= 1 && page <= lastPage) {
    navigatePage(page);
  }
}
function navigatePage(page) {
  navigate("/industry/"+page);
  getIndustry(page);
  window. scrollTo(0, 0);
 }
 function clickDelete(id){
  deleteIndustry(id);
}
function handleDelete(){
deleteIndustryForce(industryId)
}
 function handleClosePopup(){
  if(industryList.length === 0 && currentPage > 1){
    navigate("/industry/"+(currentPage-1));
    getIndustry(currentPage-1);
    setCurrentPage(currentPage-1);
  }else{
    getIndustry(currentPage);
  }

}
  return(
  <div className={styles.Industry} data-testid="Industry">
   <div>
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-4">
                        <h5>{t('industry')}</h5>
                        <div class="col-md-3 text-end">
                        <Link to="/create-industry" state={{page:currentPage,id:null,value:null}}><button class="create-btn mt-3 mb-3"><i class="fa-solid fa-plus"></i>{t('industries.create_industry')}</button></Link>    
                        </div>
                    </div>
                    {isLoaded ?  
                    <div>
                    {industryList?.length > 0 ? <div class="card table-card">
                        <div class="card-body p-0">
                            <div class="table-responsive">
                              <table class="table custom-table1">
                                    <thead class="table-header">
                                        <tr>
                                            <th scope="col" style={{"width":"10%"}}>{t('sl_no')}</th>
                                            <th scope="col" style={{"width":"35%"}}>{t('industry')}</th>
                                            <th scope="col" style={{"width":"10%"}}>{t('action')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {industryList.map(( industry, index ) => {
                                       return (
                                        <tr>
                                            <td>{(index+1)+(limit*(currentPage-1))}</td>
                                            <td>{industry?.industryName}</td>
                                            <td>
                                                <div class="row text-center">
                                                    <div class="col-md-12  ">
                                                    <Link to={"/create-industry?id="+industry?.industryId}
                                                   state={{page:currentPage,id:industry?.industryId,value:industry.industryName}} ><button class="edit-btn me-2 mbt-3"><i
                                                                class="fa-solid fa-pen"></i></button></Link>    
                                                        <button class="delete-btn me-2 "  onClick={() => clickDelete(industry.industryId)}><i
                                                                class="fa-solid fa-trash-can"></i></button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>)})}

                                    </tbody>
                                </table> 
                            </div>
                        </div>
                    </div> : <div><h6 style={{ "textAlign": "center","fontWeight":"bold","lineHeight":"400px" }}>{t('industries.industry_not_found')}</h6></div>}
                    </div>: <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px","marginTop":"150px" }}></Spinner> }
                    


                    <div class="col-md-12 mt-5">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-end">
                            <Pagination
                                  className="pagination-bar"
                                  currentPage={currentPage}
                                  totalCount={count}
                                  pageSize={limit}
                                  onPageChange={page => pageChange(page)}
                                />
                            </ul>
                        </nav>
                    </div>
                </div>
                  {/*SUCCESS POPUP */}
       <a href="" class="edit-icon" data-bs-toggle="modal" id="successPopUpButton" data-bs-target="#successPopup" hidden></a>

<div class="modal fade" id="successPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-confirm ">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <div class="icon-box">
          {response === true ? <i class="fa fa-check" aria-hidden="true"></i> : <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>}
        </div>
       <button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn"
          data-bs-dismiss="modal" aria-label="Close" onClick={handleClosePopup}><i class="fa-solid fa-xmark"></i></button>
      </div>
      <div class="modal-body text-center">
        {response === true ? <h4>{t('success')}</h4> : <h4>{t('error')}</h4>}
        {(response === true ) ? <p>{t('industries.industry_deleted')}</p> : null}
        {response === false && errorCode === "34203" ? <p>{t('industries.industry_not_found')}</p> : null}
        <button class="btn btn-success btn-lg" data-bs-dismiss="modal" onClick={handleClosePopup}><span>{t('ok')}</span></button>
      </div>
    </div>
  </div>
</div>

  {/*DELETE POPUP */}
  <a href="" class="edit-icon" data-bs-toggle="modal" id="deletePopUpButton" data-bs-target="#deletePopup" hidden></a>

  <div class="modal fade" id="deletePopup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">

                    <div class="modal-body px-5 py-3">
                        <div class="row mb-2 mt-5 px-3">
                            <div class="col-md-12 text-center">
                                <i class="fa-regular fa-circle-xmark delete-pop"></i>
                            </div>

                            <div class="text-center mt-4 mb-4">
                                <h5>{t('warning')}</h5>
                            </div>

                            <div class="text-center">
                                <p>{t('industries.industry_warning')}</p>
                            </div>


                        </div>
                        <div class="col-md-12 text-center mb-3">
                            <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button>
                            <button class="main-buttons sub-btn"  data-bs-dismiss="modal" onClick={handleDelete}>{t('delete')}</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
  </div>
);}

export default Industry;
