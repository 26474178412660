import React from "react";
import axios from "axios";
const NO_OP = () => {};

const AppContext = React.createContext();


const useAppContext = () => React.useContext(AppContext);

const LOGIN_API = "login";
const LOGIN_API_PUT = "login";
const SESSION_KEY = "refreshToken";
const basicContentType = { "Content-Type": "application/json" };
const AXIOS = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    get: basicContentType,
    post: basicContentType,
    put: basicContentType,
    delete: basicContentType,
    patch: basicContentType,
  },
});
AXIOS.interceptors.request.use(
  function (config) {
    console.log(config)
    config.headers["authorization"] = "JobSiteAdmin " + localStorage.getItem("accessToken");
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
AXIOS.interceptors.response.use(

    (response) => {
      localStorage.setItem("session",false)
      return response;
    },
    function (error) {
      localStorage.setItem("session",false)
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        localStorage.setItem("session",true)
        console.log(localStorage.getItem("LANGUAGE"))
        localStorage.removeItem(SESSION_KEY);
        
        removeAuthorization();
       
          window.location.pathname = process.env.PUBLIC_URL+"/login";
     
    
        return Promise.reject(error);
      }
      if (error?.response?.status === 404) {
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
 );
 function AppContextProvider({ init, children }) {
    const [auth, setAuth] = React.useState(init);
    const [localSuccesstmessage, setLocalSuccessMessage] = React.useState(null);
    const [localErrorMessage, setLocalErrorMessage] = React.useState(null);
    React.useEffect(() => {
      if (auth !== null) {
      
        const time = 300000; 
        const timeout = setInterval(() => {
          refreshAccessToken();
        }, time);
     
        return () => {
          clearTimeout(timeout);
        };
      }
  
      return NO_OP;
    }, [auth]);
  
    function signIn(email, password) {
      removeAuthorization();
      let data = JSON.stringify({
        password: password,
        username: email,
      });
      return AXIOS.post(LOGIN_API, data).then(
        (response) => {
          // AXIOS.defaults.headers.common["AuthToken"] =
          //   response.data.accessToken;
         // setAuthorization(response.data.accessToken);
          localStorage.setItem(SESSION_KEY, response.data.refreshToken);
          localStorage.setItem("accessToken", response.data.accessToken);
          setAuth(response.data.accessToken);
          return { status: true, info: response };
        },
        (error) => {
          removeAuthorization();
          return { status: false, info: error };
        }
      );
    }
  
    function logout() {
      removeAuthorization();
      setAuth(null);
    }
    const setSuccess = (message) => {
    setLocalSuccessMessage(message);
    }
    const setError = (message) => {
      setLocalErrorMessage(message);
      }
    const isLoggedin = () => auth !== null;
  
    const getAccessToken = () => auth;
  
    const getAxios = () => AXIOS;
    const getlocalSuccessMessage = () => localSuccesstmessage
    const getlocalErrorMessage = () => localErrorMessage
    const context = { signIn,logout,isLoggedin, getAccessToken, getAxios,setSuccess,setError,getlocalSuccessMessage,getlocalErrorMessage};
  
    return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
  }
  function refreshAccessToken() {
    let refresToken = localStorage.getItem(SESSION_KEY);
    let refreshTokendata = JSON.stringify({
      refreshToken: refresToken,
    });
    if (refresToken === null) {
      return Promise.resolve(null);
    }
    return AXIOS.put(LOGIN_API_PUT, refreshTokendata, {
      headers: {
        authorization: null,
      },
    }).then(
      (response) => {
        if (localStorage.getItem("accessToken")) {
        // AXIOS.defaults.headers.common["AuthToken"] =
        //   response.data.accessToken;
      //  setAuthorization(response.data.accessToken);
        localStorage.setItem(SESSION_KEY, response.data.refreshToken);
        localStorage.setItem("accessToken",response.data.accessToken);
        return response.data.accessToken;
        }
      },
      () => {
        if(localStorage.getItem("accessToken")){
          return localStorage.getItem("accessToken");
        } else {
          removeAuthorization();
          localStorage.removeItem(SESSION_KEY);
          return null;
        }
      }
    );
  }
  function setAuthorization(accessTokenValue) {
    AXIOS.defaults.headers.common["authorization"] = AXIOS.defaults.headers.get[
      "authorization"
    ] = AXIOS.defaults.headers.post["authorization"] = AXIOS.defaults.headers.put[
      "authorization"
    ] = AXIOS.defaults.headers.delete[
      "authorization"
    ] = AXIOS.defaults.headers.patch["authorization"] =
      "JobSiteAdmin " + accessTokenValue;
  }
  
  function removeAuthorization() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("id");
    localStorage.removeItem(SESSION_KEY);
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("persist:root")
    delete AXIOS.defaults.headers.common["authorization"];
    delete AXIOS.defaults.headers.get["authorization"];
    delete AXIOS.defaults.headers.post["authorization"];
    delete AXIOS.defaults.headers.put["authorization"];
    delete AXIOS.defaults.headers.delete["authorization"];
    delete AXIOS.defaults.headers.patch["authorization"];
  }
  
  export default useAppContext;
  export { refreshAccessToken, AppContextProvider};
  