import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import useAppContext from '../../AppContext';
import { Link, useParams, useNavigate} from 'react-router-dom';
import Pagination from '../../pagination.js';
import { Spinner } from "react-bootstrap";
import styles from './blog.module.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Blogs = () =>{
    const { t } = useTranslation();
    const appContext = useAppContext();
    const params = useParams();
    const navigate = useNavigate();
    const Axios = appContext.getAxios();
    const [blogList, setBlogList] = useState([]);
    const [blogId, setBlogId] = useState();
    const limit = 10;
    const [count, setCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(parseInt(params.page));
    const [lastPage, setLastPage] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [response, setResponse] = useState(false);
    const [errorCode, setErrorCode] =  useState();
    useEffect(() => {
        window.scrollTo(0, 0);
        i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
        getBlog(currentPage);
        // eslint-disable-next-line 
      }, []);
      function getBlog(page) {
        appContext.setError(null);
        appContext.setSuccess(null)
        setIsLoaded(false);
        setCount(0);
        Axios.get("blog?page="+page+"&size="+limit).then((response) => {
          setBlogList(response?.data?.blogList);
          setCount(response?.data?.totalCount);
          setLastPage(response?.data?.totalPages);
          setIsLoaded(true);
          console.log(blogList);
        }).catch(error => {
          setIsLoaded(true);
          console.log('There was an error!', error);
        });
      }
      function deleteBlog(id){
        appContext.setError(null);
        appContext.setSuccess(null)
        Axios.delete('blog/'+id).then(
          (response) => {
            setResponse(true)
            console.log(response);
            getBlog(currentPage);
            window.scrollTo(0, 0);
            appContext.setSuccess(t("blogz.blog_deleted"))
          },
          (error) => {
            console.log(error)
            setResponse(false);
            window.scrollTo(0, 0);
            setErrorCode(error.response?.data?.errorCode);
            if(error.response?.data?.errorCode === "310721" || error.response?.data?.errorCode === 310721){
              appContext.setError(t("blogz.blog_not_found"))
            }else{
              appContext.setError(t("error"))
            }
          }
        );
      }
      function pageChange(page) {
        if(page === currentPage){
          return;
        }
        setCurrentPage(page);
        if (page >= 1 && page <= lastPage) {
          navigatePage(page);
        }
      }
      function navigatePage(page) {
        navigate("/blog/"+page);
        getBlog(page);
        window.scrollTo(0, 0);
       }
       function clickDelete(id){
        setBlogId(id)
        document.getElementById("deletePopUpButtonBlog").click();
      }
      function handleDelete(){
      deleteBlog(blogId)
      }
       function handleClosePopup(){
        if(blogList.length === 0 && currentPage > 1){
          navigate("/blog/"+(currentPage-1));
          getBlog(currentPage-1);
          setCurrentPage(currentPage-1);
        }else{
          getBlog(currentPage);
        }
      
      }
    return (
  <div className={styles.Blogs} data-testid="Blogs">
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
    <div>
       
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-4">
                    <h5>{t('blogs')}</h5>
                    
                    <div className="col-md-3 text-end">
                    <Link to="/create-blog" state={{page:currentPage}}><button className="create-btn mt-3 mb-3"><i className="fa-solid fa-plus"></i>{t('blogz.create_blog')}</button></Link> 
                    </div>


                </div>
                {isLoaded ?  <div>
                    {blogList?.length > 0 ?<div className="card table-card">
                    <div className="card-body p-0">
                        <div className="table-responsive">
                            <table className="table custom-table1">
                                <thead className="table-header">
                                    <tr>
                                        <th scope="col"style={{"width":"10%"}}>{t('sl_no')}</th>
                                        <th scope="col"style={{"width":"8%"}}>{t('blogz.title')}</th>
                                        <th scope="col"style={{"width":"5%"}}>{t('blogz.thumbnail')}</th>
                                        <th scope="col"style={{"width":"14%"}}>{t('description')}</th>
                                        <th scope="col"style={{"width":"8%"}}>{t('blogz.date')}</th>
                                        <th scope="col"style={{"width":"8%"}}>{t('blogz.url')}</th>
                                        <th scope="col"style={{"width":"10%"}}>{t('action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {blogList.map(( blog, index ) => {
                                       return (
                                    <tr>
                                        <td>{(index+1)+(limit*(currentPage-1))}</td>
                                        <td className="site_text_order site_text_order_align">
                                          <span className="table-text"  data-toggle="tooltip" data-placement="bottom" title={blog?.blogName}>{blog.blogName}</span></td>
                                        <td className="site_text_order site_text_order_align">
                                           <img src={blog.thumbnail} alt="" className="blogImage"/>
                                        </td>
                                        <td className="site_text_order site_text_order_align"><span className="table-text">{blog.description}</span></td>
                                        <td>{blog.dateOfBlog}</td>
                                        <td className="site_text_order site_text_order_align"><span className="table-text">
                                          <a target="_blank" href={blog.url} rel="noreferrer" className="url-a">{blog.url}</a></span></td>
                                        <td>
                                            <div className="row text-center">
                                                <div className="col-md-12 ">
                                                <Link to={"/create-blog?id="+blog?.blogId}
                                                   state={{page:currentPage}} ><button class="edit-btn me-2 mbt-3"><i
                                                                class="fa-solid fa-pen"></i></button></Link>  
                                                    <button className="delete-btn me-2" data-bs-toggle="modal"
                                                         onClick={() => clickDelete(blog.blogId)}><i
                                                            className="fa-solid fa-trash-can"></i></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>)})}
                                    <tr style={{"height":"25px"}}></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> : <div><h6 style={{ "textAlign": "center","fontWeight":"bold","lineHeight":"400px" }}>{t('blogz.blog_not_found')}</h6></div>}
                </div> : <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px","marginTop":"150px" }}></Spinner> }



                <div className="col-md-12 mt-5">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-end">
                        <Pagination
                                  className="pagination-bar"
                                  currentPage={currentPage}
                                  totalCount={count}
                                  pageSize={limit}
                                  onPageChange={page => pageChange(page)}
                                />
                        </ul>
                    </nav>
                </div>

                     {/*SUCCESS POPUP */}
       <button class="edit-icon" data-bs-toggle="modal" id="successPopUpButton" data-bs-target="#successPopup" hidden></button>

<div class="modal fade" id="successPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-confirm ">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <div class="icon-box">
          {response === true ? <i class="fa fa-check" aria-hidden="true"></i> : <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>}
        </div>
       <button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn"
          data-bs-dismiss="modal" aria-label="Close" onClick={handleClosePopup}><i class="fa-solid fa-xmark"></i></button>
      </div>
      <div class="modal-body text-center">
        {response === true ? <h4>{t('success')}</h4> : <h4>{t('error')}</h4>}
        {(response === true ) ? <p>{t('blogz.blog_deleted')}</p> : null}
        {response === false && errorCode === "310721" ? <p>{t('blogz.blog_not_found')}</p> : null}
        <button class="btn btn-success btn-lg" data-bs-dismiss="modal" onClick={handleClosePopup}><span>{t('ok')}</span></button>
      </div>
    </div>
  </div>
</div>

  {/*DELETE POPUP */}
  <button class="edit-icon" data-bs-toggle="modal" id="deletePopUpButtonBlog" data-bs-target="#deletePopup" hidden></button>

  <div class="modal fade" id="deletePopup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">

                    <div class="modal-body px-5 py-3">
                        <div class="row mb-2 mt-5 px-3">
                            <div class="col-md-12 text-center">
                                <i class="fa-regular fa-circle-xmark delete-pop"></i>
                            </div>

                            <div class="text-center mt-4 mb-4">
                                <h5>{t('are_u_sure')}</h5>
                            </div>

                            <div class="text-center">
                                <p>{t('delete_msg')}</p>
                            </div>


                        </div>
                        <div class="col-md-12 text-center mb-3">
                            <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button>
                            <button class="main-buttons sub-btn"  data-bs-dismiss="modal" onClick={handleDelete}>{t('delete')}</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
  </div>
  <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
  </div>

)};


export default Blogs;
