import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import imageAXIOS from 'axios'
import useAppContext from '../../AppContext';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { Spinner } from "react-bootstrap";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import moment from "moment";
import styles from './create-blog.module.css';
import 'dayjs/locale/ja';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateBlog = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const appContext = useAppContext();
  const Axios = appContext.getAxios();
  const search = useLocation().search;
  const [blogs, setBlog] = useState([]);
  const [blogId, setBlogId] = useState("");
  const [clickSubmit, setClickSubmit] = useState(false);
  const [inputs, setInputs] = useState({});
  const [image, setImage] = useState(null);
  const [response, setResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errorCode, setErrorCode] = useState();
  const [datePickerValue, setDatePickerValue] = useState();
  const [dateOpen, setDateOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [imgError, setImgError] = useState(null);
  const [imageShow, setImageShow] = useState(null);
  const [textCount,setTextCount] = useState(0);
  let maxDate = dayjs(new Date());
  const page = location?.state?.page
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    appContext.setError(null);
    appContext.setSuccess(null)
    setBlogId(new URLSearchParams(search).get('id') ? parseInt(new URLSearchParams(search).get('id')) : "");
    if (new URLSearchParams(search).get('id')) {
      getBlogByID(parseInt(new URLSearchParams(search).get('id')));
    } else {
      let currentDate = new Date();
      setInputs(values => ({ ...values, "dateOfBlog": moment(currentDate).format("YYYY-MM-DD") }));
    }
    // eslint-disable-next-line 
  }, []);

  function getBlogByID(id) {
    setOpen(true);
    Axios.get("blog/" + id).then((response) => {
      setOpen(false);
      setBlog(response?.data);
      setBlogDetails(response?.data)
      let filePath = response?.data?.thumbnail.split("/");
      setImage(filePath[filePath.length - 1]);
      setImageShow(response.data.thumbnail)
      console.log(blogs);
    }).catch(error => {
      setOpen(false);
      console.log('There was an error!', error);
    });
  }
  function setBlogDetails(blog) {
    let filePath = blog?.thumbnail.split('/');
    setImage(filePath[filePath.length - 1])
    setInputs(values => ({ ...values, "thumbnail": blog?.thumbnail }));
    setInputs(values => ({ ...values, "blogName": blog?.blogName }));
    setInputs(values => ({ ...values, "description": blog?.description }));
    setInputs(values => ({ ...values, "dateOfBlog": blog?.dateOfBlog }));
    setInputs(values => ({ ...values, "url": blog?.url }));
    setTextCount(blog?.description.length);
    setDatePickerValue(blog?.dateOfBlog);
  }
  function createBlog() {
    setOpen(true);
    setIsLoading(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    Axios.post('blog', inputs).then(
      (response) => {
        console.log(response);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setIsLoading(false);
        if (response.status === 200) {
          setTimeout(() => {
            navigate("/blog/1");
            setOpen(false);
         }, 200);
          setResponse(true);
        }
      },
      (error) => {
        console.log(error)
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setResponse(false);
        setIsLoading(false);
        setOpen(false);
        setErrorCode(error.response?.data?.errorCode);
        if(error.response?.data?.errorCode === "310722"){
         
          appContext.setError(t("blogz.blog_limit_error"))
        }else if(error.response?.data?.errorCode === "310720"){
         
          appContext.setError(t("blogz.duplicate"))
        }else if(error.response?.data?.errorCode === "310718" || error.response?.data?.errorCode === "310719"){
        
          appContext.setError(t("blogz.invalid_url"))
        }
        else{
          document.getElementById("successPopUpButton").click();
        }
      }
    );
  }
  function editBlog() {
    setOpen(true);
    setIsLoading(true)
    appContext.setError(null);
    appContext.setSuccess(null)
    Axios.put('blog/' + blogId, inputs).then(
      (response) => {
        console.log(response);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if (response.status === 200) {
          setTimeout(() => {
            navigate("/blog/"+page);
            setOpen(false);
         }, 200);
          setResponse(true);
        }
        setIsLoading(false);
      },
      (error) => {
        console.log(error)
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setResponse(false);
        setIsLoading(false);
        setOpen(false);
        setErrorCode(error.response?.data?.errorCode);
        if(error.response?.data?.errorCode === "310722"){
         
          appContext.setError(t("blogz.blog_limit_error"))
        }else if(error.response?.data?.errorCode === "310720"){
        
          appContext.setError(t("blogz.duplicate"))
        }else if(error.response?.data?.errorCode === "310718" || error.response?.data?.errorCode === "310719"){
        
          appContext.setError(t("blogz.invalid_url"))
        }
        else{
          document.getElementById("successPopUpButton").click();
        }
      }
    );
  }
  function submitBlog(e) {
    e.preventDefault();
    setClickSubmit(true);
    if (inputs?.thumbnail === "" || !inputs?.thumbnail) {
      setImgError(t('blogz.image_required'));
    }
    if (inputs?.blogName === "" || inputs?.description === "" || inputs?.url === "" || inputs?.dateOfBlog === "" || inputs?.thumbnail === ""
      || !inputs?.blogName || !inputs?.description || !inputs?.url || !inputs?.dateOfBlog || !inputs?.thumbnail) {
      return;
    } else {
      if (blogId) {
        editBlog();
      } else {
        createBlog();
      }
    }

  }
  const handleOnchangeDate = (newValue) => {
    setDatePickerValue(new Date(newValue));
    setInputs(values => ({ ...values, "dateOfBlog": moment(new Date(newValue)).format("YYYY-MM-DD") }));
    console.log(inputs);
  }
  const handleChange = (event) => {
    const name = event.target?.name;
    const value = event.target?.value;
    if(name === "description"){
      setTextCount(value.length);
      console.log(value.length)
    }
    setInputs(values => ({ ...values, [name]: value }));
    console.log(inputs)
  }
  function onFileSelect(e) {
    if (e.target.files.length > 0) {
      let image = e.target.files[0];
      if (image.size <= '10485760') {
        console.log(e.target.files[0]);
        let reader = new FileReader();
        reader.readAsDataURL(image);
        const formData = new FormData()
        formData.append("uploadfile", image);
        setIsUploading(true);
        imageAXIOS
          .post(process.env.REACT_APP_BASE_URL + 'file/imageUpload', formData, { headers: { "Authorization": "JobSiteAdmin " + localStorage.getItem("accessToken"), } })
          .then((response) => {
            setIsUploading(false);
            let filePath = response?.data?.filepath.split('/');
            setImage(filePath[filePath.length - 1]);
            setImageShow(response?.data?.location)
            setInputs(values => ({ ...values, "thumbnail": filePath[filePath.length - 1] }));
            setImgError(null)
            console.log(inputs);
          })
          .catch((error) => {
            setIsUploading(false);
          });
        e.target.value = "";
      } else {
        setResponse(false);
        setImgError(t("company_page.file_max_size"))
      }
    }
  }
  function deleteFile() {
    setImage('');
    setInputs(values => ({ ...values, "thumbnail": "" }));
    setImgError(null);
    setImageShow(null);
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      submitBlog(event);
    }
  };
  return (
    <div className={styles.CreateBlog} data-testid="CreateBlog">
      <div>
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-5">
          {blogId && <h5>{t('blogz.edit_blog')}</h5> } {!blogId && <h5>{t('blogz.create_blog')}</h5>}
        </div>
        <div class="card create-card p-4">
          <div class="card-body">
            <form>
              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('blogz.blog_title')}</label><span class="required">*</span>
                    <input type="text" class="form-control custom-input2" id="myInputID" aria-describedby="emailHelp" placeholder={t('blogz.blog_title')}
                      name="blogName" maxLength={150} value={inputs.blogName} onChange={handleChange} onKeyDown={e => { handleKeyDown(e) }} />
                    {(clickSubmit && !inputs.blogName) && <div className="error">{t('blogz.blog_required')}</div> }
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="mb-3 margin-bot">
                    <div>
                      <label for="name" className="form-label input-label">
                        {t('blogz.upload_image')}
                      </label><span class="required">*</span>
                    </div>
                    {isUploading && <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px" }}></Spinner>}
                    { !isUploading && <div className="row">
                      {image &&
                        <div className="col-md-7 mb-3">

                          <div className="position-relative">

                            {image &&
                              <>
                                <img className="company-logo1" src={imageShow} alt="" />
                                <span className="deleteIcon position-relative" onClick={deleteFile}>
                                  <i class="fa fa-times" aria-hidden="true"></i>
                                </span>
                              </>
                             }
                          </div>

                        </div>}
                       { !image && 
                        <div className="col-md-10">
                          <div className="upload-btn-wrapper">
                            <button className="upload-btn">
                              <i className="fas fa-upload pe-2"></i>
                              {t("company_page.upload_file")}
                            </button>
                            <input
                              type="file"
                              accept=".jpg, .jpeg, .png"
                              name="myfile"
                              onChange={(e) => onFileSelect(e)}
                            />
                          </div>
                          <span style={{"fontSize":"10px","overflow":"hidden","text-overflow":"ellipsis","paddingTop":"5px",
                          "display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}}>{t("company_page.file_format_size_validation")}</span>  
                        </div>
                        
                      }


                      <div className="error">{imgError}</div>
                      
                    </div>}
                  </div>
                </div>

              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('blogz.date')}</label><span class="required">*</span>
                    <form >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="ja">
                        <DatePicker
                          clearable={true}
                          open={dateOpen}
                          onClose={() => setDateOpen(false)}
                          value={datePickerValue}
                          maxDate={maxDate}
                          className="form-control custom-input2"
                          inputFormat="YYYY-MM-DD"
                          renderInput={(params) => (<TextField {...params} sx={{
                            '.MuiInputBase-input': { fontSize: 14, height: 27, borderRadius: "10px" },
                          }}
                            onClick={() => setDateOpen(true)} inputProps={{ ...params.inputProps, readOnly: true, }}  onKeyDown={e => { handleKeyDown(e) }}/>)}
                          onChange={(newValue) => setDatePickerValue(new Date(newValue))}
                          onAccept={(newValue) => handleOnchangeDate(newValue)} />
                      </LocalizationProvider>
                    </form>
                  </div>
                  {(clickSubmit && !inputs.dateOfBlog) &&  <div className="error">{t('blogz.date_required')}</div> }

                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('blogz.url')}</label><span class="required">*</span>
                    <input type="text" name="url" value={inputs.url} class="form-control custom-input2" id="myInputID" aria-describedby="emailHelp"
                    placeholder="http://www.example.com"  maxLength={300} onChange={handleChange} onKeyDown={e => { handleKeyDown(e) }} />
                    {(clickSubmit && !inputs.url) && <div className="error">{t('blogz.url_required')}</div> }
                  </div>
                </div>
              </div>


              <div class="row mb-3">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('blogz.blog_description')}</label><span class="required">*</span>
                    <textarea class="form-control custom-textarea" id="myInputID" rows="6" placeholder={t('blogz.blog_description')}
                      maxLength={1000} value={inputs.description} name="description" onChange={handleChange} ></textarea>
                    {(clickSubmit && !inputs.description) &&  <div className="error">{t('blogz.description_required')}</div> }
                    <div className="textCount">{textCount}/1000</div>
                  </div>
                </div>
              </div>



              <div class="col-md-12 text-end my-5">
                {!isLoading &&  <div>
                  {page?<Link to={"/blog/" + page}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link>:
                  <Link to={"/blog/1"}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link>}
                  <button class="main-buttons sub-btn" onClick={(e) => submitBlog(e)}>{blogId ? t('update') : t('save')}</button>
                </div> }
                {isLoading === true && <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px" }}></Spinner> }
              </div>



            </form>
          </div>
        </div>
      </div>
      {/*SUCCESS POPUP */}
      <a href="" class="edit-icon" data-bs-toggle="modal" id="successPopUpButton" data-bs-target="#successPopup" hidden></a>

      <div class="modal fade" id="successPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-confirm ">
          <div class="modal-content">
            <div class="modal-header justify-content-center">
              <div class="icon-box">
                 <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              </div>
             
                <button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn"
                  data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
            </div>
            <div class="modal-body text-center">
             <h4>{t('error')}</h4>
              {(response === true && !blogId) && <p>{t('blogz.blog_created')}</p>}
              {(response === true && blogId) && <p>{t('blogz.blog_updated')}</p> }
              {(response === false && errorCode === "310721") && <p>{t('blogz.blog_not_found')}</p> }
              {(response === false && errorCode === "310713") && <p>{t('invalid_date')}</p>}
              {(response === false && errorCode === "310718") && <p>{t('blogz.invalid_url')}</p>}
              {(response === false && errorCode === "310719") && <p>{t('blogz.invalid_url')}</p> }
              {(response === false && errorCode === "310708") && <p>{t('blogz.invalid_desc')}</p> }
              {(response === false && errorCode === "310712") && <p>{t('blogz.invalid_thumb')}</p> }
              {(response === false && errorCode === "310704") && <p>{t('blogz.invalid_title')}</p> }
              {(response === false && errorCode === "310703") && <p>{t('blogz.title_size_error')}</p> }
              {(response === false && errorCode === "555") && <p>{t('blogz.invalid_img_size')}</p> }
              {(response === false && errorCode === "310707") && <p>{t('blogz.description_size_error')}</p> }
              {(response === false && errorCode === "310722") && <p>{t('blogz.blog_limit_error')}</p> }
              {(response === false && errorCode === "310720") && <p>{t('blogz.duplicate')}</p> }
              {response === true && <Link to={"/blog/" + page}> <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button> </Link>}
                {response === false && <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button>}
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </div>
  )
};


export default CreateBlog;
