import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import useAppContext from '../../AppContext';
import { Link, useParams, useNavigate, useLocation} from 'react-router-dom';
import Pagination from '../../pagination.js';
import { Spinner } from "react-bootstrap";
import styles from './prefecture.module.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Prefecture = () => {

  const { t } = useTranslation();
  const appContext = useAppContext();
  const params = useParams();
  const search = useLocation().search;
  const navigate = useNavigate();
  const Axios = appContext.getAxios();
  const [prefectureList, setPrefectureList] = useState([]);
  const [prefectureId, setPrefectureId] = useState();
  const limit = 10;
  const [count, setCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(parseInt(params.page));
  const [lastPage, setLastPage] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [response, setResponse] = useState(false);
  const [errorCode, setErrorCode] =  useState();
  const [freeWord, setFreeWord] =  useState(new URLSearchParams(search)?.get('search'));
  useEffect(() => {
    window.scrollTo(0, 0);
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    getPrefecture(currentPage);
    window.addEventListener('popstate', onBackButtonEvent);
    // eslint-disable-next-line 
  }, []);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.location.reload()
  }
  function getPrefecture(page) {
    appContext.setError(null);
    appContext.setSuccess(null)
    if(freeWord === ""  || !freeWord){
      Axios.get("prefecture?page="+page+"&size="+limit).then((response) => {
        setPrefectureList(response.data.prefectureList);
        setCount(response?.data?.totalCount);
        setLastPage(response?.data?.totalPages);
        setIsLoaded(true);
    console.log(prefectureList);
    const totalPageCount = Math.ceil(response?.data?.totalCount / limit);
      if(page > totalPageCount){
        setCurrentPage(currentPage-1);
        if(totalPageCount !== 0){
          navigatePage(currentPage-1);
        }else{
          setCurrentPage(1);
        }
      }
  }).catch(error => {
    setIsLoaded(true);
    console.log('There was an error!', error);
  });
    }else{
    Axios.get("prefecture?page="+page+"&size="+limit+"&search="+freeWord).then((response) => {
        setPrefectureList(response.data.prefectureList);
        setCount(response?.data?.totalCount);
        setLastPage(response?.data?.totalPages);
        setIsLoaded(true);
    console.log(prefectureList);
    const totalPageCount = Math.ceil(response?.data?.totalCount / limit);
      if(page > totalPageCount){
        setCurrentPage(currentPage-1);
        if(totalPageCount !== 0){
          navigatePage(currentPage-1);
        }
      }
  }).catch(error => {
    setIsLoaded(true);
    console.log('There was an error!', error);
  });
}
}
function deletePrefecture(id){
  appContext.setError(null);
  appContext.setSuccess(null)
    Axios.delete('prefecture/'+id).then(
      (response) => {
        setResponse(true)
        console.log(response);
        getPrefecture(currentPage);
        window.scrollTo(0, 0);
        appContext.setSuccess(t("prefecture.prefecture_deleted"))
      },
      (error) => {
        console.log(error)
        setResponse(false);
        window.scrollTo(0, 0);
        getPrefecture(currentPage);
        setErrorCode(error.response?.data?.errorCode);
        if(error.response?.data?.errorCode === "37301"){
          setPrefectureId(id);
          document.getElementById("deletePopUpButton").click();
        }else if(error.response?.data?.errorCode === "37401"){
          appContext.setError(t("prefecture.prefecture_not_found"))
        }else{
          appContext.setError(t("error"))
        }
        
      }
    );
  }
  function deleteSubCategoryForce(id){
    appContext.setError(null);
    appContext.setSuccess(null)
    Axios.delete('prefecture/'+id+"?forceDelete=1").then(
      (response) => {
        setResponse(true)
        console.log(response);
        getPrefecture(currentPage);
        window.scrollTo(0, 0);
        appContext.setSuccess(t("prefecture.prefecture_deleted"))
      },
      (error) => {
        console.log(error)
        setResponse(false);
        window.scrollTo(0, 0);
        setErrorCode(error.response?.data?.errorCode);
        appContext.setError(t("error"))
      }
    );
  }
  function pageChange(page) {
    if(page === currentPage){
      return;
    }
    setCurrentPage(page);
    if (page >= 1 && page <= lastPage) {
      navigatePage(page);
    }
  }
  function navigatePage(page) {
    if(freeWord === "" || freeWord === null){
      navigate("/prefecture/"+page);
    }else{
      navigate("/prefecture/"+page+"?search="+freeWord.trim());
    }
    getPrefecture(page);
    window.scrollTo(0, 0);
   }
   function clickDelete(id){
    deletePrefecture(id)
  }
  function handleDelete(){
    deleteSubCategoryForce(prefectureId)
  }
   function handleClosePopup(){
    if(prefectureList.length === 0 && currentPage > 1){
      navigate("/prefecture/"+(currentPage-1));
      getPrefecture(currentPage-1);
      setCurrentPage(currentPage-1);
    }else{
      getPrefecture(currentPage);
    }
  }
  const handleKeyDown = (e) =>{
    if (e.key === 'Enter') {
      freeWordSearch();
    }
  }
  const handleOnChangeFreeText = (event) => {
    setFreeWord(event.target.value)
    console.log(freeWord)
  }
  function freeWordSearch(){
    setCurrentPage(1);
    getPrefecture(1);
    if(freeWord === "" || freeWord === null){
      navigate("/prefecture/1");
    }else{
      navigate("/prefecture/1?search="+freeWord);
    }
  }
  return(
  <div className={styles.JobSubCategory} data-testid="JobSubCategory">
        <div>
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-4">
                        <h5>{t('prefectures')}</h5>
                        <div class="col-md-7">
                            <div class="row">
                                <div class="col-md-4">
                                <Link to="/create-prefecture" state={{page:currentPage,
                                                                     id:null,
                                                                     search:freeWord,
                                                                     value:null,
                                                                     locationId:null,
                                                                     locationName:null}}> 
                                 <button class="create-btn mt-3 mb-3"><i class="fa-solid fa-plus"></i>{t('prefecture.create_prefecture')}</button></Link>
                                </div>
                                <div class="col-md-8">
                                    <div class="input-group mt-3 mb-3">
                                        <input type="text" class="form-control custom-search" placeholder={t('search_by_prefecture')} onKeyDown={handleKeyDown}
                                         value={freeWord}  onChange={e => handleOnChangeFreeText(e)} aria-label="Search" aria-describedby="basic-addon2"id="myInputID"/>
                                        <button type="button" class="input-group-text search-btn" id="basic-addon2" onClick={freeWordSearch} ><i
                                               class="fa-solid fa-magnifying-glass"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoaded ?  
                    <div>
                 {prefectureList?.length > 0 ?  <div class="card table-card">
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table custom-table1 text-center">
                                    <thead class="table-header">
                                        <tr>
                                            <th scope="col" style={{"width":"15%"}}>{t('sl_no')}</th>
                                            <th scope="col" style={{"width":"30%"}}>{t('prefectures')}</th>
                                            <th scope="col" style={{"width":"30%"}}>{t('high_level_location')}</th>
                                            <th scope="col" style={{"width":"10%"}}>{t('action')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {prefectureList.map(( prefecture, index ) => {
                                       return (
                                        <tr>
                                            <td>{(index+1)+(limit*(currentPage-1))}</td>
                                            <td style={{"maxWidth":"50px"}}><span className="table-text">{prefecture?.prefectureName}</span></td>
                                            <td style={{"maxWidth":"50px"}}><span className="table-text">{prefecture?.highLevelLocation?.highLevelLocationName}</span></td>
                                            <td>
                                            <div class="row text-center">
                                                    <div class="col-md-12  ">
                                                    <Link to={"/create-prefecture?id="+prefecture?.prefectureId}
                                                   state={{page:currentPage,
                                                          id:prefecture?.prefectureId,
                                                          search:freeWord,
                                                          value:prefecture?.prefectureName,
                                                          locationId:prefecture?.highLevelLocation?.highLevelLocationName,
                                                          locationName:prefecture?.highLevelLocation?.highLevelLocationName}}>
                                                            <button class="edit-btn me-2 mbt-3"><i
                                                                class="fa-solid fa-pen"></i></button></Link>    
                                                        <button class="delete-btn me-2 "  onClick={() => clickDelete(prefecture?.prefectureId)}><i
                                                                class="fa-solid fa-trash-can"></i></button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>)})}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>: <div><h6 style={{ "textAlign": "center","fontWeight":"bold","lineHeight":"400px" }}>{t('prefecture.prefecture_not_found')}</h6></div>}
                    </div> : <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px","marginTop":"150px" }}></Spinner> }



                    {isLoaded? <div class="col-md-12 mt-5">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-end">
                            <Pagination
                                  className="pagination-bar"
                                  currentPage={currentPage}
                                  totalCount={count}
                                  pageSize={limit}
                                  onPageChange={page => pageChange(page)}
                                />
                            </ul>
                        </nav>
                    </div> : null}
                </div>

                                {/*SUCCESS POPUP */}
       <button class="edit-icon" data-bs-toggle="modal" id="successPopUpButton" data-bs-target="#successPopup" hidden></button>

<div class="modal fade" id="successPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-confirm ">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <div class="icon-box">
          {response === true ? <i class="fa fa-check" aria-hidden="true"></i> : <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>}
        </div>
       <button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn"
          data-bs-dismiss="modal" aria-label="Close" onClick={handleClosePopup}><i class="fa-solid fa-xmark"></i></button>
      </div>
      <div class="modal-body text-center">
        {response === true ? <h4>{t('success')}</h4> : <h4>{t('error')}</h4>}
        {(response === true ) ? <p>{t('prefecture.prefecture_deleted')}</p> : null}
        {response === false && (errorCode === "37203" || errorCode === "37401") ? <p>{t('prefecture.prefecture_not_found')}</p> : null}
        <button class="btn btn-success btn-lg" data-bs-dismiss="modal" onClick={handleClosePopup}><span>{t('ok')}</span></button>
      </div>
    </div>
  </div>
</div>

  {/*DELETE POPUP */}
  <button class="edit-icon" data-bs-toggle="modal" id="deletePopUpButton" data-bs-target="#deletePopup" hidden></button>

  <div class="modal fade" id="deletePopup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">

                    <div class="modal-body px-5 py-3">
                        <div class="row mb-2 mt-5 px-3">
                            <div class="col-md-12 text-center">
                                <i class="fa-regular fa-circle-xmark delete-pop"></i>
                            </div>

                            <div class="text-center mt-4 mb-4">
                                <h5>{t('warning')}</h5>
                            </div>

                            <div class="text-center">
                                <p>{t('prefecture.prefecture_warning')}</p>
                            </div>


                        </div>
                        <div class="col-md-12 text-center mb-3">
                            <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button>
                            <button class="main-buttons sub-btn"  data-bs-dismiss="modal" onClick={handleDelete}>{t('delete')}</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
  </div>
);}

export default Prefecture;
