import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import useAppContext from '../../AppContext';
import styles from './create-skill.module.css';
import { Spinner } from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateSkill = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const appContext = useAppContext();
  const Axios = appContext.getAxios();
  const search = useLocation().search;
  const [skillId, setSkillId] = useState("");
  const [skill, setSkill] = useState();
  const [inputs, setInputs] = useState({});
  const [clickSubmit, setClickSubmit] = useState(false);
  const [response, setResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] =  useState();
  const [open, setOpen] = useState(false);
  const page = location?.state?.page;
  useEffect(() => {
    window. scrollTo(0, 0);
    appContext.setError(null);
    appContext.setSuccess(null)
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    if (new URLSearchParams(search).get('id')) {
      getSkill(parseInt(new URLSearchParams(search).get('id')) )
    }
    enterKeyEvent();
    // eslint-disable-next-line 
  }, []);

  function submit(e){
    e.preventDefault();
    setClickSubmit(true);
    if (inputs.skill === "" || !inputs.skill) {
    return;
  } else {
    if(skillId){
      editSkill()
    }else{
      addSkill();
    }
  }
  }
  const handleChange = (event) => {
    const value = event.target?.value;
    const name = event.target?.name;
    setInputs(values => ({ ...values, [name]: value }));
    console.log(inputs)
  }
  function getSkill(id) {
    setOpen(true);
    Axios.get("skill/"+id).then((response) => {;
      if (response.status === 200) {
        setOpen(false);
        console.log(response)
        setResponse(true);
         setSkillId(response?.data?.skillId)
         setInputs(values => ({ ...values, "skill": response?.data?.skillName}));
      }
    }).catch(error => {
      setOpen(false);
      console.log('There was an error!', error);
      setResponse(false);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "315203"){
        if(page !== undefined){
          navigate("/skill/"+page);
        }else{
          navigate("/skill/1");
        }
     }
    });
  }
  function addSkill() {
    setOpen(true);
    setIsLoading(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    const body = {"skill":inputs.skill.trim()}
    Axios.post("skill",body).then((response) => {;
      setIsLoading(false);
      if (response.status === 200) {
        setTimeout(() => {
          navigate("/skill/1");
          setOpen(false);
       }, 200);
        setResponse(true);
      }
    }).catch(error => {
      console.log('There was an error!', error);
      setIsLoading(false);
      setOpen(false);
      setResponse(false);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "381503"){
        appContext.setError(t("skillz.skill_exist"))
      }else
      document.getElementById("successPopUpButton").click();
    });
  }
  function editSkill() {
    setOpen(true);
    setIsLoading(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    const body = {"skill":inputs.skill.trim()}
    Axios.put("skill/"+skillId,body).then((response) => {;
      setIsLoading(false);
      if (response.status === 200) {
        setTimeout(() => {
          navigate("/skill/"+page);
          setOpen(false);
       }, 200);
        setResponse(true);
      }
    }).catch(error => {
      console.log('There was an error!', error);
      setIsLoading(false);
      setOpen(false);
      setResponse(false);
      setErrorCode(error.response?.data?.errorCode);
      document.getElementById("successPopUpButton").click();
    });
  }
  function enterKeyEvent(){
    let el = document.getElementById("myInputID");
    el.addEventListener("keypress", function(event) {
    if (event.key === "Enter") {
      document.getElementById("saveBtn").click();
      event.preventDefault();
    }
   });
  }
  return(
  <div className={styles.CreateIndustry} data-testid="CreateIndustry">
         <div>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-5">
            {skillId? <h5>{t('skillz.edit_skill')}</h5> : <h5>{t('skillz.create_skill')}</h5>}
         </div>
         <div class="card create-card p-4 vh-100">
             <div class="card-body">
                 <form>
                     <div class="row mb-3">
                       <div class="col-md-12">
                         <div class="mb-3">
                           <label for="name" class="form-label input-label">{t('skillz.skill')}</label><span class="required">*</span>
                           <input type="text" class="form-control custom-input2" id="myInputID" aria-describedby="emailHelp" placeholder={t('skillz.skill')}
                            maxLength={25} name="skill"  value={inputs.skill} onChange={handleChange}/>
                         </div>
                         {(clickSubmit && !inputs.skill) ? <div className="error">{t('skillz.skill_required')}</div> : null}
                       </div>
                    
                     </div> 
                     <div class="col-md-12 text-end my-5">
                     {!isLoading ? <div>
                { page? <Link to={"/skill/"+page}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link> :
                  <Link to={"/skill/1"}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link> }
                  <button class="main-buttons sub-btn" id="saveBtn" onClick={(e) => submit(e)}>{skillId?t('update') : t('save')}</button>
                </div> : null}
                {isLoading === true ? <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px" }}></Spinner> : null
                }
                     </div>
                   </form>
             </div>
           </div>
 
         </div>
            {/*SUCCESS POPUP */}
         <a href="" class="edit-icon" data-bs-toggle="modal" id="successPopUpButton" data-bs-target="#successPopup" hidden></a>
 
 <div class="modal fade" id="successPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-confirm ">
                    <div class="modal-content">
                        <div class="modal-header justify-content-center">
                            <div class="icon-box">
                               {response === true ?  <i class="fa fa-check" aria-hidden="true"></i> : <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> }
                            </div>
                            <button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                        </div>
                        <div class="modal-body text-center">
                            {response === true ? <h4>{t('success')}</h4> : <h4>{t('error')}</h4>}
                            {response === true && !skillId ? <p>{t('skillz.skill_created')}</p> : null}
                            {response === true && skillId ? <p>{t('skillz.skill_updated')}</p> : null}
                            {response === false && errorCode === "381503" ? <p>{t('skillz.skill_exist')}</p> : null}      
                            {response === false && errorCode === "315203" ? <p>{t('skillz.skill_not_found')}</p> : null}                       
                            {response === true?  <Link to={"/skill/"+page}> <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button></Link>
                             : <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button>}
                        </div>
                    </div>
                </div>
            </div>
            <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
  </div>
)};

export default CreateSkill;
