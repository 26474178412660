import React, { useEffect, useState ,useMemo} from "react";
import { useTranslation } from "react-i18next";
import useAppContext from "../../AppContext";
import i18n from "../../i18n";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/couterReducer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../../pagination";
import moment from 'moment'
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
function User() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  const [open, setOpen] = React.useState(false);
  const [count, setCount] = useState(0);
  const [allUser, setAllUser] = useState([]);
  const counterState = useSelector((state) => state.counter);
  const [currentPage, setCurrentPage] = useState(parseInt(params.page));
  const dispatch = useDispatch();
  const [deleteRow, setDeleteRow] = useState("");
  const limit = 10;
  const [freeText, setFreeText] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [statusList,setStatusList] = useState([
    {id:0,name:'Created'},
    {id:1,name:'Active'},
    {id:2,name:'Bloked'},
    {id:3,name:'Deleted'},
  ])
 
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    getAllUser(currentPage);
    window.addEventListener('popstate', onBackButtonEvent);
  }, []);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.location.reload()
  }
  // useMemo(() => getPageCountCompany(currentPage), [currentPage]);
  function getAllUser(page) {
    appContext.setError(null);
    appContext.setSuccess(null)
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    if(freeText !== undefined && freeText !== null && freeText !== ""){
      console.log(freeText)
      setFreeText(freeText.trim());
    }
   
    axios
      .get("users?page=" + page + "&size=" + limit + "&search=" +  encodeURIComponent(freeText.trim())
      .replace('_', '%5C%5F')
      .replace(/\\/, '%5C')
      .replace('%25',"%5C%25"))
      .then((response) => {
       
        console.log(response);
        for (const iterator of response?.data?.userList) {
          let value = statusList.find(element => element.id === iterator.status)
          if(value){
            iterator.statusName = value.name;
          }
          if(iterator.status === 0) {
            iterator.disabled = true;
          }
        }
        setAllUser(response?.data?.userList);
        setCount(response?.data?.totalCount);
        setTimeout(() => {
          tableScroll();
       }, 500);  
        const totalPageCount = Math.ceil(response?.data?.totalCount / limit);
        if(page > totalPageCount){
          setCurrentPage(totalPageCount);
          if(totalPageCount !== 0){
            dispatch(actions.setPageUserCount(totalPageCount));
            getAllUser(totalPageCount);
            navigatePage(totalPageCount);
          }
         
        }
       
        setIsLoaded(true);
        setOpen(false);
        console.log(totalPageCount);
      })
      .catch((error) => {
        setIsLoaded(true);
        setOpen(false);
        console.log("There was an error!", error);
      });
  }
  function tableScroll(){
    const slider = document.querySelector('.table-responsive');
    let isDown = false;
    let startX;
    let scrollLeft;
    
    slider?.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider?.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider?.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider?.addEventListener('mousemove', (e) => {
      if(!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      console.log(walk);
    });
  }
  function pageChange(page) {
    console.log(page);
    setCurrentPage(page);
    dispatch(actions.setPageUserCount(page));
    getAllUser(page);
    navigatePage(page);
  }
  function onDelete(row) {
    console.log(row);
    setDeleteRow(row);
  }
  function deleteUser(){
    appContext.setError(null);
    appContext.setSuccess(null)
    setOpen(true);
    axios
      .delete("/users/" + deleteRow.userId)
      .then((response) => {
        setOpen(false);
     
        getAllUser(currentPage);
        navigatePage(currentPage);
        appContext.setSuccess(t("userlist.user_delete_success"))
      })
      .catch((error) => {
        setOpen(false);
        appContext.setError(t("userlist.user_delete_fail"));
      });
  }
  function getPageCountCompany(){
    setOpen(true);
    setTimeout(() => {
    getAllUser();
    },100)
  
  }
  function navigatePage(page) {
   
      navigate("/user/"+page);
  }
  function onStatusChange(row){
    appContext.setError(null);
    appContext.setSuccess(null)
    setOpen(true);
    axios
      .patch("/users/changeStatus/" + row.userId)
      .then((response) => {
        setOpen(false);
        getAllUser(currentPage);
        navigatePage(currentPage);
        if(row.status === 1){
         
          appContext.setSuccess(t("userlist.user_blocked_success"));
        } else if(row.status === 2){
       
          appContext.setSuccess(t("userlist.user_Unblocked_success"));
        }
       
       
       
      })
      .catch((error) => {
        setOpen(false);
        appContext.setError(t("userlist.user_not_found"));
      });
  }
  function onSearch(){
   setCurrentPage(1);
    dispatch(actions.setPageUserCount(1));
 
    getAllUser(1);
    navigatePage(1);

  }
  const handleOnChangeFreeText = (event) => {
    setFreeText(event.target.value);
    console.log(freeText);
  };
   const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setCurrentPage(1);
      dispatch(actions.setPageUserCount(1));
      getAllUser(1);
      navigatePage(1);

    }
  };
  return(
  <>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-4">
      <h5>{t("userlist.user_management")}</h5>
      <div className="col-md-4">
        <div className="input-group mt-3 mb-3">
          <input
            type="text"
            className="form-control custom-search"
            placeholder={t("userlist.search_By_email")}
            aria-label="Search"
            value={freeText} 
            onChange={(e) => handleOnChangeFreeText(e)}
            onKeyDown={handleKeyDown}
            aria-describedby="basic-addon2"
            id="myInputID"
          />
          <button onClick={onSearch}
            type="button"
            className="input-group-text search-btn"
            id="basic-addon2"
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </div>
    {isLoaded ?  
      <div>
       {allUser.length > 0 ? 
    <div className="card table-card">
      <div className="card-body p-0">
        <div className="table-responsive">
          <table className="table custom-table">
            <thead className="table-header">
              <tr>
                <th style={{'width':"5%"}} scope="col">{t("userlist.s_no")}</th>
                <th style={{'width':"8%"}} scope="col">{t("userlist.name")}</th>
                <th style={{'width':"9%"}} scope="col">{t("userlist.email")}</th>
                <th style={{'width':"9%"}} scope="col">{t("userlist.location")}</th>
                <th style={{'width':"6%"}} scope="col">{t("userlist.date_brith")}</th>
                <th style={{'width':"3%"}} scope="col">{t("userlist.telephone")}</th>
                {/* <th style={{'width':"8%"}} scope="col">{t("userlist.interesed_job_categories")}</th> */}
               
                <th style={{'width':"6%"}} scope="col">{t("userlist.date")}</th>
                <th style={{'width':"4%"}} scope="col">{t("userlist.status")}</th>
                <th style={{'width':"5%"}} scope="col">{t("userlist.action")}</th>
              </tr>
            </thead>
            <tbody>
              {allUser.length > 0 ? (
                <>
                  {allUser?.map((row, index) => (
                    <tr>
                      <td className="site_text_order site_text_order_align">{(index+1)+(limit*(currentPage-1))}</td>
                      <td className="site_text_order site_text_order_align"> {row?.lastName} {row?.middleName} {row?.Name}</td>
                      <td className="site_text_order site_text_order_align">{row?.email}</td>
                      <td className="site_text_order site_text_order_align">{row?.prefecture} {row?.city} {row?.apartmentName}</td>
                      <td className="site_text_order site_text_order_align">{ moment(row?.dateOfBirth).format("YYYY-MM-DD")}</td>
                      <td className="site_text_order site_text_order_align">{row?.telNumber}</td>
                      
                      {/* <td className="site_text_order site_text_order_align">
                      {row?.jobSubCategory?.map((category, index) => (
                        <span> {category?.subcategoryName} 
                          {row?.jobSubCategory.length-1 === index ? '': ","}</span>
                      ))}
                        </td> */}
                     
                      <td className="site_text_order site_text_order_align">{ moment(row?.registerDate).format("YYYY-MM-DD")}</td>
                      <td className="site_text_order site_text_order_align">{t(row?.statusName)}</td>
                      <td>
                        <div className="row">
                          <div className="col-md-12 ">
                            {row?.status === 0 ?(    <button  disabled={row.disabled} className="ban-gray me-2">
                              <i className="fa-solid fa-ban"></i>
                            </button>): (   <button  className={
                                     row?.status === 2 ? "ban-blocked me-2" : "ban-green me-2"
                                             } onClick={(e) => onStatusChange(row)}>
                              <i className="fa-solid fa-ban"></i>
                            </button>)}
                          

                            <button className="delete-btn me-2"  data-bs-toggle="modal"
                                  data-bs-target="#deleteUser" onClick={(e) => onDelete(row)}>
                              <i className="fa-solid fa-trash-can"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}{" "}
                </>
              ) : (
                <tr>
                  <td className="no-data" colspan="10">
                  {t("userlist.user_not_found")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
 : <div><h6 style={{ "textAlign": "center","fontWeight":"bold","lineHeight":"400px" }}>  {t("userlist.user_not_found")}</h6></div>}
 </div>: <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px","marginTop":"150px" }}></Spinner> }
    <div className="col-md-12 mt-5">
      <Pagination
        className="pagination-bar page-item page-list"
        currentPage={currentPage}
        totalCount={count}
        pageSize={limit}
        onPageChange={(page) => pageChange(page)}
      />
    </div>
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />

    <div class="modal fade" id="deleteUser" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">

                    <div class="modal-body px-5 py-3">
                        <div class="row mb-2 mt-5 px-3">
                            <div class="col-md-12 text-center">
                                <i class="fa-regular fa-circle-xmark delete-pop"></i>
                            </div>

                            <div class="text-center mt-4 mb-4">
                                <h5> {t("userlist.delete_heading")}</h5>
                            </div>

                            <div class="text-center">
                                <p> {t("userlist.delete_message")}</p>
                            </div>


                        </div>
                        <div class="col-md-12 text-center mb-3">
                            <button class="cancel-btn" data-bs-dismiss="modal">{t("userlist.cancel")}</button>
                            <button class="main-buttons sub-btn"  data-bs-dismiss="modal" onClick={deleteUser}>{t("userlist.delete")}</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
  </>
  );
}

export default User;
