import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import useAppContext from "../../AppContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { actions } from "../../redux/couterReducer";
import logo from '../../Images/dcareer-white.png'
import CryptoJS from "crypto-js";

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appContext = useAppContext();
  let history = useNavigate();
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"+-@$!%*?&#_+=,._0-9]+\.)+[^<>()[\]\.,;:\s@\"]{2,3})$/i
  );
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const { email, password } = user;
  const [emailError, setEmailError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const [commonError, setCommonError] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [errorResponse,setErrorResponse] = useState(null);
  useEffect(() => {
    setErrorResponse(null)
    window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
      });
    console.log(localStorage.getItem("session"))
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    if(localStorage.getItem("session") === "true"){
      console.log(localStorage.getItem("session"))
      setErrorResponse(t("session_out"))
      }
      if (window.location.pathname === "/login") {
        if(!appContext.isLoggedin()){
          localStorage.removeItem("session");

        }else {
            window.location.replace("/user/1")
        }
    }
   // appContext.logout();
   
  }, []);
  const onInputChange = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    setUser({ ...user, [e.target.name]: e.target.value.trimStart() });
    if (e.target.name === "email") {
      setEmailError(null);
      if (e.target.value === "") {
        setEmailError(t("login.email_error"));
        return;
      } else if (!validEmailRegex.test(e.target.value)) {
        setEmailError(t("login.valid_email"));
        return;
      }
    } else if (e.target.name === "password") {
      setPasswordError(null);
      if (e.target.value === "") {
        setPasswordError(t("login.password_error"));
        return;
      }
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setCommonError(null);
    setEmailError(null);
    setPasswordError(null);
    setErrorResponse(null)
  
    if (user.email === "") {
      setEmailError(t("login.email_error"));
    }
    if (user.password === "") {
      setPasswordError(t("login.password_error"));
    }
    if (user.email !== "" && !validEmailRegex.test(user.email)) {
      setEmailError(t("login.valid_email"));
    }

    if (
      user.email !== "" &&
      validEmailRegex.test(user.email) &&
      user.password !== ""
    ) {
      let passwordEncryptedData = encryptData(user.password)
      setOpen(true);

      appContext.signIn(user.email, passwordEncryptedData).then(
        (response) => {
          if (response.status === false) {
            if (
              response?.info?.response?.data?.errorCode === "21203" ||
              response?.info?.response?.data?.errorCode === "21204"
            ) {
              setErrorResponse(t("login.invalid_credationals"))
            }else if (
              response?.info?.response?.data?.errorCode === "31101"
            ) {
              setErrorResponse(t("username_password_not_match"))
            }
            else if (
              response?.info?.response?.data?.errorCode === "31102"
            ) {

              setErrorResponse(t("user_notfound"))
            }
             else {
              setErrorResponse(t("login.invalid_credationals"))
            }
            setTimeout(() => {
              setOpen(false);
            }, 100);
          } else {
            dispatch(actions.setSearchCompanyfield(""));
            dispatch(actions.setPageCompanyCount(1));
            dispatch(actions.setPageUserCount(1));
            setOpen(false);
            history("/user/1");
            localStorage.setItem("INDEX",1);
          }
        },
        (error) => {
          setOpen(false);
        }
      );
    }
    
  };
  function encryptData(password){
    const secretPass = "XkhZG4fW2t2W";
    
    // const data = CryptoJS.AES.encrypt(
    //   JSON.stringify(password),
    //   secretPass
    // ).toString();
    // return data;
    return CryptoJS.AES.encrypt(password, secretPass).toString();
  }
  return (
    <div className="justify-content-center login-form">
      <div className="login-banner m-auto d-flex">
        <form className="form-signin" onSubmit={(e) => onSubmit(e)}>
          <div className="text-center pb-5">
            <img src={logo} alt="" />
          </div>
          <h1 className="text-center text-white pb-4">{t("login.heading")}</h1>
          {/* {commonError ? <div className="error">{commonError}</div> : ""} */}
          <div className="form-floating custom-form pb-5">
            <input
              type="text"
              id="floatingInput"
              placeholder="name@example.com"
              value={email}
              maxLength="50"
              name="email"
              onChange={(e) => onInputChange(e)}
              className={`form-control custom-input custom-input-changeJa ${
                emailError ? "is-invalid" : ""
              }`}
            />

            {emailError ? <div  className="c-validation" >{emailError}</div> : ''}
            <label htmlFor="floatingInput ">{t("login.email")}</label>
          </div>
          <div className="form-floating custom-form pb-5">
            <input
              type="password"
              id="floatingPassword"
              placeholder="Password"
              value={password}
              name="password"
              className={`form-control custom-input custom-input-changeJa ${
                passwordError ? "is-invalid" : ""
              }`}
              onChange={(e) => onInputChange(e)}
            />
            {passwordError ? (
              <div className="c-validation ">{passwordError}</div>
            ) : (
              ""
            )}

            <label htmlFor="floatingPassword">{t("login.password")}</label>
          </div>

          <div className="text-center">
            <button className="login-btn" type="submit">
              {t("login.heading")}
            </button>
          </div>
          {errorResponse !== null ? (   <div className="error-login d-flex">
              <div className="login-icon"><i class="fas fa-exclamation"></i>

               </div>
              <div className="login-error-res">{errorResponse}</div>
            </div>) : (<></>)}
         
        </form>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="bottom-right"
        autoClose={500}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default Login;
