import React, { useEffect } from 'react';
import logo from '../../Images/404-01.png'
import { useNavigate } from "react-router-dom";
import useAppContext from '../../AppContext';
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
function Error() {
  const appContext = useAppContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
  }, []);
  function back(){
   
     if (!appContext.isLoggedin()) {
      navigate("/login")
    }
    if (appContext.isLoggedin()) {
      navigate("/user/1");
    }
  }
  return(
   <body class="justify-content-center error-middle">

  
    <div class="error-page m-auto d-flex">
      <form class="error-page-wd">

        <div class="text-center pb-5">    
            <h1 class="er-text ">{t('errorPage.404')}</h1>
            <p class="pt-3">{t('errorPage.notFound')}</p>
        </div>
          
        
        <div class="text-center pb-5 pt-3"><img src={logo} alt="" class="img-fluid" /></div>


        <div class="col-md-12 text-center my-5">
            <button class="main-buttons sub-btn" onClick={back}>{t('errorPage.button')}</button>
        </div>
  
      </form>
    </div>
  
  
  
  </body>
  );
}
 

export default Error;
