import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "counter",
  initialState: {
    contents: 1,
    freeText:"",
    page: 1
  },
  reducers: {
    setPageCompanyCount: (state, action) => {
      state.contents = action.payload;
    },
    setSearchCompanyfield: (state, action) => {
      state.freeText = action.payload;
    },
    setPageUserCount: (state, action) => {
      state.page = action.payload;
    }
  }
});

export const { reducer, actions } = slice;

export const selectCount = (state) => state.counter.value;
