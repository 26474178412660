import styles from './home.module.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core";
import "bootstrap";
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link, Outlet,useNavigate } from 'react-router-dom';
import i18n from '../../i18n';
import Footer from '../footer/footer';
import useAppContext from '../../AppContext';
import { useDispatch } from "react-redux";
import { actions } from "../../redux/couterReducer";
import logo from '../../Images/dcareer-white.png'
const Home = () => {
const appContext = useAppContext();
let history = useNavigate()
const dispatch = useDispatch();
  const { t } = useTranslation();
  const [language, setLanguage] = useState();
  const [index, setIndex] = useState(1);
  let isLogin = localStorage.getItem("userId");
  const [errorResponse,setErrorResponse] = useState(null);
  const [successResponse,setSuccessResponse] = useState(null);
  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    if(localStorage.getItem("INDEX")){
        setIndex(parseInt(localStorage.getItem("INDEX")));
    }else{
        setIndex(1);
        localStorage.setItem("INDEX",1);
    }
    console.log(isLogin);
    console.log(language)
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    if(!appContext.isLoggedin()){
        history('/login');
    }

    if (window.location.pathname === "/") {
        if(!appContext.isLoggedin()){
        window.location.replace("/login")
        }else {
            window.location.replace("/user/1")
        }
    }
    if (localStorage.getItem("LANGUAGE")) {
      setLanguage(localStorage.getItem("LANGUAGE"))
    }
    else {
      setLanguage("ja");
      i18n.changeLanguage("ja");
      setLanguage(localStorage.setItem("LANGUAGE","ja"))
    }
    pathCheck();
  }, []);
  useEffect(() => {
    setSuccessResponse(appContext.getlocalSuccessMessage())
    setErrorResponse(appContext.getlocalErrorMessage())
  }, [appContext.getlocalSuccessMessage(),appContext.getlocalErrorMessage()]);
  const onBackButtonEvent = (e) => {
    pathCheck();
    e.preventDefault();
    document.querySelector('.modal-backdrop').remove();
    document.body.style.overflow = 'auto';
  }
  function pathCheck(){
    let url = window.location.pathname.split("/");
    if(url[1] === "user"){
        setIndex(1);
        localStorage.setItem("INDEX",1);
    }else if(url[1] === "company"){
        setIndex(2);
        localStorage.setItem("INDEX",2);
    }else if(url[1] === "job"){
        setIndex(3);
        localStorage.setItem("INDEX",3);
    }else if(url[1] === "job-category"){
        setIndex(4);
        localStorage.setItem("INDEX",4);
    }else if(url[1] === "job-sub-category"){
        setIndex(5);
        localStorage.setItem("INDEX",5);
    }else if(url[1] === "industry"){
        setIndex(6);
        localStorage.setItem("INDEX",6);
    }else if(url[1] === "employment"){
        setIndex(7);
        localStorage.setItem("INDEX",7);
    }else if(url[1] === "high-level-location"){
        setIndex(8);
        localStorage.setItem("INDEX",8);
    }else if(url[1] === "prefecture"){
        setIndex(9);
        localStorage.setItem("INDEX",9);
    }else if(url[1] === "salary-range"){
        setIndex(10);
        localStorage.setItem("INDEX",10);
    }else if(url[1] === "tag"){
        setIndex(11);
        localStorage.setItem("INDEX",11);
    }else if(url[1] === "skill"){
        setIndex(12);
        localStorage.setItem("INDEX",12);
    }else if(url[1] === "hot-job"){
        setIndex(13);
        localStorage.setItem("INDEX",13);
    }else if(url[1] === "popular-company"){
        setIndex(14);
        localStorage.setItem("INDEX",14);
    }else if(url[1] === "blog"){
        setIndex(15);
        localStorage.setItem("INDEX",15);
    }else if(url[1] === "pickup-category"){
        setIndex(16);
        localStorage.setItem("INDEX",16);
    }           
  }
  function handleOnClickJobCategory(){
    setIndex(4);
    localStorage.setItem("INDEX",4);
    history("/job-category/1");
    clearData();
    document.querySelector('.modal-backdrop').remove();
    document.body.style.overflow = 'auto';
  }
  function handleOnClickJobCategoryDropDown(e){
    e.stopPropagation();
  }
  function handleOnClickSubCategory(e){
    setIndex(5);
    localStorage.setItem("INDEX",5);
    history("/job-sub-category/1")
    clearData();
    document.querySelector('.modal-backdrop').remove();
    document.body.style.overflow = 'auto';
    e.stopPropagation();
  }
  function handleOnClickPrefecture(e){
    setIndex(9);
    localStorage.setItem("INDEX",9);
    history("/prefecture/1");
    clearData();
    document.querySelector('.modal-backdrop').remove();
    document.body.style.overflow = 'auto';
    e.stopPropagation();
  }
  function handleOnClickLocationDropDown(e){
    e.stopPropagation();
  }
  function handleOnClickLocation(){
    setIndex(8);
    localStorage.setItem("INDEX",8);
    history("/high-level-location/1");
    clearData();
    document.querySelector('.modal-backdrop')?.remove();
    document.body.style.overflow = 'auto';
  }
  function handleOnClickUser(){
    setIndex(1);
    localStorage.setItem("INDEX",1);
    history("/user/1");
    dispatch(actions.setSearchCompanyfield(""));
    dispatch(actions.setPageCompanyCount(1));
    document.querySelector('.modal-backdrop')?.remove();
    document.body.style.overflow = 'auto';
    console.log(index)
  }
  function handleOnClickCompany(){
    setIndex(2);
    localStorage.setItem("INDEX",2);
    history("/company/1");
    dispatch(actions.setPageUserCount(1));
    document.querySelector('.modal-backdrop')?.remove();
    document.body.style.overflow = 'auto';
    console.log(index)
  }
  function handleOnClickJob(){
    setIndex(3);
    localStorage.setItem("INDEX",3);
    history("/job/1");
    clearData();
    document.querySelector('.modal-backdrop')?.remove();
    document.body.style.overflow = 'auto';
    console.log(index)
  }
  function handleOnClickIndustry(){
    setIndex(6);
    localStorage.setItem("INDEX",6);
    history("/industry/1");
    clearData();
    document.querySelector('.modal-backdrop')?.remove();
    document.body.style.overflow = 'auto';
  }
  function handleOnClickEmployment(){
    setIndex(7);
    localStorage.setItem("INDEX",7);
    history("/employment/1");
    clearData();
    document.querySelector('.modal-backdrop')?.remove();
    document.body.style.overflow = 'auto';
  }
  function handleOnClickSalary(){
    setIndex(10);
    localStorage.setItem("INDEX",10);
    history("/salary-range/1");
    clearData();
    document.querySelector('.modal-backdrop')?.remove();
    document.body.style.overflow = 'auto';
  }
  function handleOnClickTag(){
    setIndex(11);
    localStorage.setItem("INDEX",11);
    history("/tag/1");
    clearData();
    document.querySelector('.modal-backdrop')?.remove();
    document.body.style.overflow = 'auto';
  }
  function handleOnClickSkill(){
    setIndex(12);
    localStorage.setItem("INDEX",12);
    history("/skill/1");
    clearData();
    document.querySelector('.modal-backdrop')?.remove();
    document.body.style.overflow = 'auto';
  }
  function handleOnClickHotJob(){
    setIndex(13);
    localStorage.setItem("INDEX",13);
    history("/hot-job");
    clearData();
    document.querySelector('.modal-backdrop')?.remove();
    document.body.style.overflow = 'auto';
  }
  function handleOnClickPopularCompany(){
    setIndex(14);
    localStorage.setItem("INDEX",14);
    history("/popular-company");
    clearData();
    document.querySelector('.modal-backdrop')?.remove();
    document.body.style.overflow = 'auto';
  }
  function handleOnClickBlog(){
    setIndex(15);
    localStorage.setItem("INDEX",15);
    history("/blog/1");
    clearData();
    document.querySelector('.modal-backdrop')?.remove();
    document.body.style.overflow = 'auto';
  }
  function handleOnClickPickupCategory(){
    setIndex(16);
    localStorage.setItem("INDEX",16);
    history("/pickup-category");
    clearData();
    document.querySelector('.modal-backdrop')?.remove();
    document.body.style.overflow = 'auto';
  }
  function logout(){
    appContext.logout();
    localStorage.removeItem("INDEX");
    localStorage.removeItem("session");
    history('/login')
    }
function reditectToUser(){
    history('/user/1')
    window.localStorage.reload();
    clearData();
    setIndex(1);
    localStorage.setItem("INDEX",1);
    document.querySelector('.modal-backdrop')?.remove();
    document.body.style.overflow = 'auto';
}
function clearData(){
    dispatch(actions.setSearchCompanyfield(""));
    dispatch(actions.setPageCompanyCount(1));
    dispatch(actions.setPageUserCount(1));
}
  return(
  <div className={styles.Home} data-testid="Home">
   <body class="h-100">

<div className="container-fluid">
    <div className="row flex-nowrap">
        <div className="col-auto col-md-3 col-xl-2  px-0 hide-mobile position-relative">
        <div class="side-bg position-fixed sub-side">
            <div className="d-flex flex-column  px-3 min-vh-100">
                <div className="text-center py-3"><Link onClick={reditectToUser}> <img src={logo} alt="" width="176"/></Link></div>
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
                    id="menu" style={{"textAlign":"left"}}>
                    <li className="nav-item nav-bottom" >
                        <span onClick={handleOnClickUser} className="nav-link nav-side align-middle px-0 cursor" id="user" 
                        style={ index ===1 ? { "fontWeight":"bolder","color":"white"} : {}}>
                            <i className="fa-solid fa-user"></i> <span className="d-none d-sm-inline">{t('user_management')}</span>
                        </span>
                    </li>

                    <li className="nav-item nav-bottom">
                        <span  onClick={handleOnClickCompany} className="nav-link nav-side align-middle px-0 cursor" 
                         style={ index ===2 ? { "fontWeight":"bolder","color":"white"} : {}}>
                            <i className="fa-regular fa-building"></i> <span className="d-none d-sm-inline">{t('company_management')}</span>
                        </span>
                    </li>

                    <li className="nav-item nav-bottom">
                        <span  onClick={handleOnClickJob} className="nav-link nav-side align-middle px-0 cursor" 
                         style={ index ===3 ? { "fontWeight":"bolder","color":"white"} : {}}>
                            <i className="fa-solid fa-briefcase"></i> <span className="d-none d-sm-inline">{t('job_management')}</span>
                        </span>
                    </li>

                    <li className="nav-item nav-bottom">
                        
                        <a className="nav-link align-middle nav-side px-0 " data-bs-toggle="collapse"
                            href="#collapseExample" role="button"  onClick={handleOnClickJobCategory}  style={ index ===4 ? { "fontWeight":"bolder","color":"white"} : {}}
                            aria-controls="collapseExample">
                            <i className="fa-solid fa-list-alt" style={{fontSize:"17px"}}></i><span className="d-none d-sm-inline">{t('job_category')}</span>
                             <span className="float-end " id="categorySpan" 
                             onClick={handleOnClickJobCategoryDropDown} ><img src={require("../../Images/chevron-left-solid.png")} alt=""/></span>
                        </a>
                        
                    </li>
                    
                    <div className="collapse w-100" id="collapseExample">
                        <div className="card card-body card-bg">
                            <ul className="nav nav-pills flex-column">
                                <li className="nav-item nav-bottom">
                                    <span style={ index ===5 ? { "fontWeight":"bolder","color":"white"} : {}}
                                    className="nav-link align-middle nav-side px-0 cursor" onClick={handleOnClickSubCategory}>
                                        <i className="fa-solid fa-list"  style={{fontSize:"17px"}}></i> <span className="d-none d-sm-inline">{t('job_sub_category')}</span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <li className="nav-item nav-bottom">
                        <span onClick={handleOnClickIndustry} className="nav-link align-middle px-0 nav-side cursor" 
                        style={ index ===6 ? { "fontWeight":"bolder","color":"white"} : {}}>
                            <i className="fa-solid fa-suitcase"></i> <span
                                className="d-none d-sm-inline">{t('industry')}</span>
                        </span>
                    </li>

                    <li className="nav-item nav-bottom">
                        <span onClick={handleOnClickEmployment} className="nav-link align-middle px-0 nav-side cursor" 
                        style={ index ===7 ? { "fontWeight":"bolder","color":"white"} : {}}>
                            <i className="fa-solid fa-industry"></i> <span className="d-none d-sm-inline">{t('employment_type')}</span>
                        </span>
                    </li>

                    <li className="nav-item nav-bottom">
                        <a className="nav-link align-middle px-0 nav-side" data-bs-toggle="collapse"
                            href="#collapseExample2" role="button" aria-expanded="false" onClick={handleOnClickLocation}  
                            style={ index ===8 ? { "fontWeight":"bolder","color":"white"} : {}}
                            aria-controls="collapseExample">
                            <i className="fa-solid fa-location-dot"></i> 
                            <span className="d-none d-sm-inline">{t('high_level_location')}</span>
                             <span className="float-end" id="locationSpan"
                              onClick={handleOnClickLocationDropDown}><img src={require("../../Images/chevron-left-solid.png")} alt=""/></span>
                        </a>
                    </li>
                    <div className="collapse w-100" id="collapseExample2">
                        <div className="card card-body card-bg">
                            <ul className="nav nav-pills flex-column">
                                <li className="nav-item nav-bottom">
                                <span  style={ index ===9 ? { "fontWeight":"bolder","color":"white"} : {}}
                                  href="/prefecture/1" className="nav-link align-middle px-0 nav-side cursor" onClick={handleOnClickPrefecture}>
                                        <i className="fa-solid fa-map-location-dot"></i> <span
                                            className="d-none d-sm-inline">{t('prefectures')}</span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <li className="nav-item nav-bottom">
                    <span onClick={handleOnClickSalary} className="nav-link align-middle px-0 nav-side cursor"
                     style={ index ===10 ? { "fontWeight":"bolder","color":"white"} : {}}>
                            <i className="fa-solid fa-yen-sign"></i> <span className="d-none d-sm-inline">{t('salary_ranges')}</span>
                        </span>
                    </li> */}

                    <li className="nav-item nav-bottom">
                        <span onClick={handleOnClickTag} className="nav-link align-middle px-0 nav-side cursor" 
                         style={ index ===11 ? { "fontWeight":"bolder","color":"white"} : {}}>
                            <i className="fa-solid fa-tags"></i> <span className="d-none d-sm-inline">{t('tag_management')}</span>
                        </span>
                    </li>

                    <li className="nav-item nav-bottom">
                        <span onClick={handleOnClickSkill} className="nav-link align-middle px-0 nav-side cursor" 
                         style={ index ===12 ? { "fontWeight":"bolder","color":"white"} : {}}>
                            <i className="fas fa-award"></i> <span className="d-none d-sm-inline">{t('skill_management')}</span>
                        </span>
                    </li>
                    <li className="nav-item nav-bottom">
                        <span onClick={handleOnClickHotJob} className="nav-link align-middle px-0 nav-side cursor" 
                         style={ index ===13 ? { "fontWeight":"bolder","color":"white"} : {}}>
                            <i className="fa-brands fa-hotjar"></i> <span className="d-none d-sm-inline">{t('hot_jobs')}</span>
                        </span>
                    </li>
{/* 
                    <li className="nav-item nav-bottom">
                        <span onClick={handleOnClickPopularCompany} className="nav-link align-middle px-0 nav-side cursor" 
                         style={ index ===14 ? { "fontWeight":"bolder","color":"white"} : {}}>
                            <i className="fa-solid fa-building"></i> <span className="d-none d-sm-inline">{t('popular_companies')}</span>
                        </span>
                    </li> */}

                    <li className="nav-item nav-bottom">
                        <span onClick={handleOnClickBlog} className="nav-link align-middle px-0 nav-side cursor" 
                         style={ index ===15 ? { "fontWeight":"bolder","color":"white"} : {}}>
                          <i className="fa-solid fa-pen-nib"></i> <span className=" d-none d-sm-inline">{t('blogs')}</span>
                        </span>
                    </li>

                    <li className="nav-item nav-bottom">
                        <span onClick={handleOnClickPickupCategory} className="nav-link align-middle px-0 nav-side cursor" 
                         style={ index ===16 ? { "fontWeight":"bolder","color":"white"} : {}}>
                            <i className="fa-solid fa-list"></i> <span className=" d-none d-sm-inline">{t('pickup_categories')}</span>
                        </span>
                    </li>




                </ul>

            </div>
        </div>
        </div>

        <div className="offcanvas offcanvas-start side-bg off-scroll side-width hide-web" tabindex="-1"
            id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <button type="button" className="Close-btn" data-bs-dismiss="offcanvas" aria-label="Close"> <i
                    className="fa-solid fa-xmark"></i></button>
            <div className="text-center py-4"><Link onClick={reditectToUser}><img src={require("../../Images/logo-white.png")} alt="" width="176"/></Link></div>
            <div className="offcanvas-body">
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
                    id="menu">
                    <li className="nav-item nav-bottom">
                        <a href="/user/1" className="nav-link nav-side align-middle px-0">
                            <i className="fa-solid fa-user"></i> <span >{t('user_management')}</span>
                        </a>
                    </li>

                    <li className="nav-item nav-bottom">
                        <a href="/company/1" className="nav-link nav-side align-middle px-0">
                            <i className="fa-regular fa-building"></i> <span >{t('company_management')}</span>
                        </a>
                    </li>

                    <li className="nav-item nav-bottom">
                        <a href="/job/1" className="nav-link nav-side align-middle px-0">
                            <i className="fa-solid fa-briefcase"></i> <span >{t('job_management')}</span>
                        </a>
                    </li>

                     <li className="nav-item nav-bottom">
                        <a className="nav-link align-middle nav-side px-0 " data-bs-toggle="collapse"
                            href="#collapseExample" role="button" aria-expanded="false" onClick={handleOnClickJobCategory}
                            aria-controls="collapseExample">
                            <i className="fa-solid fa-list-alt"></i><span >{t('job_category')}</span> <span
                                className="float-end "><img src={require("../../Images/chevron-left-solid.png")} alt=""/></span>
                        </a>
                    </li> 
                
                    <div className="collapse w-100" id="collapseExample">
                        <div className="card card-body card-bg">
                            <ul className="nav nav-pills flex-column">
                                <li className="nav-item nav-bottom">
                                    <a href="/job-sub-category/1" className="nav-link align-middle nav-side px-0">
                                        <i className="fa-solid fa-list"></i> <span >{t('job_sub_category')}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <li className="nav-item nav-bottom">
                        <a href="/industry/1" className="nav-link align-middle px-0 nav-side">
                            <i className="fa-solid fa-suitcase"></i> <span >{t('industry')}</span>
                        </a>
                    </li>

                    <li className="nav-item nav-bottom">
                        <a href="/employment/1" className="nav-link align-middle px-0 nav-side">
                            <i className="fa-solid fa-industry"></i> <span >{t('employment_type')}</span>
                        </a>
                    </li>

                    <li className="nav-item nav-bottom">
                        <a className="nav-link align-middle px-0 nav-side" data-bs-toggle="collapse"
                            href="#collapseExample2" role="button" aria-expanded="false" onClick={handleOnClickLocation}
                            aria-controls="collapseExample">
                            <i className="fa-solid fa-location-dot"></i> <span >{t('high_level_location')}</span>
                            <span className="float-end"><img src={require("../../Images/chevron-left-solid.png")} alt=""/></span>
                        </a>
                    </li>
                    <div className="collapse w-100" id="collapseExample2">
                        <div className="card card-body card-bg">
                            <ul className="nav nav-pills flex-column">
                                <li className="nav-item nav-bottom">
                                    <a href="/prefecture/1" className="nav-link align-middle px-0 nav-side">
                                        <i className="fa-solid fa-map-location-dot"></i> <span
                                           >{t('prefectures')}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <li className="nav-item nav-bottom">
                        <a href="/salary-range/1" className="nav-link align-middle px-0 nav-side">
                            <i className="fa-solid fa-yen-sign"></i> <span>{t('salary_ranges')}</span>
                        </a>
                    </li> */}

                    <li className="nav-item nav-bottom">
                        <a href="/tag/1" className="nav-link align-middle px-0 nav-side">
                            <i className="fa-solid fa-tags"></i> <span >{t('tag_management')}</span>
                        </a>
                    </li>


                    <li className="nav-item nav-bottom">
                        <a href="/skill/1" className="nav-link align-middle px-0 nav-side">
                            <i className="fas fa-award"></i> <span >{t('skill_management')}</span>
                        </a>
                    </li>

                    <li className="nav-item nav-bottom">
                        <a href="/hot-job" className="nav-link align-middle px-0 nav-side">
                            <i className="fa-brands fa-hotjar"></i> <span>{t('hot_jobs')}</span>
                        </a>
                    </li>

                    {/* <li className="nav-item nav-bottom">
                        <a href="/popular-company" className="nav-link align-middle px-0 nav-side">
                            <i className="fa-solid fa-building"></i> <span >{t('popular_companies')}</span>
                        </a>
                    </li> */}

                    <li className="nav-item nav-bottom">
                        <a href="/blog/1" className="nav-link align-middle px-0 nav-side">
                            <i className="fa-solid fa-pen-nib"></i> <span>{t('blogs')}</span>
                        </a>
                    </li>

                    <li className="nav-item nav-bottom">
                        <a href="/pickup-category" className="nav-link align-middle px-0 nav-side">
                            <i className="fa-solid fa-list"></i> <span>{t('pickup_categories')}</span>
                        </a>
                    </li>


                    <li className="nav-item nav-bottom">
                        <a href="/change-password" className="nav-link align-middle px-0 nav-side">
                            <i className="fa-solid fa-key"></i> <span >{t('change_password')}</span>
                        </a>
                    </li>


                    <li className="nav-item nav-bottom">
                        <a href="#" className="nav-link align-middle px-0 nav-side"  data-bs-toggle="modal"
                          data-bs-target="#logout2">
                            <i className="fa-solid fa-arrow-right-from-bracket"></i> <span >{t('logout')}</span>
                        </a>
                    </li>




                </ul>

            </div>
        </div>
        <div class="col-10 col-custom10 p-0 middle-area position-relative">
            <div>
                <nav className="navbar navbar-expand-lg header-bg fixed-top">
                    <div className="container-fluid padding-middle">
                        <a className="navbar-brand " href="#"><img src={require("../../Images/logo.png")} className="head-logo" alt=""
                                width="176"/></a>

                       {successResponse !== null ? (<div className="validation-success">{successResponse}</div>) :(<div  className="validation">{errorResponse}</div>)} 
                        
                        <div className="flex-shrink-0 ">
                            <button className="navbar-toggler hide-toogle" type="button" data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                <i className="fa-solid fa-bars-staggered"></i>
                            </button>
                            <div className="dropdown user-hide">
                                <a href="#" className="d-block link-dark text-decoration-none" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <i className="fa-solid fa-circle-user user-icon"></i>
                                </a>

                                <ul className="dropdown-menu drop-menu profile-nav" >
                                    <div className="drop-arrow"> <i className="fa-solid fa-sort-up"></i></div>

                                    <li className="nav-item">
                                        <a href="/change-password" className="nav-link align-middle px-0 nav-drop">
                                            <i className="fa-solid fa-key"></i> <span >{t('change_password')}</span>
                                        </a>
                                    </li>
                                    <hr/>

                                    <li className="nav-item">
                                        <a   data-bs-toggle="modal"
                          data-bs-target="#logout2" className="nav-link align-middle px-0 nav-drop">
                                            <i className="fa-solid fa-arrow-right-from-bracket"></i> <span
                                              >{t('logout')}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </nav>
            </div>
            <div id="page-container">
            <div id="content-wrap">
            <div className="padding-middle middle-top">
          
              <Outlet></Outlet>

            </div>
            </div>
            </div>
            <div class="clearfix"></div>

            <Footer></Footer>

        </div>
    </div>


    
</div>




<div className="modal fade" id="logout2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">

                    <div className="modal-body px-5 py-3">
                        <div className="row mb-2 mt-5 px-3">
                            <div className="col-md-12 text-center">
                                <i className="fa-solid fa-arrow-right-from-bracket delete-pop"></i>
                            </div>

                         <div className="text-center mt-4 mb-4">
                                <h5>{t("are_u_sure")}</h5>
                            </div> 

                            <div className="text-center" >
                                <p>{t("logout_description")}</p>
                            </div>


                        </div>
                        <div className="col-md-12 text-center mb-3">
                            <button className="cancel-btn" data-bs-dismiss="modal">{t("cancel")}</button>
                            <button className="main-buttons sub-btn" data-bs-dismiss="modal" onClick={logout}>{t("logout")}</button>
                        </div>
                    </div>
                    {/* <div className="modal-footer border-0">
                       
                    </div> */}
                </div>
            </div>
        </div>
    <script src="js/bootstrap.bundle.js"></script>
   
</body>
  </div>
)};

export default Home;
