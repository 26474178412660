import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { useLocation, Link, useNavigate} from 'react-router-dom';
import useAppContext from '../../AppContext';
import styles from './create-employment.module.css';
import { Spinner } from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 

const CreateEmployment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const appContext = useAppContext();
  const Axios = appContext.getAxios();
  const search = useLocation().search;
  const [employmentId, setEmploymentId] = useState("");
  const [inputs, setInputs] = useState({});
  const [clickSubmit, setClickSubmit] = useState(false);
  const [response, setResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorCode, setErrorCode] =  useState();
  const page = location?.state?.page;

  useEffect(() => {
    window. scrollTo(0, 0);
    appContext.setError(null);
    appContext.setSuccess(null)
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    if (new URLSearchParams(search).get('id')) {
    getEmploymentType(parseInt(new URLSearchParams(search).get('id')));
    }
    enterKeyEvent();
    // eslint-disable-next-line 
  }, []);
  function getEmploymentType(id) {
    setOpen(true);
    Axios.get("employmentType/"+id).then((response) => {
      if (response.status === 200) {
        setOpen(false);
        console.log(response)
        setResponse(true);
        setEmploymentId(response?.data?.employmentTypeId)
        setInputs(values => ({ ...values, "employmentType": response?.data?.employmentTypeName}));
      }
    }).catch(error => {
      setOpen(false);
      console.log('There was an error!', error);
      setResponse(false);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "35203"){
        if(page !== undefined){
          navigate("/employment/"+page);
        }else{
          navigate("/employment/1");
        }
     }
    });
  }
  function submit(e){
    e.preventDefault();
    setClickSubmit(true);
    if (inputs.employmentType === "" || !inputs.employmentType) {
    return;
  } else {
    if(employmentId){
      editEmployment()
    }else{
      addEmployment();
    }
  }
  }
  const handleChange = (event) => {
    const value = event.target?.value;
    const name = event.target?.name;
    setInputs(values => ({ ...values, [name]: value }));
    console.log(inputs)
  }
  function addEmployment() {
    setOpen(true);
    setIsLoading(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    const body = {"employmentType":inputs.employmentType.trim()}
    Axios.post("employmentType",body).then((response) => {;
      setIsLoading(false);
      if (response.status === 200) {
        setResponse(true);
        setTimeout(() => {
          navigate("/employment/1");
          setOpen(false);
       }, 200);
      }
    }).catch(error => {
      console.log('There was an error!', error);
      setIsLoading(false);
      setResponse(false);
      setOpen(false);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "35202"){
        appContext.setError(t("employment.employment_exist"))
      }else
      document.getElementById("successPopUpButton").click();
    });
  }
  function editEmployment() {
    setOpen(true);
    setIsLoading(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    const body = {"employmentType":inputs.employmentType.trim()}
    Axios.put("employmentType/"+employmentId,body).then((response) => {;
      setIsLoading(false);
      if (response.status === 200) {
        setResponse(true);
        setTimeout(() => {
          navigate("/employment/"+page);
          setOpen(false);
       }, 200);
      }
    }).catch(error => {
      console.log('There was an error!', error);
      setIsLoading(false);
      setResponse(false);
      setOpen(false);
      setErrorCode(error.response?.data?.errorCode);
      document.getElementById("successPopUpButton").click();
    });
  }
  function enterKeyEvent(){
    let el = document.getElementById("myInputID");
    el.addEventListener("keypress", function(event) {
    if (event.key === "Enter") {
      document.getElementById("saveBtn").click();
      event.preventDefault();
    }
   });
  }
  return(
  <div className={styles.CreateEmployment} data-testid="CreateEmployment">
   <div>
           <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-5">
          {employmentId && <h5>{t('employment.edit_employment')}</h5>}{ !employmentId && <h5>{t('employment.create_employment')}</h5>}
        </div>
        <div class="card create-card p-4 vh-100">
            <div class="card-body">
                <form>
                    <div class="row mb-3">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label for="name" class="form-label input-label">{t('employment.employment_title')}</label><span class="required">*</span>
                          <input type="text" class="form-control custom-input2" id="myInputID" aria-describedby="emailHelp" placeholder={t('employment.employment_title')}
                          maxLength={100} name="employmentType"  value={inputs.employmentType} onChange={handleChange}/>
                        </div>          
                        {(clickSubmit && !inputs.employmentType) && <div className="error">{t('employment.employment_required')}</div> }
                      </div>                   
                    </div>           
                    <div class="col-md-12 text-end my-5">
                     {!isLoading && <div>
                  {page && <Link to={"/employment/"+page}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link>}
                  {!page && <Link to={"/employment/1"}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link>  }
                  <button class="main-buttons sub-btn" id="saveBtn" onClick={(e) => submit(e)}>{employmentId ? t('update') : t('save')}</button>
                </div> }
                {isLoading === true && <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px" }}></Spinner>}
                     </div>              
                  </form>
            </div>
          </div>
        </div>

                  {/*SUCCESS POPUP */}
      <a href="" class="edit-icon" data-bs-toggle="modal" id="successPopUpButton" data-bs-target="#successPopup" hidden></a>
 
 <div class="modal fade" id="successPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-confirm ">
                    <div class="modal-content">
                        <div class="modal-header justify-content-center">
                            <div class="icon-box">
                               {response === true &&  <i class="fa fa-check" aria-hidden="true"></i> }
                               {response === false && <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> }
                            </div>
                            <button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                        </div>
                        <div class="modal-body text-center">
                            {response === true ? <h4>{t('success')}</h4> : <h4>{t('error')}</h4>}
                            {response === true && !employmentId && <p>{t('employment.employment_created')}</p> }
                            {response === true && employmentId && <p>{t('employment.employment_updated')}</p> }
                            {response === false && errorCode === "35202" && <p>{t('employment.employment_exist')}</p> }
                            {response === false && errorCode === "35203" && <p>{t('employment.employment_not_found')}</p> }
                            {response === true?  <Link to={"/employment/"+page}> <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button> </Link>
                             : <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button>}
                        </div>
                    </div>
                </div>
            </div>
            <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
  </div>
)}

export default CreateEmployment;
