import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import useAppContext from '../../AppContext';
import styles from './create-job-sub-category.module.css';
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateJobSubCategory = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const appContext = useAppContext();
  const Axios = appContext.getAxios();
  const [subCategoryId, setSubCategoryId] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [inputs, setInputs] = useState({});
  const [clickSubmit, setClickSubmit] = useState(false);
  const [response, setResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] = useState();
  const [selectOptions, setSelectOptions] = useState();
  const [open, setOpen] = useState(false);
  let options = [];
  const page = location?.state?.page
  const freeWord = location?.state?.search;
  useEffect(() => {
    window.scrollTo(0, 0);
    appContext.setError(null);
    appContext.setSuccess(null)
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    getCategoryList();
    if (new URLSearchParams(search).get('id')) {
      getSubCategory(new URLSearchParams(search).get('id'));
    }
    enterKeyEvent();
    // eslint-disable-next-line 
  }, []);

  function getCategoryList() {
    Axios.get("category").then((response) => {
      setCategoryList(response?.data?.categoryList);
      options = response?.data?.categoryList.map(item => {
        return {
          label: String(item.categoryName),
          value: item.categoryId
        }
      })
      setSelectOptions(options);
      console.log(categoryList);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function submit(e) {
    e.preventDefault();
    setClickSubmit(true);
    if (inputs.subCategoryName === "" || !inputs.subCategoryName || !inputs.categoryId || inputs.categoryId === "") {
      return;
    } else {
      if (subCategoryId) {
        editCategory()
      } else {
        addCategory();
      }
    }
  }
  const handleChange = (event) => {
    const value = event.target?.value;
    const name = event.target?.name;
    setInputs(values => ({ ...values, [name]: value }));
    console.log(inputs)
  }
  const handleChangeSelect = (event) => {
    console.log(event.value)
    setInputs(values => ({ ...values, "categoryId": event.value }));
    console.log(inputs)
  }
  function getSubCategory(id) {
    setOpen(true);
    Axios.get("subCategory/"+id).then((response) => {;
      if (response.status === 200) {
        setOpen(false);
        console.log(response)
        setResponse(true);
        // setCategoryId(response?.data?.CategoryId)
        setSubCategoryId(response?.data?.subCategoryId);
        setInputs(values => ({ ...values, "subCategoryName": response?.data?.subCategoryName }));
        setInputs(values => ({ ...values, "categoryId": response?.data?.category?.categoryId}));
      }
    }).catch(error => {
      setOpen(false);
      console.log('There was an error!', error);
      setResponse(false);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "33301"){
        if(page !== undefined){
          navigate("/job-sub-category/"+page);
        }else{
          navigate("/job-sub-category/1");
        }
     }
    });
  }
  function addCategory() {
    setOpen(true);
    setIsLoading(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    const body = { "categoryId": inputs.categoryId, "subCategoryName": inputs.subCategoryName.trim() }
    Axios.post("subCategory", body).then((response) => {
      ;
      setIsLoading(false);
    
      if (response.status === 200) {
        setTimeout(() => {
          navigate("/job-sub-category/1");
          setOpen(false);
       }, 200);
        setResponse(true);
      }
    }).catch(error => {
      console.log('There was an error!', error);
      setIsLoading(false);
      setResponse(false);
      setOpen(false);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "33202"){
        appContext.setError(t("sub_category.sub_category_exist"))
      }else if(error.response?.data?.errorCode === "33301"){
        appContext.setError(t("category.category_not_found"))
      }else{
        document.getElementById("successPopUpButton").click();
      }
    
    });
  }
  function editCategory() {
    setOpen(true);
    setIsLoading(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    const body = { "categoryId": inputs.categoryId, "subCategoryName": inputs.subCategoryName.trim() }
    Axios.put("subCategory/" + subCategoryId, body).then((response) => {
      setIsLoading(false);
    
      if (response.status === 200) {
        setTimeout(() => {
          if(freeWord){
            navigate("/job-sub-category/"+page+"?search="+freeWord);
          }else{
            navigate("/job-sub-category/"+page);
          }          
          setOpen(false);
       }, 200);
        setResponse(true);
      }
    }).catch(error => {
      console.log('There was an error!', error);
      setIsLoading(false);
      setResponse(false);
      setOpen(false);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "33202"){
        appContext.setError(t("sub_category.sub_category_exist"))
      }else if(error.response?.data?.errorCode === "33301"){
        appContext.setError(t("category.category_not_found"))
      }else{
        document.getElementById("successPopUpButton").click();
      }
    });
  }
  function enterKeyEvent() {
    let el = document.getElementById("myInputID");
    el.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        document.getElementById("saveBtn").click();
        event.preventDefault();
      }
    });
  }
  return (
    <div className={styles.CreateJobSubCategory} data-testid="CreateJobSubCategory">
      <div>
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-5">
          {subCategoryId ? <h5>{t('sub_category.edit_sub_category')}</h5> : <h5>{t('sub_category.create_sub_category')}</h5>}
        </div>
        <div class="card create-card vh-100 p-4">
          <div class="card-body">
            <form>
              <div class="row mb-3">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('sub_category.sub_category_name')}</label><span class="required">*</span>
                    <input type="text" class="form-control custom-input2" id="myInputID" aria-describedby="emailHelp"
                      maxLength={100} name="subCategoryName" value={inputs.subCategoryName} onChange={handleChange} placeholder={t('sub_category.sub_category_name')} />
                  </div>
                  {(clickSubmit && !inputs.subCategoryName) ? <div className="error">{t('sub_category.sub_category_required')}</div> : null}
                </div>
              </div>

              <div class="row mb-3">
                <div class="mb-3">
                  <label for="name" class="form-label input-label">{t('category.category_name')}</label><span class="required">*</span>
                  {/* <select class="form-select custom-input2" aria-label="Default select example"
                    name="categoryId" value={inputs.categoryId} onChange={handleChange}>
                    <option disabled selected>{t('select_category')}</option>
                    {categoryList?.map((cat) => {
                      return (
                        <option value={cat?.categoryId}>{cat?.categoryName}</option>)
                    })}
                  </select> */}
                  <Select aria-label="Default select example" placeholder={t('select_category')} 
                  components={{IndicatorSeparator:() => null }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: '#CBCBCB',
                      boxShadow: 'none',
                      '&:hover': {
                          border: '1px solid #85b2f9',
                      },
                      height : "60px",
                      borderRadius:"6px",
                      }),
                      singleValue: (base, data) =>({

                        ...base,
                        color:data.value === 0 ? "#c7c7c7" : '#212529'
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color:'black',
                        backgroundColor: state.isSelected ? 'transparant' : 'inherit',
                        '&:hover': { backgroundColor: 'rgb(222, 235, 255)' }
                      }),                      
                  }}                 
                    name="categoryId" defaultValue={[{ label: location?.state?.categoryName ? location?.state?.categoryName : t('select_category'),
                      value:location?.state?.categoryId ? location?.state?.categoryId :  0 }]}
                     onChange={handleChangeSelect} options={selectOptions}
                    noOptionsMessage={() => t('no_results')} >

                  </Select>
                </div>
                {(clickSubmit && !inputs.categoryId) ? <div className="error">{t('sub_category.category_required')}</div> : null}
              </div>



              <div class="col-md-12 text-end my-5">
                {!isLoading ? <div>
                 {freeWord? <Link to={"/job-sub-category/" + page + "?search="+freeWord}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link>:
                  <Link to={"/job-sub-category/1"}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link>}
                  <button class="main-buttons sub-btn" id="saveBtn" onClick={(e) => submit(e)}>{subCategoryId ? t('update') : t('save')}</button>
                </div> : null}
                {isLoading === true ? <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px" }}></Spinner> : null}
              </div>

            </form>
          </div>
        </div>

      </div>
      {/*SUCCESS POPUP */}
      <a href="" class="edit-icon" data-bs-toggle="modal" id="successPopUpButton" data-bs-target="#successPopup" hidden></a>

      <div class="modal fade" id="successPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-confirm ">
          <div class="modal-content">
            <div class="modal-header justify-content-center">
              <div class="icon-box">
               <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              </div>
              <button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
            </div>
            <div class="modal-body text-center">
             <h4>{t('error')}</h4>
              {response === true && !subCategoryId && <p>{t('sub_category.sub_category_created')}</p>}
              {response === true && subCategoryId && <p>{t('sub_category.sub_category_updated')}</p>}
              {response === false && errorCode === "33201" && <p>{t('sub_category.sub_category_exist')}</p>}
              {response === false && errorCode === "33202" && <p>{t('sub_category.sub_category_exist')}</p>}
              {response === false && errorCode === "33301" && <p>{t('sub_category.sub_category_not_found')}</p>}
              {response === false && errorCode === "32401" && <p>{t('sub_category.invalid_category')}</p>}
              {response === true && <Link to={"/job-sub-category/" + page}> <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button> </Link>}
              {response === false && <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button>}
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </div>
  )
};

export default CreateJobSubCategory;
