import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { useLocation, Link , useNavigate} from 'react-router-dom';
import useAppContext from '../../AppContext';
import styles from './create-prefecture.module.css';
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreatePrefecture = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const appContext = useAppContext();
  const Axios = appContext.getAxios();
  const [prefectureId, setPrefectureId] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [inputs, setInputs] = useState({});
  const [clickSubmit, setClickSubmit] = useState(false);
  const [response, setResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] = useState();
  const [selectOptions,setSelectOptions] = useState();
  const [open, setOpen] = useState(false);
  let options =[];
  const page = location?.state?.page
  const freeWord = location?.state?.search;
  useEffect(() => {
    window. scrollTo(0, 0);
    appContext.setError(null);
    appContext.setSuccess(null)
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    getLocationList();
    if (new URLSearchParams(search).get('id')) {
      getPrefecture(parseInt(new URLSearchParams(search).get('id')));
      setPrefectureId(location?.state?.id)
      setInputs(values => ({ ...values, "prefecture": location?.state?.value}));
      setInputs(values => ({ ...values, "highLevelLocationId": location?.state?.locationId}));
    }
    enterKeyEvent();
    // eslint-disable-next-line 
  }, []);

  async function getLocationList() {
   await Axios.get("highLevelLocation").then((response) => {
      setLocationList(response?.data?.highLevelLocationList);
       options = response?.data?.highLevelLocationList.map(item => {
        return {
            label: String(item.highLevelLocationName),
            value: item.highLevelLocationId     
        }
    })
    setSelectOptions(options);
    console.log(locationList);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function submit(e) {
    e.preventDefault();
    setClickSubmit(true);
    if (inputs.prefecture === "" || !inputs.prefecture || !inputs.highLevelLocationId || inputs.highLevelLocationId === "") {
      return;
    } else {
      if (prefectureId) {
        editPrefecture()
      } else {
        addPrefecture();
      }
    }
  }
  const handleChange = (event) => {
    const value = event.target?.value;
    const name = event.target?.name;
    setInputs(values => ({ ...values, [name]: value }));
    console.log(inputs)
  }
  const handleChangeSelect = (event) =>{
    console.log(event)
    setInputs(values => ({ ...values, "highLevelLocationId": event?.value }));
    console.log(inputs)
  }
  function getPrefecture(id) {
    setOpen(true);
    Axios.get("prefecture/"+id).then((response) => {;
      if (response.status === 200) {
        setOpen(false);
        console.log(response)
        setResponse(true);
        setPrefectureId(response?.data?.prefectureId);
        setInputs(values => ({ ...values, "prefecture": response?.data?.prefectureName }));
        setInputs(values => ({ ...values, "highLevelLocationId": response?.data?.highLevelLocation?.highLevelLocationId}));
      }
    }).catch(error => {
      setOpen(false);
      console.log('There was an error!', error);
      setResponse(false);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "37203"){
        if(page !== undefined){
          navigate("/prefecture/"+page);
        }else{
          navigate("/prefecture/1");
        }
     }
    });
  }
  function addPrefecture() {
    setOpen(true);
    setIsLoading(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    const body = { "highLevelLocationId": inputs.highLevelLocationId, "prefecture": inputs.prefecture.trim() }
    Axios.post("prefecture", body).then((response) => {
      setIsLoading(false);
     
      if (response.status === 200) {
        setTimeout(() => {
          navigate("/prefecture/1");
          setOpen(false);
       }, 200);
        setResponse(true);
      }
    }).catch(error => {
      console.log('There was an error!', error);
      setIsLoading(false);
      setResponse(false);
      setOpen(false);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "37402"){
       
        appContext.setError(t("prefecture.prefecture_exist"))
      }else if(error.response?.data?.errorCode === "37203"){
       
        appContext.setError(t("high_level.location_not_found"))
      }else{
        document.getElementById("successPopUpButton").click();
      }
     
    });
  }
  function editPrefecture() {
    setOpen(true);
    setIsLoading(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    const body = { "highLevelLocationId": inputs.highLevelLocationId, "prefecture": inputs.prefecture.trim() }
    Axios.put("prefecture/" + prefectureId, body).then((response) => {
      ;
      setIsLoading(false);
    
      if (response.status === 200) {
        setTimeout(() => {
          if(freeWord){
            navigate("/prefecture/"+page+"?search="+freeWord);
          }else{
            navigate("/prefecture/"+page);
          } 
          setOpen(false);
       }, 200);
        setResponse(true);
      }
    }).catch(error => {
      console.log('There was an error!', error);
      setIsLoading(false);
      setResponse(false);
      setOpen(false);
      setErrorCode(error.response?.data?.errorCode);
      if(error.response?.data?.errorCode === "37402"){
       
        appContext.setError(t("prefecture.prefecture_exist"))
      }else if(error.response?.data?.errorCode === "37203"){
       
        appContext.setError(t("high_level.location_not_found"))
      }else{
        document.getElementById("successPopUpButton").click();
      }
    

    });
    console.log(body)
  }
  function enterKeyEvent(){
    let el = document.getElementById("myInputID");
    el.addEventListener("keypress", function(event) {
    if (event.key === "Enter") {
      document.getElementById("saveBtn").click();
      event.preventDefault();
    }
   });
  }
  return (
    <div className={styles.CreateJobSubCategory} data-testid="CreateJobSubCategory">
      <div>
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-5">
          {prefectureId ? <h5>{t('prefecture.edit_prefecture')}</h5> : <h5>{t('prefecture.create_prefecture')}</h5>}
        </div>
        <div class="card create-card vh-100 p-4">
          <div class="card-body">
            <form>
              <div class="row mb-3">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">{t('prefecture.prefecture_name')}</label><span class="required">*</span>
                    <input type="text" class="form-control custom-input2" id="myInputID" aria-describedby="emailHelp" placeholder={t('prefecture.prefecture_name')}
                      maxLength={25} name="prefecture" value={inputs.prefecture} onChange={handleChange} />
                  </div>
                  {(clickSubmit && !inputs.prefecture) && <div className="error">{t('prefecture.prefecture_required')}</div>}
                </div>
              </div>

              <div class="row mb-3">
                <div class="mb-3">
                  <label for="name" class="form-label input-label">{t('high_level.location_name')}</label><span class="required">*</span>
             
                  <Select aria-label="Default select example" placeholder={t('select_high_level_location')} 
                    components={{IndicatorSeparator:() => null }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#CBCBCB',
                        boxShadow: 'none',
                        '&:hover': {
                            border: '1px solid #85b2f9',
                        },
                        height : "60px",
                        borderRadius:"6px",
                        }),
                        singleValue: (base, data) =>({
                          ...base,
                          color:data.label === t('select_high_level_location') ? "#c7c7c7" : '#212529'
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          color:'black',
                          backgroundColor: state.isSelected ? 'transparant' : 'inherit',
                          '&:hover': { backgroundColor: 'rgb(222, 235, 255)' }
                        }),                      
                    }}              
                    name="highLevelLocationId" defaultValue={[{ label: location?.state?.locationName ? location?.state?.locationName : t('select_high_level_location') },
                    {value:location?.state?.locationId}]}
                     onChange={handleChangeSelect} options={selectOptions}
                    noOptionsMessage={() => t('no_results')} >

                  </Select>
                </div>
                {(clickSubmit && !inputs.highLevelLocationId) ? <div className="error">{t('prefecture.location_required')}</div> : null}
              </div>


              <div class="col-md-12 text-end my-5">
                {!isLoading && <div>
                  {freeWord?<Link to={"/prefecture/" + page + "?search="+freeWord}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link>:
                  <Link to={"/prefecture/1"}> <button class="cancel-btn" data-bs-dismiss="modal">{t('cancel')}</button></Link>}
                  <button class="main-buttons sub-btn" id="saveBtn" onClick={(e) => submit(e)}>{prefectureId?t('update') : t('save')}</button>
                </div>}
                {isLoading === true && <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px" }}></Spinner>}
              </div>

            </form>
          </div>
        </div>

      </div>
      {/*SUCCESS POPUP */}
      <a href="" class="edit-icon" data-bs-toggle="modal" id="successPopUpButton" data-bs-target="#successPopup" hidden></a>

      <div class="modal fade" id="successPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-confirm ">
          <div class="modal-content">
            <div class="modal-header justify-content-center">
              <div class="icon-box">
                 <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              </div>
              <button type="button" id="successPopUpCloseButton" class="successPopup-Close-btn" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
            </div>
            <div class="modal-body text-center">
               <h4>{t('error')}</h4>
             
              {response === false && errorCode === "37402" && <p>{t('prefecture.prefecture_exist')}</p>}
              {response === false && errorCode === "37203" && <p>{t('prefecture.prefecture_not_found')}</p>}
              {response === false && errorCode === "36401" && <p>{t('high_level.location_not_found')}</p>}
              {response === false && errorCode === "37401" && <p>{t('prefecture.invalid_prefecture')}</p>}
              {response === true ? <Link to={"/prefecture/" + page}> <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button> </Link>
                : <button class="btn btn-success btn-lg" data-bs-dismiss="modal"><span>{t('ok')}</span></button>}
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </div>
  )
};

export default CreatePrefecture;
