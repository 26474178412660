import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import useAppContext from '../../AppContext';
import i18n from '../../i18n';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useLocation, useNavigate , useParams} from "react-router-dom";
import companylogo from '../../Images/company.png';
function CompanyDetails(){
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  const [company, setCompany] = useState([]);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const page = location?.state?.page;
  useEffect( () => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    getCompany();
    // eslint-disable-next-line 
  },[])
  function getCompany(){
    appContext.setError(null);
    appContext.setSuccess(null)
    setOpen(true);
    axios
    .get('company/' + params.id)
    .then((response) => {
      setOpen(false);
      console.log(response);
      setCompany(response?.data);
    })
    .catch((error) => {
      setOpen(false);
      console.log("There was an error!", error);
    });
  }
  function edit(){
   navigate("/edit-company/" +  params.id)
  }
  function back(){
    navigate("/company/"+ page)
  }
  return(
  <>
           <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-5 pb-5">
           <h5>{t('company_detail.heading')}</h5>
        </div>
        <div className="card create-card p-4">
            <div className="card-body">
                <form>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label for="name" className="form-label input-label font-16">{t('company_detail.company_name')}</label>
                          <div className="label-h">{company?.companyName}</div>
                        </div>
          
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                            <div><label for="name" className="form-label input-label font-16">{t('company_detail.logo')}</label></div>
                            <div className="label-h"><div className="company-bg2 text-center">
                                <img className="logo-img" src={(company?.companyLogo === "" || company?.companyLogo === null) ? companylogo: company?.companyLogo} alt=""
                                style={{"objectFit":"contain"}} />
                            </div></div>
                        </div>
          
                      </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label for="name" className="form-label input-label font-16">{t('company_detail.industry')}</label>
                            <div className="label-h">{company?.industry?.industryName}</div>
                          </div>
            
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label for="name" className="form-label input-label font-16">{t('company_detail.capital')}</label>
                                <div className="label-h">  {(company?.capital !== null) ? (<>{new Intl.NumberFormat('ja').format(company?.capital)} {t("company_detail.yen")}</>) : ''}</div>
                              </div>
            
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label for="name" className="form-label input-label font-16">{t('company_detail.url')}</label>
                            <div className="label-h">  <a
                              href={company?.companyURL}
                              className="url-a"
                              target="_blank"
                              rel="noreferrer"
                            >{company?.companyURL}</a></div>
                          </div>
            
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label for="name" className="form-label input-label font-16">{t('company_detail.location')}</label>
                               {/* <div className="label-h">{company?.prefecture?.prefectureName}</div> */}
                                <div className="label-h" data-toggle="tooltip" data-placement="bottom" style={{ maxWidth: '75%', whiteSpace: 'normal', overflow: 'hidden' }} title={company?.prefectures?.map(prefecture => prefecture.prefectureName).join(', ')}>
                                  {company?.prefectures
                                    ?.map((prefecture) => prefecture.prefectureName)
                                    .join(', ')}
                                </div>
                              </div>
            
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label for="name" className="form-label input-label font-16">{t('company_detail.head_quarters_location')}</label>
                            <div className="label-h">{company?.headquartersLocation}</div>
                          </div>
            
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label for="name" className="form-label input-label font-16">{t('company_detail.content')}</label>
                               <div className="label-h">{company?.businessContent}</div>
                              </div>
            
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label for="name" className="form-label input-label font-16">{t('company_detail.catchPhrase')}</label>
                            <div className="label-h">{company?.catchPhrase}
                                </div>
                          </div>
            
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label for="name" className="form-label input-label font-16">{t('company_detail.working_enviroment')}</label>
                            <div className="label-h">{company?.workingEnvironment}
                                </div>
                          </div>
            
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label for="name" className="form-label input-label font-16">{t('company_detail.description')}</label>
                            <div className="label-h">{company?.description}
                                </div>
                          </div>
            
                        </div>
                      </div>


                    <div className="col-md-12 text-end my-5">
                        <button className="cancel-btn" data-bs-dismiss="modal" onClick={back}>{t('company_detail.back')}</button>
                        <Link to={"/edit-company/" +  params.id}  state={{page: page}}>  <button className="main-buttons sub-btn" >{t('company_detail.edit')}</button></Link>
                    </div>
          
          
          
                  </form>
            </div>
          </div>
       


 
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
  </>

  )

}

export default CompanyDetails;
