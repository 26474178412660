
import './App.css';
import { BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import Home from './components/home/home.js';
import User from './components/user/user.js';
import Blog from './components/blog/blog.js';
import Company from './components/company/company.js'
import EmploymentType from './components/employment-type/employment-type.js';
import Prefecture from './components/prefecture/prefecture.js'
import HighLevelLocation from './components/high-level-location/high-level-location.js';
import JobCategory from './components/job-category/job-category.js';
import JobSubCategory from './components/job-sub-category/job-sub-category.js';
import Industry from './components/industry/industry.js';
import HotJob from './components/hot-job/hot-job.js';
import PickupCategory from './components/pickup-category/pickup-category.js';
import PopularCompany from './components/popular-company/popular-company.js';
import SalaryRange from './components/salary-range/salary-range.js';
import Tag from './components/tag/tag.js';
import Job from './components/job/job.js';
import Login from './components/login/login.js';
import CompanyDetails from './components/company-details/company-details.js';
import CreateCompany from './components/create-company/create-company.js';
import CreateBlog from './components/create-blog/create-blog.js';
import CreateEmployment from './components/create-employment/create-employment.js';
import CreateIndustry from './components/create-industry/create-industry.js';
import CreateJob from './components/create-job/create-job.js';
import CreateJobCategory from './components/create-job-category/create-job-category.js';
import CreateJobSubCategory from './components/create-job-sub-category/create-job-sub-category.js';
import CreatePrefecture from './components/create-prefecture/create-prefecture.js';
import CreateSalaryRange from './components/create-salary-range/create-salary-range.js';
import CreateTag from './components/create-tag/create-tag.js';
import CreateHighLevelLocation from './components/create-high-level-location/create-high-level-location.js';
import ChangePassword from './components/change-password/change-password';
import Skill from './components/skill/skill.js';
import CreateSkill from './components/create-skill/create-skill.js';
import Error from './components/error/error';
function App() {
  return (
    <div className="App">
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}>
           <Route path="blog/:page" element={<Blog />} />
           <Route path="company/:page" element={<Company />} />
           <Route path="user/:page" exact={true} element={<User />} />
           <Route path="employment/:page" element={<EmploymentType />} />
           <Route path="prefecture/:page" element={<Prefecture />} />
           <Route path="high-level-location/:page" element={<HighLevelLocation />} />
           <Route path="job-category/:page" element={<JobCategory />} />
           <Route path="job-sub-category/:page" element={<JobSubCategory />} />
           <Route path="industry/:page" element={<Industry />} />
           <Route path="hot-job" element={<HotJob />} />
           <Route path="pickup-category" element={<PickupCategory />} />
           <Route path="popular-company" element={<PopularCompany />} />
           <Route path="salary-range/:page" element={<SalaryRange />} />
           <Route path="tag/:page" element={<Tag />} />
           <Route path="job/:page" element={<Job />} />   
           <Route path="skill/:page" element={<Skill />} />         
           <Route path="company-details/:id" element={<CompanyDetails />} />
           <Route path="create-company" element={<CreateCompany />} />
           <Route path="create-blog" element={<CreateBlog />} />
           <Route path="create-employment" element={<CreateEmployment />} />
           <Route path="create-industry" element={<CreateIndustry />} />
           <Route path="create-job" element={<CreateJob />} />           
           <Route path="create-job-category" element={<CreateJobCategory />} /> 
           <Route path="create-job-sub-category" element={<CreateJobSubCategory />} /> 
           <Route path="create-prefecture" element={<CreatePrefecture />} /> 
           <Route path="create-salary-range" element={<CreateSalaryRange />} /> 
           <Route path="create-tag" element={<CreateTag />} /> 
           <Route path="create-skill" element={<CreateSkill />} /> 
           <Route path="create-high-level-location" element={<CreateHighLevelLocation />} /> 
           <Route path="change-password" element={<ChangePassword />} /> 
           <Route path="edit-company/:id" element={<CreateCompany />} />
         
        </Route>
        <Route path='*' exact={true} element={<Error />}  />
        <Route path="login" element={<Login />} />
        <Route path="error" element={<Error />} />
    </Routes>
  
   </Router>
  </div>
  );
}

export default App;
