import { applyMiddleware,configureStore } from "@reduxjs/toolkit";
import { reducer as counterReducer } from "./couterReducer";
import { reducer as userCounterReducer } from "./userCouterReducer";
import {persistStore,persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import storageSession from 'redux-persist/lib/storage/session'
const persistConfig={
  key:'root',
  storage:storageSession,
  }
  const persistConfig1={
    key:'root',
    storage:storageSession
    }

const persistedReducer=persistReducer(persistConfig,counterReducer)
const persistedReducerUser=persistReducer(persistConfig1,userCounterReducer)
 const store = configureStore({
  reducer: {
    counter: persistedReducer,
    userCounter : persistedReducerUser
  }
},applyMiddleware())

const persistor = persistStore(store)

export {persistor}
export default store