import React, { useEffect, useState , useMemo ,useRef} from "react";
import { useTranslation } from "react-i18next";
import useAppContext from "../../AppContext";
import i18n from "../../i18n";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from "../../pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/couterReducer";
import { Spinner } from "react-bootstrap";
import companylogo from '../../Images/company.png';
function Company() {
  const scrollRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  const [allCompany, setAllCompany] = useState([]);
  const [open, setOpen] = React.useState(false);
  const limit = 10;
  const [count, setCount] = useState(0);
  const counterState = useSelector((state) => state.counter);
  const [currentPage, setCurrentPage] = useState(parseInt(params.page));
  const [freeText, setFreeText] = useState(counterState.freeText);
  const [deleteRow, setDeleteRow] = useState("");
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  
 
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    getAllCompany(currentPage)
    // eslint-disable-next-line 
    window.addEventListener('popstate', onBackButtonEvent);
  }, []);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.location.reload()
  }
  // useMemo(() => getPageCountCompany(currentPage), [currentPage]);
  function getAllCompany(page) {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    appContext.setError(null);
    appContext.setSuccess(null)
    console.log(counterState);
    if(freeText !== undefined && freeText !== null && freeText !== ""){
      console.log(freeText)
      setFreeText(freeText.trim());
    }
    axios
      .get("company?page=" + page + "&size=" + limit + "&search=" + encodeURIComponent(freeText.trim())
      .replace('_', '%5C%5F')
      .replace(/\\/, '%5C')
      .replace('%25',"%5C%25"))
      .then((response) => {
        dispatch(actions.setSearchCompanyfield(freeText));
        console.log(response);
        setAllCompany(response?.data?.companyList);
        setCount(response?.data?.totalCount);
        setTimeout(() => {
          tableScroll();
       }, 500);  
        const totalPageCount = Math.ceil(response?.data?.totalCount / limit);
        if(page > totalPageCount){
          setCurrentPage(totalPageCount);
          if(totalPageCount !== 0){
          dispatch(actions.setPageCompanyCount(totalPageCount));
          getAllCompany(currentPage);
          navigatePage(currentPage)
          }
        }
        setIsLoaded(true);
        setOpen(false);
      console.log(totalPageCount);
        console.log(allCompany);
      })
      .catch((error) => {
        setIsLoaded(true);
        setOpen(false);
        console.log("There was an error!", error);
      });
  }
  function tableScroll(){
    const slider = document.querySelector('.table-responsive1');
    let isDown = false;
    let startX;
    let scrollLeft;
    slider?.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider?.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider?.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider?.addEventListener('mousemove', (e) => {
      if(!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      console.log(walk);
    });
  }
  function pageChange(page) {
    console.log(page);
    setCurrentPage(page);
    dispatch(actions.setPageCompanyCount(page));  
    getAllCompany(page);
    navigatePage(page)
  }
  function onDelete(row) {
    console.log(row);
    setDeleteRow(row);
  }
  function deleteCompany() {
    setOpen(true);
    appContext.setError(null);
    appContext.setSuccess(null)
    axios
      .delete("company/" + deleteRow.companyId)
      .then((response) => {
        setOpen(false);
        getAllCompany(currentPage);
        navigatePage(currentPage)
        appContext.setSuccess(t("company_list.company_delete_success"))
      })
      .catch((error) => {
        setOpen(false);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        if(error?.response?.data?.errorCode === "310401") {
         
          appContext.setError(t("company_list.company_deletion_failed"))
        } else {
          appContext.setError(t("company_list.company_notfound"))
        }

        console.log("There was an error!", error);
      });
  }
  function onEdit(row) {
    console.log(row);
    navigate("/edit-company/" +   row.companyId)
  }
  function onSearch() {
    setCurrentPage(1);
    dispatch(actions.setPageCompanyCount(1));
    getAllCompany(1);
    navigatePage(1)
  }
  const handleOnChangeFreeText = (event) => {
    setFreeText(event.target.value);
    console.log(freeText);
  };
  function getPageCountCompany(){
    setOpen(true);

  getAllCompany();

   

 
  }
  function navigatePage(page) {
   
    navigate("/company/"+page);
}
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setCurrentPage(1);
      dispatch(actions.setPageCompanyCount(1));
      getAllCompany(1);
      navigatePage(1)
    }
  };

  return (
    <>
    
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-4">
          <h5>{t("company_list.heading")}</h5>
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-4">
                <Link to={"/create-company"}  state={{page: currentPage}}>
                  <button className="create-btn mt-3 mb-3">
                    <i className="fa-solid fa-plus"></i>{" "}
                    {t("company_list.create_company")}
                  </button>
                </Link>
              </div>
              <div className="col-md-8">
                <div className="input-group mt-3 mb-3">
                  <input
                    type="text"
                    className="form-control custom-search"
                    placeholder={t("company_list.company_search")}
                    value={freeText}
                    onChange={(e) => handleOnChangeFreeText(e)}
                    onKeyDown={handleKeyDown}
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    id="myInputID"
                  />
                  <button
                    type="button"  onClick={onSearch}
                    className="input-group-text search-btn"
                    id="basic-addon2"
                  >
                    <i
                      className="fa-solid fa-magnifying-glass"
                     
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoaded ?  
      <div>
       {allCompany.length > 0 ? 
        <div className="card table-card">
          <div className="card-body p-0">
            <div className="table-responsive1" ref={scrollRef} id="tablescrollcontainer">
            {/* <div className="table-responsive1" ref={scrollRef} id="tablescrollcontainer"
        onWheel={onWheel}> */}
                  <table className="table custom-table">
                    <thead className="table-header">
                      <tr>
                        <th style={{ "width": "6%" }} scope="col">{t("company_list.no")}</th>
                        <th style={{ "width": "8%" }} scope="col">{t("company_list.company_name")}</th>
                        <th style={{ "width": "4%" }} scope="col">{t('company_list.logo')}</th>
                        <th style={{ "width": "12%" }} scope="col">{t('company_list.description')}</th>
                        {/* <th scope="col">{t("company_list.skill")}</th> */}
                        <th style={{ "width": "5%" }} scope="col">{t("company_list.industry")}</th>
                        <th style={{ "width": "5%" }} scope="col">{t("company_list.capital")}</th>
                        <th style={{ "width": "7%" }} scope="col">{t("company_list.url")}</th>
                        <th style={{ "width": "7%" }} scope="col">{t("company_list.location")}</th>
                        <th style={{ "width": "7%" }} scope="col">
                          {t("company_list.head_quarters_location")}
                        </th>
                        <th style={{ "width": "10%" }} scope="col">{t("company_list.content")}</th>
                        <th style={{ "width": "10%" }} scope="col">{t("company_list.catchPhrase")}</th>
                        <th style={{ "width": "10%" }} scope="col">{t("company_list.working_enviroment")}</th>
                        <th style={{ "width": "5%" }} scope="col">{t("company_list.action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allCompany.length > 0 ? (
                        <>
                          {allCompany?.map((row, index) => (
                            <tr>
                              <td>{(index + 1) + (limit * (currentPage - 1))}</td>
                              <td className="site_text_order site_text_order_align"><span className="table-text">    <Link className="compny_href" to={"/company-details/" + row.companyId} state={{ page: currentPage }}>{row?.companyName}</Link></span></td>
                              <td className="site_text_order_align">
                                <div className="company-bg">
                                  <img className="logo-img2" src={(row?.companyLogo === "" || row?.companyLogo === null) ? companylogo : row?.companyLogo} alt="" />
                                </div>
                              </td>
                              <td className="site_text_order site_text_order_align"><span className="table-text">{row?.description}</span></td>
                              {/* <td>{row?.companyName}</td> */}
                              <td className="site_text_order site_text_order_align"><span className="table-text">{row?.industry?.industryName}</span></td>
                              <td className="site_text_order site_text_order_align"><span className="table-text">

                                {(row?.capital) ? (<>{new Intl.NumberFormat('ja').format(row?.capital)} {t("company_list.yen")}</>) : ''}</span></td>
                              <td className="site_text_order site_text_order_align">
                                <a
                                  href={row?.companyURL}
                                  className="url-a"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {row?.companyURL}
                                </a>
                              </td>
                              <td className="site_text_order site_text_order_align">
                                {/* <span className="table-text">{row?.prefecture?.prefectureName}</span> */}
                                <div className="label-h" data-toggle="tooltip" data-placement="bottom" style={{ maxWidth: '75%', whiteSpace: 'normal', overflow: 'hidden' }} title={row?.prefectures?.map(prefecture => prefecture.prefectureName).join(', ')}>
                                  {row?.prefectures
                                    ?.map((prefecture) => prefecture.prefectureName)
                                    .join(', ')}
                                </div>
                              </td>
                              <td className="site_text_order site_text_order_align"><span className="table-text">{row?.headquartersLocation}</span></td>
                              <td className="site_text_order site_text_order_align"><span className="table-text">{row?.businessContent}</span></td>
                              <td className="site_text_order site_text_order_align"><span className="table-text">{row?.catchPhrase}</span></td>
                              <td className="site_text_order site_text_order_align"><span className="table-text">{row?.workingEnvironment}</span></td>
                              <td className="site_text_order_align">
                                <div className="row">
                                  <div className="col-md-12 ">
                                    <Link className="compny_href" to={"/edit-company/" + row.companyId} state={{ page: currentPage }}> <button
                                      className="edit-btn me-2"

                                    >
                                      <i className="fa-solid fa-pen"></i>
                                    </button></Link>
                                    <button
                                      className="delete-btn me-2"
                                      onClick={(e) => onDelete(row)}
                                      data-bs-toggle="modal"
                                      data-bs-target="#applyjob"
                                    >
                                      <i className="fa-solid fa-trash-can"></i>
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}{" "}
                        </>
                      ) : (
                        <tr>
                          <td className="no-data" colspan="13">{t("company_list.no_data_found")}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            : <div><h6 style={{ "textAlign": "center", "fontWeight": "bold", "lineHeight": "400px" }}>{t("company_list.no_data_found")}</h6></div>}
        </div> : <Spinner style={{ "color": "#02c090", "height": "50px", "width": "50px", "marginTop": "150px" }}></Spinner>}
      <div className="col-md-12 mt-5 m-b5">
        <Pagination
          className="pagination-bar page-item page-list"
          currentPage={currentPage}
          totalCount={count}
          pageSize={limit}
          onPageChange={(page) => pageChange(page)}
        />
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
      {/* <div
        class="modal fade"
        id="applyjob"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered  modal-lg">
          <div class="modal-content">
            <div class="modal-header border-none">
              <div class="col-md-12 text-center mt-60"></div>
              <button
                type="button"
                class="Close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="modal-body p-0 mb-5">
              <div class="row d-flex align-items-center">
                <div class="text-center">
                  <h4>Are you sure do you want to delete?</h4>
                </div>

                <div class="col-md-12 text-center">
                  <button class="main-buttons sub-btn" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button
                    class="delete-buttons sub-btn"
                    data-bs-dismiss="modal"
                    onClick={deleteCompany}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="modal fade" id="applyjob" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">

                    <div className="modal-body px-5 py-3">
                        <div className="row mb-2 mt-5 px-3">
                            <div className="col-md-12 text-center">
                                <i className="fa-regular fa-circle-xmark delete-pop"></i>
                            </div>

                            <div className="text-center mt-4 mb-4">
                                <h5>{t("company_list.delete_head")}</h5>
                            </div>

                            <div className="text-center">
                                <p>{t("company_list.delete_description")}</p>
                            </div>


                        </div>
                        <div className="col-md-12 text-center mb-3">
                            <button className="cancel-btn" data-bs-dismiss="modal">{t("company_list.cancel")}</button>
                            <button className="main-buttons sub-btn"  data-bs-dismiss="modal" onClick={deleteCompany}>{t("company_list.delete")}</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    
    </>
  );
  
}

export default Company;
